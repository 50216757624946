<template>
  <div class="partners-wrapper">
    <h2 v-show="partners?.length">{{ $t("our_partners.title") }}</h2>
    <swiper
      :modules="modules"
      :slides-per-view="slidesPerView"
      :space-between="50"
      :loop="true"
      :autoplay="{
        delay: 1,
        disableOnInteraction: false,
      }"
      :free-mode="{
        momentum: false,
        sticky: true,
      }"
      :speed="5000"
    >
      <swiper-slide v-for="item in partners" :key="item?.id">
        <a :href="item?.url" target="_blank">
          <img :src="item?.sponsor_logo?.thumbnails?.small" :alt="item?.name" />
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import sponsorService from "@/services/SponsorService";

export default {
  name: "our-partners",
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      slidesPerView: 6,
      partners: null,
    };
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.getPartners();
  },

  methods: {
    onResize() {
      if (window.innerWidth > 1200) {
        this.slidesPerView = 6;
      } else if (window.innerWidth <= 1200 && window.innerWidth > 767) {
        this.slidesPerView = 3;
      } else if (window.innerWidth <= 767) {
        this.slidesPerView = 2;
      }
    },

    async getPartners() {
      this.partners = await sponsorService.getSponsors();
      this.$emit("partnersLoaded");
    },
  },

  setup() {
    return {
      modules: [Autoplay, FreeMode],
    };
  },
};
</script>

<style scoped lang="scss">
.partners-wrapper {
  background-color: #e2ff00;
  background-image: url("../../assets/img/bnx_sponsor-bg.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 76px 0;

  h2 {
    color: #000;
    font-family: DrukWideBoldItalic;
    font-size: 80px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
    text-align: center;
    margin-bottom: 30px;
  }
}

:deep(.swiper-free-mode > .swiper-wrapper) {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.swiper {
  margin-bottom: 10px;

  .swiper-slide {
    img {
      max-width: 280px;
      width: 100%;
      height: 140px;
      object-fit: contain;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .swiper {
    .swiper-slide {
      img {
        max-width: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .swiper {
    .swiper-slide {
      img {
        max-width: 280px;
      }
    }
  }
}
</style>
