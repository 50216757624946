<template>
  <div id="hero-carousel" class="carousel slide">
    <div class="carousel-indicators">
      <button
        v-for="(item, index) in slides"
        :class="{ active: index.toString() === '0' }"
        type="button"
        data-bs-target="#hero-carousel"
        :data-bs-slide-to="index"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item" v-for="(item, index) in slides" :class="{ active: index.toString() === '0' }">
        <div class="flex-container">
          <div class="slide-left">
            <h2 class="title">{{ item?.title }}</h2>
            <p class="description">{{ item?.lead.length > 120 ? item?.lead.substring(0, 120) + "..." : item?.lead }}</p>
            <div class="btn-wrapper">
              <router-link
                :to="{
                  name: misc.getRouteNameByLocale($router.options.routes, 'SinglePost'),
                  params: { slug: item?.slug },
                }"
                class="read-more-btn"
              >
                {{ $t("home.hero.button") }}
              </router-link>
            </div>
          </div>
          <div class="slide-right">
            <img :src="item?.cover_image?.thumbnails?.medium" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#hero-carousel" data-bs-slide="prev">
      <span><font-awesome-icon icon="fa-solid fa-angle-left" /></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#hero-carousel" data-bs-slide="next">
      <span><font-awesome-icon icon="fa-solid fa-angle-right" /></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";
import { Carousel } from "bootstrap";

export default {
  name: "home-hero",
  props: {
    slides: Array,
  },
  data() {
    return {
      misc: misc,
    };
  },
  mounted() {
    // Wait for the DOM to fully update before initializing the carousel
    this.$nextTick(() => {
      this.initCarousel();
    });
  },
  methods: {
    initCarousel() {
      const heroCarousel = document.getElementById("hero-carousel");
      if (heroCarousel) {
        new Carousel(heroCarousel, {
          interval: 7000,
          ride: "carousel",
          pause: "false",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
#hero-carousel {
  .carousel-indicators button {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin: 0 6px;
  }

  .carousel-control-prev {
    height: 54px;
    width: 54px;
    top: calc(50% - 27px);
    left: 20px;

    svg {
      font-size: 34px;
      color: #fff;
      background-color: #6441ffad;
      border-radius: 100px 100px 100px 100px;
      box-shadow: 0 10px 30px 0 rgb(148 124 255 / 36%);
      padding: 10px;
      height: 34px;
      width: 34px;
    }
  }

  .carousel-control-next {
    height: 54px;
    width: 54px;
    top: calc(50% - 15px);
    right: 20px;

    svg {
      font-size: 34px;
      color: #fff;
      background-color: #6441ffad;
      border-radius: 100px 100px 100px 100px;
      box-shadow: 0 10px 30px 0 rgb(148 124 255 / 36%);
      padding: 10px;
      height: 34px;
      width: 34px;
    }
  }
}
.carousel-inner {
  .carousel-item {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;

    .title,
    .description {
      color: #ffffff !important;
    }

    .read-more-btn {
      background: #ffffff !important;
      color: #000000 !important;

      &:hover {
        background: #3264ff !important;
      }
    }

    &:nth-child(5n + 1) {
      background: url("../../assets/img/slider/bnx_slider_1-min-scaled.jpg");
    }

    &:nth-child(5n + 2) {
      background: url("../../assets/img/slider/bnx_slider_2-min-scaled.jpg");
    }

    &:nth-child(5n + 3) {
      background: url("../../assets/img/slider/bnx_slider_3-min-scaled.jpg");
    }

    &:nth-child(5n + 4) {
      background: url("../../assets/img/slider/bnx_slider_4-min-scaled.jpg");
    }

    &:nth-child(5n + 5) {
      background: url("../../assets/img/slider/bnx_slider_5-min-scaled.jpg");
    }

    .slide-left {
      text-align: left;

      .title {
        color: #e2ff00;
        font-family: DrukWideItalic, Sans-serif;
        font-size: 5.5rem;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 0.85em;
        width: 160%;
        z-index: 1;
        position: relative;
        margin: 70px 0 40px;
      }

      .description {
        color: #fff;
        font-size: 1.4em;
        font-weight: 500;
        line-height: 1.3em;
        margin: 0 15% 10% 0;
      }

      .btn-wrapper {
        display: flex;

        .read-more-btn {
          background-color: #e2ff32;
          font-family: DrukWideItalic, Sans-serif;
          font-size: 30px;
          font-weight: 500;
          text-transform: uppercase;
          line-height: 1.3em;
          color: #000;
          border-width: 0;
          border-radius: 100px;
          padding: 15px 40px 12px;
          text-decoration: none;
          transition: 0.3s;

          &:hover {
            background-color: #000;
            color: #e2ff32;
          }
        }
      }
    }

    .flex-container {
      display: flex;
      margin-top: 150px;
      margin-bottom: 120px;
      padding: 0 8%;

      .slide-left {
        width: 40%;
      }

      .slide-right {
        width: 60%;

        img {
          object-fit: cover;
          height: 520px;
          object-position: center center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .slide-left {
    width: 45% !important;

    .title {
      font-size: 50px !important;
      margin: 170px 0 20px !important;
    }

    .description {
      font-size: 16px !important;
      margin-bottom: 10px !important;
    }

    .read-more-btn {
      font-size: 24px !important;
      padding: 12px 30px 10px !important;
    }
  }

  .slide-right {
    width: 55% !important;
  }
}

@media only screen and (max-width: 767px) {
  .carousel-control-prev {
    left: 10px !important;
    width: 44px !important;
    height: 44px !important;
    top: calc(50% - 22px);

    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .carousel-control-next {
    right: 10px !important;
    width: 44px !important;
    height: 44px !important;
    top: calc(50% - 22px);

    svg {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .carousel-item {
    .flex-container {
      flex-direction: column-reverse;
      margin-top: 120px !important;
      margin-bottom: 70px !important;
      padding: 0 4% !important;

      .slide-left {
        width: 100% !important;

        .title {
          margin: 10px 0 !important;
          width: 100% !important;
        }
      }

      .slide-right {
        width: 100% !important;

        img {
          height: 350px !important;
        }
      }
    }
  }
}
</style>
