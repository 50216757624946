<template>
  <div class="survey-contest" id="answering">
    <div v-if="!surveyHasBeenSent">
      <div
        class="contest"
        v-if="
          moment(new Date()) <= moment(this.survey?.end_date) && moment(new Date()) >= moment(this.survey?.start_date)
        "
      >
        <div class="progress-bar-wrapper">
          <div class="progress-bar">
            <div
              class="progress-bar-state"
              :style="{ width: 100 / (survey?.questions?.length / (currentQuestionIndex + 1)) + '%' }"
            ></div>
          </div>
        </div>

        <div class="question" v-for="(item, index) in survey?.questions" v-show="index === currentQuestionIndex">
          <div class="question-number">{{ $t("survey.contest.question") }} {{ index + 1 }}</div>
          <h3 class="question-title">{{ item.question }}</h3>
          <input type="text" class="answer" v-model="item.answer" placeholder="your answer" />
        </div>

        <form
          class="submit-survey"
          @submit="onSendSurvey($event)"
          v-if="currentQuestionIndex + 1 > survey?.questions?.length"
        >
          <div class="form-group">
            <label for="survey-firstname" class="name-label">{{ $t("survey.contest.firstname") }}</label>
            <input type="text" name="survey-firstname" id="survey-firstname" required v-model="contest.firstname" />
          </div>

          <div class="form-group">
            <label for="survey-name" class="name-label">{{ $t("survey.contest.name") }}</label>
            <input type="text" name="survey-name" id="survey-name" required v-model="contest.name" />
          </div>

          <div class="form-group">
            <label for="survey-email" class="email-label">{{ $t("email_address") }}</label>
            <input
              type="text"
              name="survey-email"
              id="survey-email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
              required
              v-model="contest.email"
            />
          </div>

          <div class="form-group checkbox-wrapper">
            <input type="checkbox" id="survey-is_adult" name="survey-is_adult" v-model="is_adult" required />
            <label for="survey-is_adult" class="checkbox-label">
              {{ $t("survey.is_adult") }}
            </label>
          </div>

          <div class="form-group checkbox-wrapper">
            <input
              type="checkbox"
              id="survey-privacy-policy"
              name="survey-privacy-policy"
              v-model="survey_privacy_policy"
            />
            <label for="survey-privacy-policy" class="checkbox-label">
              {{ $t("accept_privacy_policy_survey") }}
            </label>
          </div>

          <div class="buttons-wrapper">
            <button class="question-btn" @click="onPreviousQuestion" v-if="currentQuestionIndex !== 0">
              {{ $t("survey.previous_question") }}
            </button>

            <button type="submit" class="question-btn">{{ $t("survey.send_button") }}</button>
          </div>
        </form>

        <div class="buttons-wrapper" v-if="currentQuestionIndex < survey?.questions?.length">
          <button class="question-btn" @click="onPreviousQuestion" v-if="currentQuestionIndex !== 0">
            {{ $t("survey.previous_question") }}
          </button>
          <button
            class="question-btn"
            @click="onNextQuestion"
            v-if="currentQuestionIndex + 1 <= survey?.questions?.length"
            :disabled="!survey?.questions[currentQuestionIndex]?.answer"
          >
            <span v-if="currentQuestionIndex + 1 < survey?.questions?.length">{{ $t("survey.next_question") }}</span>
            <span v-if="currentQuestionIndex + 1 === survey?.questions?.length">{{
              $t("survey.complete_button")
            }}</span>
          </button>
        </div>
      </div>

      <div class="no-contest" v-else>
        <h2>{{ $t("survey.contest_over_1") }}</h2>
        <p>{{ $t("survey.contest_over_2") }}</p>
      </div>
    </div>
    <div v-else class="has-been-sent">{{ $t("survey.success_message") }}</div>
  </div>

  <Snackbar @close="snackBarMessage = null" v-if="snackBarMessage">{{ snackBarMessage }}</Snackbar>
</template>

<script>
import moment from "moment";
import surveyService from "@/services/SurveyService";
import Snackbar from "@/components/shared/snackbar";

export default {
  name: "survey-contest",
  components: { Snackbar },
  props: {
    survey: null,
  },

  data() {
    return {
      currentQuestionIndex: 0,
      contest: {
        survey_id: null,
        name: null,
        firstname: null,
        email: null,
        answers: [],
      },
      survey_privacy_policy: false,
      is_adult: false,
      snackBarMessage: null,
      surveyHasBeenSent: false,
    };
  },

  methods: {
    onPreviousQuestion() {
      this.currentQuestionIndex -= 1;
    },

    onNextQuestion() {
      this.currentQuestionIndex += 1;
    },

    onSendSurvey(e) {
      e.preventDefault();

      this.contest.survey_id = this.survey.id;
      this.survey?.questions?.forEach((question) => {
        const index = this.contest.answers.findIndex((answer) => answer.question_id === question.id);

        if (index === -1) {
          this.contest.answers.push({ question_id: question.id, answer: question.answer });
        } else {
          this.contest.answers.find((answer) => answer.question_id === question.id).answer = question.answer;
        }
      });
      this.survey_privacy_policy = false;
      this.contest.is_adult = true;
      this.sendSurvey(this.contest);
    },

    async sendSurvey(surveyData) {
      await surveyService.sendSurveyData(surveyData).then((res) => {
        if (res.id) {
          this.surveyHasBeenSent = true;
          this.snackBarMessage = "Thank you for your participation";
          this.contest = {
            survey_id: null,
            name: null,
            firstname: null,
            is_adult: null,
            email: null,
            answers: [],
          };
        } else {
          this.snackBarMessage = Object.values(res)[0][0];
        }
      });
    },
  },

  computed: { moment: () => moment },
};
</script>

<style scoped lang="scss">
.survey-contest {
  background: url("../../assets/img/bnx_slider_orange.jpg") no-repeat center center;
  background-size: cover;
  padding: 80px 20px 70px;

  .contest {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;

    .question {
      &-number {
        color: #fff;
        font-family: DrukWide, Sans-serif;
        font-size: 18px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      &-title {
        color: #fff;
        font-family: DrukWideItalic, Sans-serif;
        font-size: 80px;
        text-transform: uppercase;
        line-height: 0.85;
      }

      .answer {
        color: #fff;
        font-family: DrukWideItalic, Sans-serif;
        font-size: 80px;
        text-transform: uppercase;
        line-height: 0.85;
        background: transparent;
        border: none;
        text-align: center;
        margin-top: 60px;

        &:focus-visible {
          outline: none;
        }

        &::placeholder {
          color: #fff;
          opacity: 0.6;
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #fff;
          opacity: 0.6;
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #fff;
          opacity: 0.6;
        }
      }
    }

    .submit-survey {
      .form-group {
        text-align: left;
      }

      .email-label,
      .name-label {
        color: #fff;
        margin-bottom: 10px;
        font-family: DrukWide;
        font-weight: 400 !important;
        letter-spacing: 0.5px;
      }

      .checkbox-wrapper {
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;

        .checkbox-label {
          font-family: helvetica;
          font-size: 18px;
          margin-left: 5px;
          color: #fff;
        }
      }

      #survey-email,
      #survey-firstname,
      #survey-name {
        width: 100%;
        padding: 15px;
        font-family: helvetica;
        font-weight: 600;
        font-size: 18px;
        border-radius: 8px;
        color: #000000;
        box-shadow: 5px 5px 0 #cc3366;
        border: solid 3px #cc3366;
        margin-bottom: 30px;
      }
    }

    .buttons-wrapper {
      display: flex;
      gap: 20px;
      justify-content: center;

      .question-btn {
        font-family: DrukWideItalic, Sans-serif;
        font-size: 30px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 0.85em;
        fill: #070707;
        color: #070707;
        background-color: #e2ff00;
        border-radius: 100px;
        padding: 20px 40px;
        border: none;
        margin-top: 50px;

        &:disabled {
          background-color: #bababa;
        }
      }
    }

    .progress-bar-wrapper {
      height: 30px;
      position: relative;
      margin-bottom: 50px;

      .progress-bar {
        width: 100%;
        height: 3px;
        background: #fff;
        border-radius: 50px;

        &-state {
          position: absolute;
          height: 10px;
          background: #fff;
          border-radius: 50px;
          max-width: 100%;
        }
      }
    }
  }

  .no-contest {
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    gap: 20px;
    flex-direction: column;
    text-align: center;

    h2 {
      color: #fff;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-size: 80px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.85em;
    }

    p {
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4em;
    }
  }

  .has-been-sent {
    color: #fff;
    font-family: DrukWideItalic, Sans-serif;
    font-size: 80px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 0.85em;
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  .survey-contest {
    .contest {
      .question {
        &-number {
          font-size: 16px;
        }

        &-title {
          font-size: 60px;
        }

        .answer {
          font-size: 60px;
        }
      }

      .buttons-wrapper {
        .question-btn {
          font-size: 24px;
          padding: 16px 40px;
        }
      }

      .progress-bar-wrapper {
        .progress-bar {
          &-state {
            height: 8px;
          }
        }
      }
    }

    .no-contest {
      h2 {
        font-size: 60px;
      }

      p {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .survey-contest {
    .contest {
      .question {
        &-number {
          font-size: 14px;
        }

        &-title {
          font-size: 50px;
        }

        .answer {
          font-size: 50px;
        }
      }

      .buttons-wrapper {
        flex-direction: column;
        margin-top: 50px;

        .question-btn {
          margin-top: 0;
        }
      }

      .progress-bar-wrapper {
        .progress-bar {
          &-state {
            height: 8px;
          }
        }
      }
    }

    .no-contest {
      p {
        font-size: 16px;
      }
    }
  }
}
</style>
