<template>
  <div :class="{ 'iframe-wrapper': isIframe }">
    <nav class="navbar navbar-expand-xl" :class="{ 'full-width': scrollPosition >= 200, awards: isAwardsPage }">
      <router-link :to="{ name: misc.getRouteNameByLocale($router.options.routes, 'Home') }" class="navbar-brand">
        <img src="./assets/img/A-BNXT_League_white.png" alt="" />
      </router-link>

      <button class="navbar-toggler" aria-expanded="false" aria-label="Toggle navigation" @click="showMenuModal">
        <img src="./assets/img/bnx_menu-icon.svg" alt="" />
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <LocaleSwitcher @lang-change="getMenuItems" />

        <ul class="navbar-nav">
          <slot v-for="item in headerMenuItems">
            <slot v-if="!item.children.length">
              <a :href="item.link" class="nav-link" target="_blank" v-if="item.type === 'external_url'">
                <img :src="require('@/assets/img/C-BNXTTV_logo_white.png')" v-if="item.name === 'BNXT.TV'" />
                <span v-else>{{ item.name }}</span>
              </a>
              <router-link
                :to="{ path: `/${locale}/${item.slug}` }"
                class="nav-link"
                v-else-if="item.type === 'internal_url'"
              >
                {{ item.name }}
              </router-link>
            </slot>
            <slot v-else>
              <li class="nav-item dropdown">
                <a
                  href="javascript:void(0)"
                  class="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ item.name }}
                  <font-awesome-icon icon="fa-solid fa-angle-down" />
                </a>
                <ul class="dropdown-menu">
                  <li v-for="child in item.children">
                    <router-link
                      :to="{ name: child.link, hash: misc.getMenuItemHash(child.name) }"
                      class="dropdown-item"
                    >
                      {{ child.name }}
                    </router-link>
                  </li>
                </ul>
              </li>
            </slot>
          </slot>
          <a href="javascript:void(0)" class="nav-link bordered" @click="showFollowUsModal">
            <font-awesome-icon icon="fa-solid fa-user-plus" />
            {{ $t("menu.follow_us") }}
          </a>
        </ul>
      </div>

      <FollowUsModal
        class="follow-us-modal"
        v-show="isFollowUsModalVisible"
        @mouseleave="isFollowUsModalVisible = !isFollowUsModalVisible"
      />
    </nav>

    <CookieBar />

    <router-view :key="$route.fullPath" />

    <AppFooter :explore-bnxt-items="footerExploreBnxtMenuItems" :legal-items="footerLegalBnxtMenuItems" />
    <mobile-menu-modal
      :menu-items="headerMenuItems"
      @close="closeMenuModal"
      @lang-change="getMenuItems"
      v-show="isMenuModalVisible"
    ></mobile-menu-modal>
    <WelcomeModal :popup="welcomePopup" @close="isWelcomeModalVisible = false" v-show="isWelcomeModalVisible" />
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/shared/localeswitcher/LocaleSwitcher";
import MobileMenuModal from "@/components/shared/modals/mobile-menu-modal";
import FollowUsModal from "@/components/shared/modals/follow-us-modal";
import menuService from "@/services/MenuService";
import AppFooter from "@/components/shared/app-footer";
import CookieBar from "@/components/shared/cookie-bar";
import misc from "@/core/helpers/misc";
import WelcomeModal from "@/components/shared/modals/welcome-modal";
import bannersService from "@/services/BannersService";

export default {
  components: {
    WelcomeModal,
    CookieBar,
    AppFooter,
    LocaleSwitcher,
    MobileMenuModal,
    FollowUsModal,
  },

  data() {
    return {
      scrollPosition: null,
      isMenuModalVisible: false,
      isFollowUsModalVisible: false,
      isWelcomeModalVisible: false,
      headerMenuItems: Array,
      footerExploreBnxtMenuItems: Array,
      footerLegalBnxtMenuItems: Array,
      misc: misc,
      isIframe: null,
      welcomePopup: null,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);

    if (this.$route.path.includes("iframe")) {
      this.isIframe = true;
      this.misc.hideRecaptchaBadge();
    } else {
      this.isIframe = false;
      this.getMenuItems();
    }
    this.getWelcomePopup();
  },

  methods: {
    async getMenuItems() {
      await menuService.getMenuItems().then((res) => {
        this.headerMenuItems = this.misc.sortMenuItemsByRecursion(res.find((item) => item.place === "header").items);
        this.footerExploreBnxtMenuItems = this.misc.sortMenuItemsByRecursion(
          res.find((item) => item.place === "footer" && item.slug === "explore-bnxt").items
        );
        this.footerLegalBnxtMenuItems = this.misc.sortMenuItemsByRecursion(
          res.find((item) => item.place === "footer" && item.slug === "legal").items
        );
      });
    },

    async getWelcomePopup() {
      await bannersService.getWelcomePopup().then((res) => {
        this.welcomePopup = res;

        if (this.welcomePopup.id) {
          this.isWelcomeModalVisible = this.isWelcomePopupVisible();
        }
      });
    },

    updateScroll() {
      this.scrollPosition = window.scrollY;
    },

    showMenuModal() {
      this.isMenuModalVisible = true;
      document.body.style.overflow = "hidden";
    },

    closeMenuModal() {
      this.isMenuModalVisible = false;
      document.body.style.overflow = "auto";
    },

    showFollowUsModal() {
      this.isFollowUsModalVisible = true;
    },

    isWelcomePopupVisible() {
      let isVisible = false;

      if (!document.cookie.includes("welcome_popup")) {
        const date = new Date();
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        document.cookie = `welcome_popup=show;expires=${date.toUTCString()};`;
        isVisible = true;
      }
      return isVisible;
    },
  },

  computed: {
    isAwardsPage() {
      return this.$route.path.includes("awards");
    },

    locale() {
      return this.$i18n.locale || localStorage.getItem("lang") || "en";
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iframe-wrapper {
  nav,
  footer,
  .cookie {
    display: none;
  }
}

.navbar {
  position: fixed !important;
  width: 95%;
  top: 15px;
  background: #000;
  margin: 0 2.5%;
  border-radius: 100px;
  padding: 12px 10px 12px 40px !important;
  box-shadow: 0 10px 30px rgb(103 200 255 / 60%);
  transition: 0.3s ease-out !important;
  z-index: 9999;

  &.awards {
    background-color: #eb3ce7;
    opacity: 0.85;
    box-shadow: 0 10px 30px rgb(235 60 231 / 50%);
  }

  .follow-us-modal {
    top: 94px;
  }

  &-nav {
    align-items: center;
  }

  &-collapse {
    justify-content: space-between;
  }

  &.full-width {
    box-shadow: 0 10px 30px rgb(249 223 68 / 60%);
    top: 0;
    width: 100%;
    margin: 0;
    border-radius: 0;
    padding: 15px 30px 15px 50px !important;

    .navbar-brand {
      img {
        max-width: 80px;
      }
    }

    .nav-item.dropdown .dropdown-menu {
      top: 51px;
    }

    .follow-us-modal {
      top: 73px;
      left: 80px;
    }
  }

  &-brand {
    padding: 0 !important;
    img {
      max-width: 90px;
    }
  }

  .nav-link {
    font-family: DrukWideItalic, Sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: #fff !important;
    padding: 0 10px !important;

    &:hover {
      color: #fff;
    }

    &.bordered {
      border: solid 2px #fff;
      border-radius: 100px;
      padding: 6px 15px !important;
      margin-left: 10px;

      svg {
        margin-right: 10px;
      }
    }

    img {
      max-height: 26px;
    }
  }

  &-toggler {
    img {
      width: 30px;
    }
  }

  .nav-item.dropdown {
    .dropdown-menu {
      background: #000;
      top: 54px;
      border-radius: 0;
      width: 198px;
      padding: 0;
      margin: 0;

      a {
        color: #fff;
        font-family: DrukWideItalic;
        font-size: 28px;
        text-transform: uppercase;
        transition: color 0.3s ease-out, background-color 0.3s ease-out;
        line-height: 42px;

        &:hover {
          background: #6749ed;
        }
      }
    }

    a {
      &:after {
        display: none;
      }

      svg {
        font-size: 14px;
        vertical-align: inherit;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 16px !important;
  display: block !important;
  background: 0 0 !important;
}

::-webkit-scrollbar-track {
  background: 0 0 !important;
}

::-webkit-scrollbar-thumb {
  border: 6px solid transparent !important;
  display: block !important;
  background: #787878 !important;
  border-radius: 10px !important;
  background-clip: padding-box !important;
}

@media only screen and (max-width: 767px) {
  .navbar-brand img {
    max-width: 60px !important;
  }

  .navbar-toggler img {
    width: 24px !important;
  }
}
</style>
