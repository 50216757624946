import { createStore } from "vuex";

export default createStore({
  state: {
    translations: null,
    players: false,
  },
  getters: {},
  mutations: {
    setTranslations(state, val) {
      state.translations = val;
    },

    playerPageIsLoaded(state, val) {
      state.players = val;
    },
  },
  actions: {},
  modules: {},
});
