<template>
  <div class="related-posts">
    <div class="container">
      <h2 class="title">{{ $t("related_posts.title") }}</h2>
      <div class="grid-container">
        <div class="news-box" v-for="article of related_articles">
          <NewsBox :article="article" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsBox from "@/views/news-and-videos/news-box";
export default {
  name: "related-posts",
  components: { NewsBox },
  props: {
    related_articles: [],
  },
};
</script>

<style scoped lang="scss">
.related-posts {
  background: #e2ff00;
  padding: 90px 0;

  .container {
    max-width: 1200px;

    .title {
      color: #003c80;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-size: 60px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.3em;
      text-align: center;
    }

    .grid-container {
      display: grid;
      gap: 50px 30px;
      margin-top: 40px;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .related-posts {
    .container {
      .grid-container {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .related-posts {
    .container {
      .grid-container {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
</style>
