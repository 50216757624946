export default {
  playerPositions: [
    {
      id: 1,
      key: "point_guard",
      value: "Point guard",
    },
    {
      id: 2,
      key: "shooting_guard",
      value: "Shooting guard",
    },
    {
      id: 3,
      key: "small_forward",
      value: "Small forward",
    },
    {
      id: 4,
      key: "power_forward",
      value: "Power forward",
    },
    {
      id: 5,
      key: "center",
      value: "Center",
    },
    {
      id: 6,
      key: "point_guard-shooting_guard",
      value: "Point guard, shooting guard",
    },
    {
      id: 7,
      key: "shooting_guard-small_forward",
      value: "Shooting guard, small forward",
    },
    {
      id: 8,
      key: "small_forward-power_forward",
      value: "Small forward, power forward",
    },
    {
      id: 9,
      key: "power_forward-center",
      value: "Power forward, center",
    },
  ],
};
