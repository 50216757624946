<template>
  <div v-show="playoffBracket?.id">
    <div class="playoff-bracket-wrapper">
      <img :src="playoffBracket?.banner_image?.public_url" :alt="playoffBracket?.alt" />
    </div>
  </div>
</template>
<script>
export default {
  name: "home-playoff-bracket",
  props: {
    playoffBracket: null,
  },
};
</script>
<style scoped lang="scss">
.playoff-bracket-wrapper {
  overflow-x: hidden;

  img {
    width: 100%;
  }
}
@media only screen and (max-width: 850px) {
  .playoff-bracket-wrapper {
    overflow-x: auto;

    img {
      width: 850px;
    }
  }
}
</style>
