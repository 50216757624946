<template>
  <div class="flex-container">
    <span>Filter</span>
    <div class="select-wrapper">
      <label>{{ $t("season") }}</label>
      <select v-model="filters.season" @change="changeSeason">
        <option :value="season.season" v-for="season in seasons">{{ season.season - 1 }} - {{ season.season }}</option>
      </select>
    </div>

    <div class="select-wrapper">
      <label>{{ $t("player_name") }}</label>
      <select v-model="selectedLetter" @change="$emit('selectedLetterChange', selectedLetter)">
        <option :value="null">{{ $t("all_players") }}</option>
        <option :value="letter" v-for="letter in alphabet">{{ letter.toUpperCase() }}</option>
      </select>
    </div>

    <div class="select-wrapper">
      <label>{{ $t("teams") }}</label>
      <select v-model="filters.competition_team_id" @change="emitFilters">
        <option :value="null">{{ $t("all_teams") }}</option>
        <option :value="team.id" v-for="team in teams">{{ team.name }}</option>
      </select>
    </div>

    <div class="select-wrapper">
      <label>{{ $t("positions") }}</label>
      <select v-model="filters.player_position_id" @change="emitFilters">
        <option :value="null">{{ $t("all_positions") }}</option>
        <option :value="position.id" v-for="position in positions">{{ $t("player_positions." + position.key) }}</option>
      </select>
    </div>

    <div class="select-wrapper">
      <label>{{ $t("country") }}</label>
      <select v-model="filters.nationality_code" @change="emitFilters">
        <option :value="null">{{ $t("all_countries") }}</option>
        <option :value="country" v-for="country in countries">{{ country }}</option>
      </select>
    </div>

    <input
      type="reset"
      @click="resetFilters()"
      v-if="
        selectedLetter ||
        filters.competition_team_id ||
        filters.player_position_id ||
        filters.nationality_code ||
        isSearchTerm
      "
      value="Reset"
    />
  </div>
</template>

<script>
import playerPositions from "@/core/helpers/player-positions";
import seasonService from "@/services/SeasonService";
import phaseService from "@/services/PhaseService";
import sportService from "@/services/SportService";
import playersService from "@/services/PlayersService";

export default {
  data() {
    return {
      alphabet: "abcdefghijklmnopqrstuvwxyz",
      positions: playerPositions.playerPositions.sort((a, b) => (a.value > b.value ? 1 : -1)),
      selectedLetter: null,
      seasons: [],
      teams: [],
      countries: [],
      filters: {
        competition_team_id: null,
        player_position_id: null,
        nationality_code: null,
        season: process.env.VUE_APP_ACTIVE_SEASON,
      },
    };
  },
  props: {
    isSearchTerm: Boolean,
  },
  mounted() {
    this.getSeasons();
    this.getTeamsForSelectedSeason();
    this.getPlayerCountries();
  },

  methods: {
    emitFilters() {
      this.$emit("filters", this.filters);
    },

    changeSeason() {
      this.filters.competition_team_id = null;
      this.filters.player_position_id = null;
      this.filters.nationality_code = null;
      this.selectedLetter = null;

      this.getTeamsForSelectedSeason();
      this.$emit("filters", this.filters);
    },

    resetFilters() {
      this.selectedLetter = null;
      this.filters = {
        competition_team_id: null,
        player_position_id: null,
        nationality_code: null,
        season: process.env.VUE_APP_ACTIVE_SEASON,
      };
      this.$emit("resetSearchFilter");
    },

    async getSeasons() {
      this.seasons = await seasonService.getSeasons();
    },

    async getTeamsForSelectedSeason() {
      await phaseService.getPhases(this.filters.season).then((data) => {
        const competitionId = data[0].competition.id;
        this.getTeamsByCompetitionId(competitionId);
      });
    },

    async getTeamsByCompetitionId(competitionId) {
      const teamCompetitions = await sportService.getTeamCompetitions(competitionId);
      this.teams = [...teamCompetitions.belgium, ...teamCompetitions.netherlands].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    },

    async getPlayerCountries() {
      this.countries = await playersService.getPlayerCountries();
    },
  },
};
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  gap: 25px;
  align-items: flex-end;
  width: 100%;

  span {
    font-family: DrukWideBoldItalic, Sans-serif;
    font-weight: 600;
    color: #000;
    min-width: 100px;
    font-size: 30px;
    text-transform: uppercase;
  }

  .select-wrapper {
    width: 48%;

    label {
      font-family: DrukWide;
      font-weight: 500;
      color: #000;
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .flex-container {
    flex-wrap: wrap;

    span {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 639px) {
  .flex-container {
    .select-wrapper {
      width: 100%;
    }
  }
}
</style>
