<template>
  <div class="hero" :style="{ backgroundImage: 'url(' + article?.cover_image?.thumbnails?.medium + ')' }"></div>
  <div class="flex-container">
    <div class="left-sidebar">
      <div class="news-category sticky-top">
        <span v-for="category in article?.categories">{{ category?.name }}</span>
      </div>
    </div>
    <div class="news">
      <h1>{{ article?.title }}</h1>
      <div class="content" v-html="article?.body"></div>
    </div>
    <div class="right-sidebar">
      <div class="share sticky-top">
        <div class="title">{{ $t("news.single_post.share_title") }}</div>
        <div class="social">
          <ShareNetwork
            network="facebook"
            :url="siteUrl + $route.fullPath"
            :title="article?.title"
            :description="article?.lead"
          >
            <font-awesome-icon icon="fa-brands fa-facebook" />
          </ShareNetwork>
          <ShareNetwork
            network="twitter"
            :url="siteUrl + $route.fullPath"
            :title="article?.title"
            :description="article?.lead"
          >
            <font-awesome-icon icon="fa-brands fa-twitter" />
          </ShareNetwork>
          <ShareNetwork
            network="linkedin"
            :url="siteUrl + $route.fullPath"
            :title="article?.title"
            :description="article?.lead"
          >
            <font-awesome-icon icon="fa-brands fa-linkedin" />
          </ShareNetwork>
          <ShareNetwork
            network="whatsapp"
            :url="siteUrl + $route.fullPath"
            :title="article?.title"
            :description="article?.lead"
          >
            <font-awesome-icon icon="fa-brands fa-whatsapp" />
          </ShareNetwork>
        </div>
      </div>
    </div>
  </div>

  <RelatedPosts :related_articles="article?.related_articles.slice(0, 3)" v-if="article?.related_articles?.length" />
</template>

<script>
import RelatedPosts from "@/views/news-and-videos/related-posts";
import newsAndVideosService from "@/services/NewsAndVideosService";
import misc from "@/core/helpers/misc";

export default {
  name: "single-post",
  components: { RelatedPosts },
  data() {
    return {
      article: {},
      siteUrl: process.env.VUE_APP_SITE_URL,
      loaders: {
        article: false,
      },
      misc: misc,
    };
  },

  mounted() {
    const slug = this.$route.params.slug;
    this.getArticle(slug);
  },

  methods: {
    async getArticle(slug) {
      this.article = await newsAndVideosService.getArticleBySlug(slug);
      this.loaders.article = true;
      this.changeUrlToCurrentLocale(this.article.slug);
      this.addMetaTags();
    },

    changeUrlToCurrentLocale(slug) {
      this.$router.replace({ params: { slug } }).catch(() => {});
    },

    addMetaTags() {
      let description = document.createElement("META");
      description.setAttribute("name", "description");
      description.setAttribute("content", this.article.meta_description);

      let ogImage = document.createElement("META");
      ogImage.setAttribute("name", "og:image");
      ogImage.setAttribute("content", this.article.cover_image.public_url);

      document.querySelector("head").appendChild(ogImage);
      document.querySelector("head").appendChild(description);
    },
  },
  beforeUnmount() {
    let desc = document.querySelector("meta[name='description']");
    desc.parentNode.removeChild(desc);

    let ogImage = document.querySelector("meta[name='og:image']");
    ogImage.parentNode.removeChild(ogImage);
  },

  updated() {
    this.misc.putInstagramScript("raw-html-embed");
  },
};
</script>

<style scoped lang="scss">
.hero {
  height: 300px;
  background-size: auto 300px;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 130px;
}

.flex-container {
  padding: 4% 6%;
  display: flex;

  .sticky-top {
    top: 200px;
  }

  .left-sidebar {
    width: 20%;
    padding: 10px;
    margin: 0 5% 0 0;

    .news-category {
      font-family: DrukWide, Sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      text-align: right;
      padding-right: 10px;
      border-right: 5px solid #ff0028;

      span {
        display: block;
      }
    }
  }

  .news {
    width: 60%;
    padding: 0 5%;

    h1 {
      color: #000;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-size: 70px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1em;
      margin-bottom: 30px;
    }

    .content {
      color: #000;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.4em;

      :deep(a) {
        color: #6441fa;
        text-decoration: none;
      }

      :deep(img) {
        width: 100%;
      }
    }
  }

  .right-sidebar {
    width: 20%;
    padding: 10px;

    .title {
      font-family: DrukWide, Sans-serif;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      text-align: left;
      padding-left: 10px;
      border-left: 5px solid #3264ff;
    }

    .social {
      display: flex;
      gap: 30px;
      margin: 15px 10px;

      svg {
        font-size: 20px;
        color: #3264ff;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .flex-container {
    flex-wrap: wrap;

    .left-sidebar {
      width: 30%;
      margin: 0;
    }

    .news {
      width: 70%;

      h1 {
        font-size: 35px;
      }
    }

    .right-sidebar {
      width: 100%;
      margin: 5% 0 0 35%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .flex-container {
    padding: 30px 10px 50px;
    flex-direction: column;

    .left-sidebar {
      width: 100%;

      .news-category {
        text-align: left;
        border-right: none;
        border-left: 5px solid #ff0028;
        padding-left: 10px;
      }
    }

    .news {
      width: 100%;

      h1 {
        font-size: 42px;
      }

      .content {
        font-size: 16px;
      }
    }

    .right-sidebar {
      margin: 0;
    }
  }

  .hero {
    margin-top: 115px;
  }
}
</style>
