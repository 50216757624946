<template>
  <div class="player" :class="[boxShadow, { inactive: !isActive }]">
    <img :src="catImage" class="category-img" v-if="isActive && categoryImage" />

    <div class="img-holder" :style="{ backgroundColor: backgroundColor, backgroundImage: bgImage }">
      <img :src="player.player.profile_image.public_url" v-if="player?.player?.profile_image?.public_url" />
      <img :src="player.profile_image.public_url" v-else-if="player?.profile_image?.public_url" />
      <img src="../../assets/img/image_not_found.png" class="not-found" v-else />
    </div>

    <div class="player-details">
      <slot v-if="player?.player">
        <div class="last-name">{{ player.player?.last_name?.toUpperCase() }}</div>
        <div class="first-name">{{ player.player?.first_name }}</div>
        <div class="position" v-if="player?.player_position?.name">
          {{ $t("player_positions." + player.player_position.name) }}
        </div>
      </slot>
      <slot v-else>
        <div class="last-name">{{ player?.name?.toUpperCase() }}</div>
        <div class="position">{{ player?.title?.name }}</div>
      </slot>
    </div>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";

export default {
  props: {
    player: {},
    backgroundColor: String,
    backgroundImage: String,
    boxShadow: String,
    categoryImage: String,
    isActive: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      misc: misc,
      bgImage: `url(${require("@/assets/img/awards/" + this.backgroundImage)})`,
      catImage: `${require("@/assets/img/awards/award-categories/" + this.categoryImage)}`,
    };
  },
};
</script>

<style scoped lang="scss">
.player {
  position: relative;
  z-index: 0;
  height: 100%;

  &.inactive {
    opacity: 0.3;
  }

  .category-img {
    width: 90px;
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 15px;
  }

  &:after {
    content: "";
    position: absolute;
    left: 15px;
    top: 15px;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &.green:after {
    background-color: #e2ff00;
  }

  &.gray:after {
    background-color: #464655;
  }

  &.light-green:after {
    background-color: #f5f5f5;
  }

  .img-holder {
    background-position: left bottom;
    background-size: contain;
    background-repeat: no-repeat;
    float: left;
    width: 100%;
    height: 33vw;
    padding: 0 20px;
    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 30px;
      object-fit: contain;

      &.not-found {
        height: 90%;
        object-fit: cover;
        bottom: 0;
        top: auto;
      }
    }
  }

  &-details {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 100px 0 25px 25px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));

    .first-name {
      text-transform: uppercase;
      color: #fff;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-weight: 600;
      font-size: 4vw;
      line-height: 0.9em;
    }

    .last-name {
      text-transform: uppercase;
      color: #fff;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-weight: 600;
      font-size: 2.5vw;
      line-height: 0.9em;
      margin-bottom: 3px;
    }

    .position {
      margin-bottom: 0;
      margin-top: 8px;
      font-family: DrukWide;
      font-weight: 500;
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}

@media only screen and (max-width: 980px) {
  .player {
    .img-holder {
      min-height: 36vw;
    }
  }
}

@media only screen and (max-width: 767px) {
  .player {
    min-height: 100vw;

    .img-holder {
      height: 100vw;
    }

    &-details {
      .first-name {
        font-size: 50px;
      }

      .last-name {
        font-size: 35px;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .player {
    .img-holder {
      min-height: 100vw;
    }
  }
}
</style>
