<template>
  <div class="pre-header">
    <h3>{{ $t("statistics.individual." + title) }}</h3>
    <a href="javascript:void(0)" @click="$emit('viewAll')"
      >{{ $t("statistics.view-all") }} <font-awesome-icon icon="fa-solid fa-arrow-right"></font-awesome-icon
    ></a>
  </div>

  <div class="statistics-box">
    <div class="header">
      <div class="team-tag">1</div>
      <span class="team-points">{{ misc.formatNumber(block[0].value) }}</span>
      <router-link
        :to="{
          name: misc.getRouteNameByLocale($router.options.routes, 'Team'),
          query: { team_id: block[0].team.id },
        }"
        class="team-url"
      >
        <div class="team">
          <img v-if="block[0].team.team_logo" :src="block[0].team.team_logo.public_url" class="img-fluid team-img" />

          <div class="team-name">
            <h4 class="team-short-name">{{ block[0].team.short_name }}</h4>
            <h4 class="team-long-name">{{ block[0].team.name }}</h4>
          </div>
        </div>
      </router-link>
    </div>
    <div class="content">
      <router-link
        :to="{ name: misc.getRouteNameByLocale($router.options.routes, 'Team'), query: { team_id: item.team.id } }"
        :key="item.id"
        class="team"
        v-for="(item, index) in block.slice(1)"
      >
        <span class="rank">{{ index + 2 }}</span>
        <div class="team-image">
          <img v-if="item.team.team_logo" :src="item.team.team_logo.public_url" alt="" />
        </div>
        <div class="team-details">
          <span class="player-name">{{ item.team.short_name }}</span>
          <h4 class="team-name">{{ item.team.name }}</h4>
        </div>
        <span class="team-points">{{ misc.formatNumber(item.value) }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";
export default {
  name: "team-statistics-box",
  props: ["title", "block"],
  emits: ["viewAll"],
  data() {
    return {
      boxImg: String,
      misc: misc,
    };
  },
};
</script>

<style scoped lang="scss">
.pre-header {
  display: flex;
  justify-content: space-between;
  font-family: DrukWideBoldItalic, Sans-serif;

  h3 {
    color: #000;
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
  }

  a {
    font-size: 22px;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;

    svg {
      color: #6441ff;
    }
  }
}

.stat-box-wrapper {
  background-size: cover;
  background-position: center;

  &:nth-child(3n + 1) .header {
    background: url("../../../assets/img/statistics/stat_bg_1.jpg");
  }
  &:nth-child(3n + 2) .header {
    background: url("../../../assets/img/statistics/stat_bg_2.jpg");
  }
  &:nth-child(3n + 3) .header {
    background: url("../../../assets/img/statistics/stat_bg_3.jpg");
  }
}

@media screen and (max-width: 980px) {
  .stat-box-wrapper {
    &:nth-child(2n + 1) .header {
      background: url("../../../assets/img/statistics/stat_bg_1.jpg");
    }
    &:nth-child(2n + 2) .header {
      background: url("../../../assets/img/statistics/stat_bg_2.jpg");
    }
  }
}

.statistics-box {
  background: #fff;
  color: #000;
  position: relative;
  z-index: 1;
  padding: 0;
  box-shadow: 0 5px 5px #def3ff;
  margin-bottom: 30px;
  border-radius: 10px;

  .header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 30px 20px 0;
    position: relative;
    height: 160px;
    background-size: cover !important;
    background-position: center !important;

    .team-url {
      text-decoration: none;
    }

    .team-tag {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      position: absolute;
      left: 10px;
      color: #fff;
      top: 10px;
      font-weight: 800;
      z-index: 1;
      font-size: 25px;

      sup {
        margin-right: 3px;
      }
    }

    .team-points {
      font-size: 22px;
      color: #fff;
      font-family: DrukWide, Sans-serif;
      font-weight: 600;
      position: absolute;
      right: 20px;
      bottom: 10px;
    }

    .team {
      display: flex;
      align-items: center;
      height: calc(100% - 15px);
      gap: 30px;

      &-img {
        max-width: 60px;
        margin-left: 30px;
      }

      &-name {
        display: flex;
        flex-direction: column;

        .team-short-name {
          font-weight: 600;
          color: #fff;
          padding-bottom: 0;
          margin-top: 0;
          margin-bottom: 0;
          font-family: DrukWide, Sans-serif;
          text-transform: uppercase;
          font-size: 20px;
        }

        .team-long-name {
          font-size: 30px;
          font-family: DrukWideBoldItalic, Sans-serif;
          color: #fff;
          text-transform: uppercase;
        }
      }
    }
  }

  .content {
    padding-bottom: 30px;

    & > * {
      text-decoration: none;
      color: #000;
    }

    .team {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;

      .rank {
        display: inline-block;
        width: 30px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        font-family: DrukWide, Sans-serif;
        padding-top: 7px;
      }

      .team-image {
        img {
          border-radius: 5px;
          max-width: 45px;
          max-height: 45px;
          width: auto;
          height: auto;
        }
      }

      .team-details {
        .player-name {
          text-decoration: none;
          color: #000;
          font-weight: 600;
          font-size: 13px;
          font-family: DrukWide;
          text-transform: uppercase;
        }

        .team-name {
          font-family: Helvetica Neue, Helvetica, Arial, sans-serif !important;
          font-weight: 600;
          font-size: 15px;
        }
      }

      .team-points {
        font-size: 14px;
        font-family: DrukWide;
        font-weight: 800;
        margin-left: auto;
        padding-right: 10px;
        line-height: 1;
      }
    }
  }
}
</style>
