import CalendarView from "@/views/calendar/CalendarView";
import GameReport from "@/views/game-report/game-report";

export const calendarRoutes = [
  {
    lang: "en",
    path: "/en/calendar",
    name: "Calendar",
    component: CalendarView,
  },
  {
    lang: "fr",
    path: "/fr/calendrier",
    name: "Calendrier",
    component: CalendarView,
  },
  {
    lang: "nl",
    path: "/nl/kalender",
    name: "Kalender",
    component: CalendarView,
  },
  {
    lang: "en",
    path: "/en/game-report/:gameId",
    name: "Game report",
    component: GameReport,
  },
  {
    lang: "fr",
    path: "/fr/rapportdejeu/:gameId",
    name: "Rapportdejeu",
    component: GameReport,
  },
  {
    lang: "nl",
    path: "/nl/spelverslag/:gameId",
    name: "Spelverslag",
    component: GameReport,
  },
];
