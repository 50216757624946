import StaticPage from "@/views/static-page/static-page";

export const staticPageRoutes = [
  {
    lang: "en",
    path: "/en/:staticPage",
    name: "Static page",
    component: StaticPage,
    props: true,
  },
  {
    lang: "fr",
    path: "/fr/:staticPage",
    name: "Static page FR",
    component: StaticPage,
    props: true,
  },
  {
    lang: "nl",
    path: "/nl/:staticPage",
    name: "Static page NL",
    component: StaticPage,
    props: true,
  },
];
