import EventList from "@/views/event/event-list";
import EventView from "@/views/event/event-view";

export const eventsRoutes = [
  {
    lang: "en",
    path: "/en/events",
    name: "Events",
    component: EventList,
  },
  {
    lang: "fr",
    path: "/fr/events",
    name: "Événements",
    component: EventList,
  },
  {
    lang: "nl",
    path: "/nl/events",
    name: "Evenementen",
    component: EventList,
  },
  {
    lang: "en",
    path: "/en/event/:slug",
    name: "Event-view",
    component: EventView,
  },
  {
    lang: "fr",
    path: "/fr/event/:slug",
    name: "Event-view FR",
    component: EventView,
  },
  {
    lang: "nl",
    path: "/nl/event/:slug",
    name: "Event-view NL",
    component: EventView,
  },
];
