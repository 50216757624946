<template>
  <div class="survey-steps" id="rules">
    <div class="steps">
      <div class="title">3 simple steps.</div>

      <div class="step">
        <h5 class="number">01</h5>
        <h3 class="title">ANSWER SIMPLE QUESTION</h3>
        <p class="description">
          We’ll test your knowledge on the BNXT League. How well do you know our teams & players?
        </p>
      </div>

      <div class="step">
        <h5 class="number">02</h5>
        <h3 class="title">REGISTER FOR THE CONTEST</h3>
        <p class="description">Tell us a bit more about yourself & make sure we can get in touch in case you win.</p>
      </div>

      <div class="step">
        <h5 class="number">03</h5>
        <h3 class="title">WATCH GAMES & WIN AGAIN</h3>
        <p class="description">
          Watch all our games on BNXT.tv. Come back every two weeks to play again & win VIP tickets.
        </p>
      </div>
    </div>

    <div class="content">
      <p class="explanation">
        If your answers to these questions are correct you will automatically participate in the competition.
        Participation is free. The winners will be contacted by the BNXT League. Click
        <a href="https://bnxt.sportpress.info/storage/C/m/Cms8D81UOtS5KbQj2xCo485Kt1668773656.pdf" target="_blank">
          here
        </a>
        to open the terms & conditions.
      </p>

      <a href="#answering" class="to-answering" @click.prevent="misc.scrollTo('#answering')">Start answering</a>
    </div>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";
export default {
  name: "survey-steps",
  data() {
    return {
      misc: misc,
    };
  },
};
</script>

<style scoped lang="scss">
.survey-steps {
  background: #000;
  padding: 6% 8%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .steps {
    display: flex;
    gap: 40px;

    .title {
      width: 25%;
      color: #fff;
      font-family: DrukWideItalic, Sans-serif;
      font-size: 80px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.85em;
    }

    .step {
      width: 25%;
      display: flex;
      gap: 15px;
      flex-direction: column;

      .number {
        color: #e2ff00;
        font-family: DrukWideItalic, Sans-serif;
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 0.85em;
        margin: 0;
      }

      .title {
        width: 100%;
        color: #fff;
        font-family: DrukWideItalic, Sans-serif;
        font-size: 50px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 0.85em;
        margin-bottom: 15px;
      }

      .description {
        color: #ffffffcf;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.4em;
      }
    }
  }

  .content {
    margin-left: 25%;
    padding-bottom: 40px;

    .explanation {
      margin-bottom: 50px;
      padding: 20px;
      background-color: #2b2b2b;
      border-radius: 8px;
      text-align: left;
      color: #fff;
      font-size: 18px;
      font-weight: 500;

      a {
        color: #cc3366;
        text-decoration: none;

        &:hover {
          color: #333366;
        }
      }
    }

    .to-answering {
      display: inline-block;
      font-family: DrukWideItalic, Sans-serif;
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.85em;
      fill: #070707;
      color: #070707;
      background-color: #e2ff00;
      border-radius: 100px;
      padding: 20px 40px;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .survey-steps {
    padding: 8% 4%;

    .steps {
      .title {
        font-size: 60px;
      }

      .step {
        .number {
          font-size: 24px;
        }

        .title {
          font-size: 35px;
        }

        .description {
          font-size: 14px;
        }
      }
    }

    .content {
      .explanation {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .survey-steps {
    padding: 50px 20px 100px;

    .steps {
      flex-direction: column;
      gap: 50px;

      .title {
        width: 100%;
      }

      .step {
        width: 100%;
        gap: 10px;

        .description {
          font-size: 16px;
        }
      }
    }

    .content {
      margin: 30px 0 0;
      padding: 0;

      .to-answering {
        font-size: 24px;
        padding: 15px 30px;
      }
    }
  }
}
</style>
