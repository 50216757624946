<template>
  <div class="team-statistics-single">
    <div class="table-wrapper" v-if="playerAverages?.length">
      <h4>Team Statistics</h4>
      <table class="table table-bordered bnxt-table">
        <thead>
          <tr>
            <th colspan="4">{{ selectedTeam?.name }}</th>
            <th colspan="2">GAME</th>
            <th colspan="3">2P</th>
            <th colspan="3">3P</th>
            <th colspan="3">FG</th>
            <th colspan="3">FT</th>
            <th colspan="3">REBOUNDS</th>
            <th colspan="2">FOULS</th>
            <th colspan="3">&nbsp;</th>
            <th colspan="2">BLOCKS</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <th class="border-left-0 border-right-0">PHOTO</th>
            <th class="border-left-0 border-right-0">NAME</th>
            <th class="border-left-0 border-right-0">PTS</th>
            <th class="border-title border-left-0">MIN</th>
            <th class="border-right-0">G</th>
            <th class="border-title border-left-0">GS</th>
            <th class="border-right-0">2PM</th>
            <th class="border-left-0 border-right-0">2PA</th>
            <th class="border-title border-left-0">2P%</th>
            <th class="border-left-0 border-right-0">3PM</th>
            <th class="border-left-0 border-right-0">3PA</th>
            <th class="border-title border-left-0">3P%</th>
            <th class="border-left-0 border-right-0">FGM</th>
            <th class="border-left-0 border-right-0">FGA</th>
            <th class="border-title border-left-0">FG%</th>
            <th class="border-left-0 border-right-0">FTM</th>
            <th class="border-left-0 border-right-0">FTA</th>
            <th class="border-title border-left-0">FT%</th>
            <th class="border-left-0 border-right-0">DR</th>
            <th class="border-left-0 border-right-0">OR</th>
            <th class="border-title border-left-0">TOT</th>
            <th class="border-left-0 border-right-0">FP</th>
            <th class="border-title border-left-0">DF</th>
            <th class="border-left-0 border-right-0">AST</th>
            <th class="border-left-0 border-right-0">ST</th>
            <th class="border-title border-left-0">TO</th>
            <th class="border-left-0 border-right-0">BS</th>
            <th class="border-title border-left-0">BR</th>
            <th class="border-left-0 border-right-0">EFF</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="player in playerAverages">
            <td>
              <img
                :src="player.player.profile_image.thumbnails.small"
                class="img-fluid profile-image"
                v-if="player?.player?.profile_image?.thumbnails?.small"
              />
              <img src="../../../assets/img/image_not_found.png" class="img-fluid profile-image" v-else />
            </td>
            <td>{{ player.player.first_name }} {{ player.player.last_name.toUpperCase() }}</td>
            <td>{{ misc.formatNumber(player.points) }}</td>
            <td>{{ misc.formatNumber(player.minute) }}</td>
            <td>{{ misc.formatNumber(player.played_games) }}</td>
            <td>{{ misc.formatNumber(player.starter) }}</td>

            <td>{{ misc.formatNumber(player.dunk_made + player.two_point_made) }}</td>
            <td>{{ misc.formatNumber(player.dunk_all + player.two_point_all) }}</td>
            <td>
              {{
                misc.formatNumber(
                  ((player.dunk_made + player.two_point_made) / (player.dunk_all + player.two_point_all)) * 100
                )
              }}
            </td>

            <td>{{ misc.formatNumber(player.three_point_made) }}</td>
            <td>{{ misc.formatNumber(player.three_point_all) }}</td>
            <td>{{ misc.formatNumber(player.three_point_percent) }}</td>

            <td>{{ misc.formatNumber(player.dunk_made + player.two_point_made + player.three_point_made) }}</td>
            <td>{{ misc.formatNumber(player.dunk_all + player.two_point_all + player.three_point_all) }}</td>
            <td>
              {{
                misc.formatNumber(
                  ((player.dunk_made + player.two_point_made + player.three_point_made) /
                    (player.dunk_all + player.two_point_all + player.three_point_all)) *
                    100,
                  true
                )
              }}
            </td>

            <td>{{ misc.formatNumber(player.free_throw_made) }}</td>
            <td>{{ misc.formatNumber(player.free_throw_all) }}</td>
            <td>{{ misc.formatNumber(player.free_throw_percent) }}</td>

            <td>{{ misc.formatNumber(player.defensive_rebound) }}</td>
            <td>{{ misc.formatNumber(player.offensive_rebound) }}</td>
            <td>{{ misc.formatNumber(player.defensive_rebound + player?.offensive_rebound) }}</td>
            <td>{{ misc.formatNumber(player.foul) }}</td>
            <td>{{ misc.formatNumber(player.defensive_foul) }}</td>
            <td>{{ misc.formatNumber(player.assist) }}</td>
            <td>{{ misc.formatNumber(player.steal) }}</td>
            <td>{{ misc.formatNumber(player.turnover) }}</td>
            <td>{{ misc.formatNumber(player.block) }}</td>
            <td>{{ misc.formatNumber(player.block_against) }}</td>
            <td>{{ misc.formatNumber(player.value) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else>{{ $t("team.no_statistics") }}</div>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";
export default {
  name: "team-statistics-single",

  data() {
    return {
      misc: misc,
    };
  },

  props: {
    playerAverages: null,
  },
};
</script>

<style scoped lang="scss">
.team-statistics-single {
  .table-wrapper {
    h4 {
      border: 0;
      color: #000;
      padding: 0;
      margin: 0 0 25px;
      text-transform: uppercase;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-weight: 600;
      font-size: 28px;
    }

    table {
      text-align: center !important;

      thead {
        tr {
          border: 1px solid;

          &:nth-child(2) {
            th {
              border-radius: 0 !important;
            }
          }
        }
      }

      tbody {
        .profile-image {
          max-width: 35px;
        }
      }
    }
  }
}
</style>
