<template>
  <div class="referees">
    <div class="hero">
      <h3 class="title">{{ $t("referees.title") }}</h3>
    </div>

    <div class="content">
      <div class="grid-container">
        <slot v-for="referee in referees">
          <RefereeCard :referee="referee" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import staffsService from "@/services/StaffsService";
import misc from "@/core/helpers/misc";
import RefereeCard from "@/views/referees/RefereeCard";
export default {
  name: "Referees",
  components: { RefereeCard },

  data() {
    return {
      misc: misc,
      referees: null,
      loaders: {
        referees: false,
      },
    };
  },

  mounted() {
    this.getReferees();
  },

  methods: {
    async getReferees() {
      this.referees = await staffsService.getReferees();
      this.loaders.referees = true;
    },
  },
};
</script>

<style scoped lang="scss">
.hero {
  background: url("../../assets/img/hero/bnx_bg-pink.jpg") no-repeat top left;
  background-size: cover;
  height: 400px;
  padding: 8% 8% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-family: DrukWideBoldItalic, Sans-serif;
    font-weight: 600;
    font-size: 72px;
    text-transform: uppercase;
    color: #fff;
    line-height: 67px;
  }
}

.content {
  padding: 90px 8%;
  background: #f2faff;

  .grid-container {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 60px;
    grid-template-columns: auto auto auto auto;
  }
}

@media only screen and (max-width: 1200px) {
  .hero {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 980px) {
  .hero {
    height: 400px;
  }

  .content {
    .grid-container {
      grid-template-columns: auto auto auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .content {
    .grid-container {
      grid-template-columns: auto auto;
    }
  }
}

@media only screen and (max-width: 550px) {
  .content {
    .grid-container {
      grid-template-columns: auto;
    }
  }
}
</style>
