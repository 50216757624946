<template>
  <div class="video-box">
    <div class="img-wrapper">
      <div class="img-overlay"></div>
      <img :src="videoArticle?.cover_image" class="img-fluid" />
      <div class="watch-now-wrapper">
        <a href="javascript:void(0)" class="watch-now" @click="$emit('playVideo', videoArticle?.video_url)">
          <img src="../../assets/img/icons/bnxt_icon_play.svg" alt="Play video" />
          <span>{{ $t("video_highlights.button") }}</span>
        </a>
      </div>
    </div>
    <h3 class="video-title">{{ videoArticle?.name }}</h3>
  </div>
</template>

<script>
export default {
  name: "home-video-box",
  props: {
    videoArticle: {},
  },
};
</script>

<style scoped lang="scss">
.video-box {
  .img-wrapper {
    position: relative;
    box-shadow: 10px -10px 0 0 #eb3ce7;
    margin-top: 10px;
    overflow: hidden;

    .img-overlay {
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(180deg, #00000000 65%, #000000 100%);
      opacity: 0.5;
    }

    .img-fluid {
      position: relative;
      top: -2.6vw;
      width: 100%;
      margin-bottom: -50px;
    }

    .watch-now-wrapper {
      background: #000;
      position: absolute;
      height: 50px;
      bottom: 0;
      width: 100%;
    }

    .watch-now {
      position: relative;
      left: 10px;
      bottom: -6px;
      text-decoration: none;
      display: flex;
      align-items: center;

      img {
        width: 38px;
      }

      span {
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 30px;
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1em;
        color: #fff;
        position: relative;
        margin-left: 10px;

        &:hover {
          &:after {
            width: 0;
          }
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: currentColor;
          transition: background-color 0.25s ease-in-out, width 0.36s cubic-bezier(0.51, 0.5, 0.07, 0.99);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
        }
      }
    }
  }

  .video-title {
    color: #fff;
    font-family: DrukWideItalic, Sans-serif;
    font-size: 42px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1em;
    margin-top: 28px;
  }
}

@media only screen and (max-width: 1200px) {
  .video-box {
    .video-title {
      font-size: 35px;
    }

    .img-wrapper {
      .img-fluid {
        top: -4.2vw;
      }
    }
  }
}

@media only screen and (max-width: 766px) {
  .video-box {
    .img-wrapper {
      .img-fluid {
        top: -8.8vw;
      }
    }
  }
}
</style>
