<template>
  <div class="sixth-man-of-the-year">
    <img src="../../assets/img/awards/bnx_comet-big-purple-min-979x1024.png" class="background-img-left" />
    <img src="../../assets/img/awards/bnx_comet-small-orange-min-979x1024.png" class="background-img-right" />

    <div class="header">
      <h2 class="title">{{ awardCategory?.title }}</h2>
      <p class="description">{{ awardCategory?.description }}</p>
    </div>

    <div class="content">
      <slot v-for="player in awardCategory?.rosters">
        <AwardsPlayerCard
          :player="player"
          :is-active="player?.is_winner === 1"
          background-color="#000"
          background-image="bnx_bg-mvp-bubble-min.png"
          category-image="BNXT_Awards-winner-sixthman-min.png"
          :box-shadow="'green'"
        />
      </slot>
      <slot v-for="player in getPlaceholderCount(awardCategory)">
        <AwardsPlayerCard
          :player="null"
          :is-active="false"
          background-color="#000"
          background-image="bnx_bg-mvp-bubble-min.png"
          category-image="BNXT_Awards-winner-sixthman-min.png"
          :box-shadow="'green'"
        />
      </slot>
    </div>
  </div>

  <div class="section-filler"></div>
</template>

<script>
import AwardsPlayerCard from "@/views/awards/awards-player-card";

export default {
  name: "awards-sixth-man-of-the-year",
  components: { AwardsPlayerCard },
  props: {
    awardCategory: null,
  },
  methods: {
    getPlaceholderCount(awardCategory) {
      let length = 0;
      if (awardCategory) {
        length = awardCategory.rosters.length;
        return awardCategory.placeholder_count - length > 0 ? awardCategory.placeholder_count - length : 0;
      }
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
.sixth-man-of-the-year {
  background-color: #67c8ff;
  padding: 6% 8% 0;
  position: relative;

  .background-img-left {
    position: absolute;
    top: -450px;
    width: 900px;
  }

  .background-img-right {
    position: absolute;
    width: 500px;
    right: 15%;
    top: 30%;
  }

  .header {
    display: flex;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;

    .title {
      color: #fff;
      font-family: DrukWide, Sans-serif;
      font-size: 4vw;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.85em;
      width: 50%;
      padding-right: 10px;
    }

    .description {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.4em;
      width: 50%;
    }
  }

  .content {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 70px;
  }
}

.section-filler {
  height: 320px;
  background-image: linear-gradient(180deg, #67c8ff 0%, #003c80 100%);
}

@media only screen and (max-width: 1200px) {
  .sixth-man-of-the-year {
    padding: 4%;

    .background-img-left {
      width: 400px;
      top: -300px;
      left: 50px;
    }

    .header {
      .title {
        font-size: 6vw;
      }

      .description {
        font-size: 16px;
      }
    }
  }

  .section-filler {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .sixth-man-of-the-year {
    .background-img-left {
      top: -200px;
      max-width: calc(90vw - 50px);
    }

    .background-img-right {
      top: 0;
      max-width: calc(90vw - 50px);
    }

    .header {
      flex-direction: column;

      .title {
        font-size: 24px;
        width: 100%;
      }

      .description {
        font-size: 14px;
        width: 100%;
      }
    }

    .content {
      display: grid;
      grid-template-columns: auto;
      gap: 70px;
    }
  }

  .section-filler {
    height: 170px;
  }
}
</style>
