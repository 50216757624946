<template>
  <div class="news">
    <router-link
      :to="{
        name: misc.getRouteNameByLocale($router.options.routes, 'SinglePost'),
        params: { slug: article?.slug || 0 },
      }"
      v-if="!article?.video_url"
    >
      <div
        class="img-wrapper"
        :style="{
          backgroundImage: 'url(' + article?.cover_image?.thumbnails?.small + ')',
        }"
      ></div>

      <div class="post-marker">
        <img src="../../assets/img/icons/news-icon.svg" />
        <span class="post-type">news</span>
      </div>
    </router-link>

    <div class="video-post-header" v-else-if="article?.video_url" @click="showVideoModal(article.video_url)">
      <div
        class="img-wrapper"
        :style="{
          backgroundImage: 'url(' + article?.cover_image + ')',
        }"
      ></div>
      <div class="post-marker">
        <img src="../../assets/img/icons/bnxt_icon_play.svg" class="video" />
        <span class="post-type">video</span>
      </div>
    </div>

    <div class="time">
      {{ moment(article?.publish_date).locale(currentLanguage).fromNow() }}
    </div>
    <h3 class="title">{{ article?.title || article?.name }}</h3>
    <div class="description" v-if="isDescriptionVisible">
      <slot v-if="article?.lead">
        {{ article?.lead.length > 120 ? article?.lead.substring(0, 120) + "..." : article?.lead }}
      </slot>
      <slot v-if="article?.description">
        {{ article?.description.length > 120 ? article?.description.substring(0, 120) + "..." : article?.description }}
      </slot>
    </div>
    <router-link
      :to="{
        name: misc.getRouteNameByLocale($router.options.routes, 'SinglePost'),
        params: { slug: article?.slug || 0 },
      }"
      :key="$route.fullPath"
      class="read-btn"
      v-if="!article?.video_url"
    >
      {{ $t("news.news_button") }} <font-awesome-icon icon="fa-solid fa-arrow-right" /><font-awesome-icon
        icon="fa-solid fa-arrow-right"
      />
    </router-link>
    <a href="javascript:void(0)" class="read-btn" v-if="article?.video_url" @click="showVideoModal(article.video_url)">
      {{ $t("news.video_button") }} <font-awesome-icon icon="fa-solid fa-arrow-right" /><font-awesome-icon
        icon="fa-solid fa-arrow-right"
    /></a>
  </div>

  <VideoModal :video-id="currentVideoId" @close="closeVideoModal" v-if="isVideoModalVisible" />
</template>

<script>
import moment from "moment";
import VideoModal from "@/components/shared/modals/video-modal";
import misc from "@/core/helpers/misc";

export default {
  name: "news-box",
  components: { VideoModal },
  props: {
    article: {},
    isDescriptionVisible: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      currentVideoId: String,
      isVideoModalVisible: false,
      misc: misc,
      currentLanguage: localStorage.getItem("lang"),
    };
  },

  computed: { moment: () => moment },

  methods: {
    showVideoModal(videoUrl) {
      this.currentVideoId = misc.getVideoId(videoUrl);
      this.isVideoModalVisible = true;
    },

    closeVideoModal() {
      this.currentVideoId = null;
      this.isVideoModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.news {
  position: relative;

  .video-post-header {
    cursor: pointer;
  }

  .img-wrapper {
    height: 280px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .post-marker {
    position: absolute;
    top: 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;

    img {
      max-width: 40px;
    }

    .post-type {
      font-family: DrukWide, Sans-serif;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;
      margin-left: 10px;
    }
  }

  .time {
    color: #6441ff;
    font-family: DrukWide, Sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.3em;
    padding: 20px 0;
  }

  .title {
    color: #000;
    font-family: DrukWideItalic, Sans-serif;
    font-size: 42px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1em;
    margin-bottom: 15px;
  }

  .description {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3em;
    margin-bottom: 20px;
  }

  .read-btn {
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: 2px;
    color: #000;
    text-decoration: none;

    &:hover {
      svg {
        &:first-child {
          transform: translateX(200%) translateY(0);
          transition-delay: 0.1s;
          opacity: 0;
        }

        &:nth-child(2) {
          transform: translateX(0) translateY(0) translatez(0);
          transition-delay: 0.25s;
          opacity: 1;
        }
      }
    }

    svg {
      transition: transform 0.38s cubic-bezier(0.37, 0.08, 0.02, 0.93), opacity 0.18s ease-in-out;
      will-change: transform;
      font-size: 20px;
      color: #6441ff;
      vertical-align: inherit;
      margin-left: 5px;

      &:first-child {
        transform: translateX(0) translateY(0);
        transition-delay: 0.1s;
        opacity: 1;
      }

      &:nth-child(2) {
        transform: translateX(-150%) translateY(0);
        transition-delay: 0s;
        opacity: 0;
        position: relative;
        left: -20px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .news {
    .img-wrapper {
      height: 200px;
    }

    .title {
      font-size: 35px;
    }

    .description {
      font-size: 16px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .news {
    .img-wrapper {
      height: 120px;
    }
  }
}
</style>
