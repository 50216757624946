<template>
  <h3 class="title">{{ $t("game_report.overview.game_leaders") }}</h3>
  <div class="game-report-overview-game-leaders">
    <div class="header">
      <div class="team">
        <h2 class="team-short-name">{{ gameLeaders?.home?.competitor?.competition_team?.short_name }}</h2>
      </div>

      <div class="team">
        <h2 class="team-short-name">{{ gameLeaders?.away?.competitor?.competition_team?.short_name }}</h2>
      </div>
    </div>

    <div class="content">
      <div class="stat-row" v-for="stat in ['points', 'total_rebound', 'assist', 'steal', 'block']">
        <div class="player">
          <img
            :src="gameLeaders.home.leaders[stat].player.profile_image.thumbnails.small"
            class="img-fluid"
            v-if="gameLeaders?.home?.leaders[stat]?.player?.profile_image?.thumbnails.small"
          />
          <img src="../../../assets/img/image_not_found.png" class="img-fluid" v-else />
          <div class="player-details">
            <div class="name">
              {{ gameLeaders?.home?.leaders[stat]?.player?.first_name }}
              {{ gameLeaders?.home?.leaders[stat]?.player?.last_name?.toUpperCase() }}
            </div>
            <div class="score">{{ gameLeaders?.home?.leaders[stat]?.value }}</div>
          </div>
        </div>

        <div class="stat-name">{{ stat }}</div>

        <div class="player second">
          <div class="player-details">
            <div class="name">
              {{ gameLeaders?.away?.leaders[stat]?.player?.first_name }}
              {{ gameLeaders?.away?.leaders[stat]?.player?.last_name?.toUpperCase() }}
            </div>
            <div class="score">{{ gameLeaders?.away?.leaders[stat]?.value }}</div>
          </div>
          <img
            :src="gameLeaders.away.leaders[stat].player.profile_image.public_url"
            class="img-fluid"
            v-if="gameLeaders?.away?.leaders[stat]?.player?.profile_image?.public_url"
          />
          <img src="../../../assets/img/image_not_found.png" class="img-fluid" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "game-report-overview-game-leaders",
  props: {
    gameLeaders: null,
  },
};
</script>

<style scoped lang="scss">
.title {
  font-family: DrukWideBoldItalic, Sans-serif;
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
}

.game-report-overview-game-leaders {
  background: #fff;
  box-shadow: 0 5px 5px #def3ff;
  border-radius: 10px;
  padding: 35px 15px 37px;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .team {
      width: 50%;
      text-align: center;

      &-short-name {
        font-family: DrukWide;
        font-size: 19px;
        margin: 0;
        font-weight: 600;
      }
    }
  }

  .content {
    .stat-row {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .player {
        width: 30%;
        display: flex;
        gap: 5px;

        &.second {
          justify-content: flex-end;

          .player-details {
            text-align: right;
          }
        }

        img {
          max-width: 45px;
          height: 45px;
        }

        .player-details {
          .name {
            font-size: 14px;
            font-weight: bold;
          }

          .score {
            font-family: DrukWide;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }

      .stat-name {
        width: 40%;
        text-align: center;
        border-radius: 20px;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        background: #6441ff;
        text-transform: uppercase;
        padding: 5px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .game-report-overview-game-leaders {
    padding-bottom: 20px;

    .header {
      justify-content: space-between;

      .team {
        width: auto;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .game-report-overview-game-leaders {
    .header {
      justify-content: space-around;
      gap: 40%;
    }

    .content {
      .player {
        flex-direction: column;
        align-items: center;
        text-align: center;

        &.second {
          flex-direction: column-reverse;

          .player-details {
            text-align: center !important;
          }
        }
      }
    }
  }
}
</style>
