<template>
  <div class="flex-container">
    <div class="select-wrapper">
      <label>{{ $t("season") }}</label>
      <select v-model="filters.season" @change="onChangeSeason">
        <option v-for="season in seasons" :value="season.season">{{ season.season - 1 }} - {{ season.season }}</option>
      </select>
    </div>

    <div class="select-wrapper">
      <label>{{ $t("phase") }}</label>
      <select v-model="filters.phase">
        <option :value="null">{{ $t("overall") }}</option>
        <option v-for="phase in phases" :value="phase.id">{{ phase.name }}</option>
      </select>
    </div>

    <div class="select-wrapper">
      <label>{{ $t("team") }}</label>
      <select v-model="filters.team">
        <option :value="null">{{ $t("all_teams") }}</option>
        <option v-for="team in teams" :value="team.id">{{ team.name }}</option>
      </select>
    </div>

    <div class="select-wrapper">
      <label>{{ $t("month") }}</label>
      <select v-model="filters.month">
        <option value="-1">{{ $t("all_months") }}</option>
        <option :value="1">{{ $t("months.january") }}</option>
        <option :value="2">{{ $t("months.february") }}</option>
        <option :value="3">{{ $t("months.march") }}</option>
        <option :value="4">{{ $t("months.april") }}</option>
        <option :value="5">{{ $t("months.may") }}</option>
        <option :value="6">{{ $t("months.june") }}</option>
        <option :value="7">{{ $t("months.july") }}</option>
        <option :value="8">{{ $t("months.august") }}</option>
        <option :value="9">{{ $t("months.september") }}</option>
        <option :value="10">{{ $t("months.october") }}</option>
        <option :value="11">{{ $t("months.november") }}</option>
        <option :value="12">{{ $t("months.december") }}</option>
      </select>
    </div>

    <div class="search-btn">
      <button @click="$emit('filtersChanged', this.filters)">{{ $t("search") }}</button>
    </div>
  </div>
</template>

<script>
import seasonService from "@/services/SeasonService";
import phaseService from "@/services/PhaseService";
import sportService from "@/services/SportService";

export default {
  name: "calendar-filter",
  props: {
    currentMonthNumber: null,
  },
  data() {
    return {
      seasons: [],
      teams: [],
      phases: [],
      filters: {
        season: null,
        phase: null,
        team: null,
        month: null,
      },
    };
  },
  watch: {
    currentMonthNumber(currentMonthNumber) {
      this.filters.month = currentMonthNumber;
    },
  },
  mounted() {
    this.getSeasons();
  },
  methods: {
    async getSeasons() {
      this.seasons = await seasonService.getSeasons();
      this.filters.season = this.seasons[0].season;
      this.getPhases(this.seasons[0].season);
    },

    async getPhases(season) {
      this.phasesComp = await phaseService.getPhases(season);
      this.phases = this.phasesComp[0].phases;
      this.$emit("phaseId", this.filters.phase);

      this.getTeamsByCompetitionId(this.phasesComp[0].competition.id);
    },

    async getTeamsByCompetitionId(competitionId) {
      const teamCompetitions = await sportService.getTeamCompetitions(competitionId);
      this.teams = [...teamCompetitions.belgium, ...teamCompetitions.netherlands].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
      this.filters.team = null;
    },

    onChangeSeason() {
      this.getPhases(this.filters.season);
    },
  },
};
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  align-items: flex-end;
  gap: 20px;

  .select-wrapper {
    width: 300px;

    label {
      font-family: DrukWide;
      font-weight: 500;
      color: #000;
      padding: 7px 0;
      margin-bottom: 5px;
    }
  }

  .search-btn {
    button {
      background-color: #6441ff;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-size: 1.6rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1em;
      color: #fff;
      border-color: #6441ff;
      border-width: 2px;
      border-radius: 100px 100px 100px 100px;
      padding: 8px 20px;
      cursor: pointer;
      border-style: solid;
      transition: 0.25s;
      display: inline-block;
      margin: 0 10px;

      &:hover {
        background: #3264ff;
        border-color: #3264ff;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .flex-container {
    flex-direction: column;

    .select-wrapper {
      width: 100%;
    }
  }
}
</style>
