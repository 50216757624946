import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import { createI18n } from "vue-i18n";
import axiosInterceptorSetup from "./core/interceptors/axios-interceptor";
import languageHelper from "./core/helpers/server-config-helper";
import "./assets/styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "swiper/css/bundle";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faUserPlus,
  faAngleLeft,
  faAngleRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faLinkedin, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "vue3-carousel/dist/carousel.css";
import { VueReCaptcha } from "vue-recaptcha-v3";
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
import VueEasyLightbox from "vue-easy-lightbox/dist/external-css/vue-easy-lightbox.esm.min.js";
import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";
import VueSocialSharing from "vue-social-sharing";
import VueGtag from "vue-gtag";

axiosInterceptorSetup();

const i18n = new createI18n({
  legacy: false,
  locale: localStorage.getItem("lang") || process.env.VUE_APP_I18N_LOCALE,
  messages: languageHelper(),
});

library.add(
  faAngleDown,
  faUserPlus,
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faFacebook,
  faTwitter,
  faLinkedin,
  faWhatsapp,
  faAngleLeft,
  faAngleRight,
  faXmark
);
const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon);
app.component("vue-cookie-accept-decline", VueCookieAcceptDecline);

app.use(VueAxios, axios);
app.use(i18n);
app.use(store);
app.use(router);
app.use(VueSocialSharing);
app.use(VueEasyLightbox);
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });
app.use(VueGtag, { config: { id: "UA-204383718-1" } }, router);
router.isReady().then(() => app.mount("#app"));
