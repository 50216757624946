<template>
  <hero
    :title="$t('videos.title')"
    :title-color="'#fff'"
    :bg-image="'bnx_slider_3-min-scaled.jpg'"
    :subtitle="$t('news_and_video.subtitle')"
  >
    <div class="page-title-container">
      <h3 class="page-title">{{ $t("videos.page_title") }}</h3>
      <div class="page-title-container filter-container">
        <div class="filter-wrapper view-select">
          <input
            id="filter"
            class="form-control"
            type="text"
            v-model="keyword"
            :placeholder="$t('search_videos')"
            @keyup="onChangeSearchTerm($event)"
          />
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row flex-container">
        <slot v-for="video in videos">
          <div class="col-sm-6 col-xl-4">
            <NewsBox :article="video" />
          </div>
        </slot>

        <div v-if="canLoadMore" class="col-md-12 text-center my-md-5">
          <a href="javascript:void(0)" class="load-more-btn" @click="loadMorePosts()">{{ $t("loadMore") }}</a>
        </div>
      </div>
    </div>
  </hero>

  <OurPartners @partnersLoaded="loaders.partners = true" />
</template>
<script>
import OurPartners from "@/components/shared/our-partners";
import Hero from "@/components/shared/hero";
import NewsBox from "@/views/news-and-videos/news-box";
import newsAndVideosService from "@/services/NewsAndVideosService";
import misc from "@/core/helpers/misc";

export default {
  name: `Videos`,
  components: { OurPartners, Hero, NewsBox },
  data() {
    return {
      isNews: true,
      videos: [],
      itemsPerPage: 15,
      currentVideosPage: 1,
      videosLastPage: 1,
      keyword: "",
      searchTermTimer: null,
      loaders: {
        partners: false,
        videos: false,
      },
      misc: misc,
    };
  },

  computed: {
    canLoadMore() {
      return this.currentVideosPage < this.videosLastPage;
    },
  },

  mounted() {
    this.getVideos();
  },

  methods: {
    async getVideos() {
      const params = {
        per_page: this.itemsPerPage,
        page: this.currentVideosPage,
      };
      if (this.currentVideosPage === 1) this.videos = [];
      const res = await newsAndVideosService.getVideosForNewsPage(params);
      this.videosLastPage = res.meta.last_page;
      this.videos = [...this.videos, ...res.data];
      this.loaders.videos = true;
    },

    loadMorePosts() {
      this.currentVideosPage += 1;
      if (this.keyword.length >= 3) this.searchVideos();
      else this.getVideos();
    },

    onChangeSearchTerm(e) {
      if ((e.keyCode >= 37 && e.keyCode <= 40) || e.keyCode === 18) return;

      clearTimeout(this.searchTermTimer);

      if (this.keyword.length >= 3) {
        this.currentVideosPage = 1;
        this.videos = [];
        this.loaders.videos = false;
        this.searchTermTimer = setTimeout(() => {
          this.searchVideos();
          this.searchTermTimer = null;
        }, 300);
      } else if (!this.keyword.trim()) {
        this.loaders.videos = false;
        this.currentVideosPage = 1;
        this.getVideos();
      }
    },

    async searchVideos() {
      const params = {
        per_page: this.itemsPerPage,
        page: this.currentVideosPage,
        locale: this.$i18n.locale,
        keyword: this.keyword,
      };
      const res = await newsAndVideosService.searchVideos(params);
      this.videosLastPage = res.meta.last_page;
      this.videos = [...this.videos, ...res.data];
    },
  },
};
</script>

<style scoped lang="scss">
.page-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .view-select {
    width: 200px;
  }

  &.filter-container {
    gap: 10px;
  }
}

.page-title {
  color: #000;
  font-family: DrukWideBoldItalic, Sans-serif;
  font-size: 35px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  text-align: center;
}

.flex-container {
  row-gap: 40px;

  .load-more-btn {
    display: inline-block;
    margin: 10px 0 20px;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
    fill: #fff;
    color: #fff;
    background-color: #6441ff;
    border-radius: 100px 100px 100px 100px;
    padding: 10px 30px;
    text-decoration: none;
    transition-duration: 0.3s;
    transition-property: transform;

    &:hover {
      background-color: #eb3ce7;
      transform: scale(0.9);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .flex-container {
    .load-more-btn {
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .filter-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .page-title-container {
    flex-direction: column;
    // margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .page-title {
    font-size: 24px;
  }

  .flex-container {
    .load-more-btn {
      font-size: 20px;
    }
  }
}
</style>
