import Home from "@/views/home/Home";

export const homeRoutes = [
  {
    lang: "en",
    path: "/en/",
    name: "Home",
    component: Home,
  },
  {
    lang: "fr",
    path: "/fr/",
    name: "HomeFr",
    component: Home,
  },
  {
    lang: "nl",
    path: "/nl/",
    name: "HomeNl",
    component: Home,
  },
];
