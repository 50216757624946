<template>
  <transition name="modal-fade">
    <div class="follow-us-modal">
      <div class="modal-body">
        <div class="flex-container">
          <div class="social">
            <p>{{ $t("follow_us.stay_updated") }}</p>
            <a href="https://www.bnxt.tv/" target="_blank" class="platform">
              <p class="platform-name bnxt">BNXT.tv</p>
              <img src="../../../assets/img/social/color/bnxt-bnxt-icon-1024x1024.png" alt="BNXT.TV" />
            </a>
            <a href="https://www.facebook.com/BNXTLeague" target="_blank" class="platform">
              <p class="platform-name facebook">Facebook</p>
              <img src="../../../assets/img/social/color/facebook.svg" alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/BNXTLeague/" target="_blank" class="platform">
              <p class="platform-name instagram">Instagram</p>
              <img src="../../../assets/img/social/color/instagram.svg" alt="Instagram" />
            </a>
            <a href="https://www.youtube.com/channel/UC4QmIGx2Wa5_hiwlzK7HLTQ" target="_blank" class="platform">
              <p class="platform-name youtube">Youtube</p>
              <img src="../../../assets/img/social/color/youtube.svg" alt="YouTube" />
            </a>
            <a href="https://www.linkedin.com/company/bnxtleague" target="_blank" class="platform">
              <p class="platform-name linkedin">LinkedIn</p>
              <img src="../../../assets/img/social/color/linkedin.svg" alt="LinkedIn" />
            </a>
            <a href="https://twitter.com/BNXTLeague" target="_blank" class="platform">
              <p class="platform-name twitter">Twitter</p>
              <img src="../../../assets/img/social/color/twitter-2.svg" alt="Twitter" />
            </a>
            <a href="https://www.tiktok.com/@bnxtleague" target="_blank" class="platform">
              <p class="platform-name tiktok">TikTok</p>
              <img src="../../../assets/img/social/color/tik-tok.svg" alt="TikTok" />
            </a>
          </div>

          <div class="tv">
            <div class="title">{{ $t("follow_us.watch_the_games") }}</div>
            <img src="../../../assets/img/C-BNXTTV_logo_white.png" alt="BNXT" class="logo" />
            <img src="../../../assets/img/bnx_bnxt-tv-min.png" alt="BNXT.TV" class="resp" />
            <a href="https://www.bnxt.tv/" target="_blank">{{ $t("follow_us.bnxt_button") }}</a>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "mobile-menu-modal",
};
</script>

<style scoped lang="scss">
.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.3s ease;
}

.follow-us-modal {
  position: fixed;
  z-index: 20;
  overflow: auto;
  width: 91%;

  .modal-body {
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 12px;
    padding: 2% 0 3%;

    .flex-container {
      display: flex;

      .social {
        width: 60%;
        padding: 0 10% 0 5%;
        border-right: 1px solid #ffffff40;

        p {
          color: #fff;
          font-family: DrukWideBoldItalic, Sans-serif;
          font-size: 2.2rem;
          font-weight: 600;
          text-transform: uppercase;
          line-height: 1.3em;
        }

        .platform {
          display: inline-block;
          margin: 20px 40px;
          text-decoration: none;
          text-align: center;

          &-name {
            font-family: DrukWide, Sans-serif;
            font-size: 11px;
            font-weight: 500;
            line-height: 1.3em;
            text-transform: none;
            margin-bottom: 10px;

            &.bnxt {
              color: #eb3ce7;
            }

            &.facebook {
              color: #1b7ceb;
            }

            &.instagram {
              color: #eb3ce7;
            }

            &.youtube {
              color: #e12b27;
            }

            &.linkedin {
              color: #3264ff;
            }

            &.twitter {
              color: #29aee9;
            }

            &.tiktok {
              color: #ff0101;
            }
          }

          img {
            width: 100px;
          }
        }
      }

      .tv {
        width: 40%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .title {
          color: #fff;
          font-family: DrukWideBoldItalic, Sans-serif;
          font-size: 2.2rem;
          font-weight: 600;
          text-transform: uppercase;
          line-height: 0.9em;
        }

        .logo {
          width: 200px;
        }

        .resp {
          width: 300px;
          background: url("../../../assets/img/bnxt-blob-purple-1024x966.png") no-repeat;
          background-size: contain;
          margin: 10px 0;
        }

        a {
          font-family: DrukWideBoldItalic, Sans-serif;
          font-size: 30px;
          font-weight: 500;
          text-transform: uppercase;
          line-height: 1.3em;
          color: #fff;
          border-width: 0;
          border-radius: 100px 100px 100px 100px;
          padding: 15px 40px 12px;
          background-color: #ff0028;
          text-decoration: none;
          transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out;
          transition-property: color, background-color, border-color;
          transition-duration: 0.25s, 0.25s, 0.25s;
          transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
          transition-delay: 0s, 0s, 0s;

          &:hover {
            background-color: #003d80;
          }
        }
      }
    }
  }
}
</style>
