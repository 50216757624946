import axios from "axios";

export default {
  getStandingsByCompetitionId(competitionId, phaseId) {
    return axios
      .get(process.env.VUE_APP_API_URL + "standings/competition/" + competitionId + "/phase/" + phaseId)
      .then((response) => {
        return response.data.data;
      });
  },
};
