<template>
  <transition name="modal-fade">
    <div class="footer-modal">
      <div class="modal-header">
        <img src="../../../assets/img/A-BNXT_League_white.png" alt="BNXT" class="img-fluid logo" />
        <img src="../../../assets/img/icons/modal-close.svg" alt="Close" class="close-btn" @click="close" />
      </div>

      <div class="modal-body">
        <div class="navbar">
          <div class="lang-switcher">
            <LocaleSwitcher @lang-change="$emit('langChange')" />
          </div>

          <div class="menu-container">
            <slot v-for="item in menuItems">
              <slot v-if="!item.children.length">
                <a
                  :href="item.link"
                  class="nav-link"
                  target="_blank"
                  v-if="item.type === 'external_url'"
                  @click="close"
                >
                  <img :src="require('@/assets/img/bnxt-tv-btn.png')" v-if="item.name === 'BNXT.TV'" />
                  <span v-else>{{ item.name }}</span>
                </a>
                <router-link
                  :to="{ path: `/${locale}/${item.slug}` }"
                  class="nav-link"
                  v-else-if="item.type === 'internal_url'"
                  @click="close"
                >
                  {{ item.name }}
                </router-link>
              </slot>

              <slot v-else>
                <a class="nav-link" @click="areStatisticsSubmenusVisible = !areStatisticsSubmenusVisible">
                  {{ item.name }}<font-awesome-icon icon="fa-solid fa-angle-down" />
                </a>

                <div class="statistics-submenus" v-show="areStatisticsSubmenusVisible">
                  <slot v-for="child in item.children">
                    <router-link
                      :to="{
                        name: misc.getRouteNameByLocale($router.options.routes, child.link),
                        hash: misc.getMenuItemHash(child.name),
                      }"
                      class="nav-link"
                      @click="close"
                    >
                      {{ child.name }}
                    </router-link>
                  </slot>
                </div>
              </slot>
            </slot>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <p>{{ $t("follow_us.stay_updated") }}</p>
        <div class="social">
          <a href="https://www.bnxt.tv/" target="_blank">
            <img src="../../../assets/img/social/color/bnxt-bnxt-icon-1024x1024.png" alt="BNXT.TV" />
          </a>
          <a href="https://www.facebook.com/BNXTLeague" target="_blank">
            <img src="../../../assets/img/social/color/facebook.svg" alt="Facebook" />
          </a>
          <a href="https://www.instagram.com/BNXTLeague/" target="_blank">
            <img src="../../../assets/img/social/color/instagram.svg" alt="Instagram" />
          </a>
          <a href="https://www.youtube.com/channel/UC4QmIGx2Wa5_hiwlzK7HLTQ" target="_blank">
            <img src="../../../assets/img/social/color/youtube.svg" alt="YouTube" />
          </a>
          <a href="https://www.linkedin.com/company/bnxt-league/" target="_blank">
            <img src="../../../assets/img/social/color/linkedin.svg" alt="LinkedIn" />
          </a>
          <a href="https://twitter.com/BNXTLeague" target="_blank">
            <img src="../../../assets/img/social/color/twitter-2.svg" alt="Twitter" />
          </a>
          <a href="https://www.tiktok.com/@bnxtleague" target="_blank">
            <img src="../../../assets/img/social/color/tik-tok.svg" alt="TikTok" />
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import LocaleSwitcher from "@/components/shared/localeswitcher/LocaleSwitcher";
import misc from "@/core/helpers/misc";
export default {
  name: "mobile-menu-modal",
  components: { LocaleSwitcher },
  props: {
    menuItems: null,
  },
  data() {
    return {
      areStatisticsSubmenusVisible: false,
      misc: misc,
    };
  },

  computed: {
    locale() {
      return this.$i18n.locale || localStorage.getItem("lang") || "en";
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 1s ease;
}

.footer-modal {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9999;
  padding: 40px 40px;
  overflow: auto;

  .modal-header {
    .logo {
      max-width: 100px;
    }

    .close-btn {
      width: 60px;
    }
  }

  .modal-body {
    .navbar {
      position: static !important;
      box-shadow: none;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 !important;
      margin: 60px 0;
      width: 100%;

      .menu-container {
        width: 100%;
        margin-top: 40px;
      }

      .nav-link {
        font-family: DrukWide, Sans-serif;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 5px 40px 5px 0 !important;

        &:focus,
        &:hover {
          color: #e2ff00 !important;
          background: #55595c;
          padding: 15px 40px;
        }

        svg {
          margin-left: 10px;
        }
      }

      .lang-switcher {
        margin: 0 auto;

        :deep(.nav-link) {
          font-family: DrukWide, Sans-serif;
          font-size: 30px;
          padding: 0 !important;
        }
      }

      :deep(.dropdown-menu) {
        border: 1px solid #474747;
      }
    }
  }

  .modal-footer {
    justify-content: center;
    flex-direction: column;

    p {
      color: #fff;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-size: 3rem;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.3em;
      margin-bottom: 10px;
    }

    .social {
      text-align: center;

      img {
        width: 100px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .footer-modal {
    .modal-footer {
      p {
        font-size: 30px;
      }

      .social {
        img {
          margin: 0 20px;
          width: 60px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer-modal {
    .modal-body {
      .navbar {
        .menu-container {
          margin-top: 20px;
        }

        .nav-link {
          font-size: 16px;
          padding: 10px 0 !important;
        }

        .lang-switcher {
          margin: 0;

          :deep(.nav-link) {
            font-size: 18px;
          }
        }
      }
    }

    .modal-footer {
      p {
        font-size: 30px;
      }

      .social {
        img {
          margin: 10px;
          width: 50px;
        }
      }
    }
  }
}
</style>
