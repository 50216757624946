<template>
  <hero
    :title="$t('teams.title')"
    :title-color="'#fff'"
    :bg-image="'bnx_slider_4-min-scaled.jpg'"
    :subtitle="$t('teams.subtitle')"
  >
    <h3 class="page-title">{{ $t("teams.section.title") }}</h3>

    <TeamsList />
  </hero>

  <OurPartners @partnersLoaded="loaders.partners = true" />
</template>
<script>
import Hero from "@/components/shared/hero";
import TeamsList from "@/components/shared/teams-list";
import OurPartners from "@/components/shared/our-partners";
import misc from "@/core/helpers/misc";

export default {
  name: `TeamsView`,
  components: { Hero, TeamsList, OurPartners },
  data() {
    return {
      misc: misc,
      loaders: {
        partners: false,
      },
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style scoped lang="scss">
.page-title {
  color: #000;
  font-family: DrukWideBoldItalic, Sans-serif;
  font-size: 35px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em;
  text-align: center;
  margin-bottom: 30px;
}
</style>
