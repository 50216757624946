<template>
  <div class="video-modal">
    <div class="close" @click="close">
      <font-awesome-icon icon="fa-solid fa-xmark" />
    </div>

    <div class="video">
      <iframe
        width="75%"
        height="100%"
        :src="'https://www.youtube.com/embed/' + videoId"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "video-modal",
  props: {
    videoId: String,
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.video-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;

  .close {
    position: absolute;
    right: 20px;
    top: 15px;
    color: hsla(0, 0%, 93.3%, 0.9);
    font-size: 24px;
    cursor: pointer;
    line-height: 1;
  }

  .video {
    iframe {
      position: absolute;
      margin: 0 12.5%;
    }
  }
}
</style>
