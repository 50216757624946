<template>
  <div class="hero">
    <img src="../../assets/img/awards/bnxt_ball-min-1024x1024.png" class="left-ball" />

    <div class="content">
      <img :src="award?.logo?.thumbnails?.medium" class="img-fluid" />

      <div class="details">
        <div class="date">{{ moment(award?.award_date).format("D MMM YYYY") }}</div>
        <div class="place">{{ award?.place }}</div>
      </div>
    </div>

    <img src="../../assets/img/awards/bnxt_ball-min-1024x1024.png" class="right-ball" />

    <div class="buttons-wrapper">
      <a href="#categories" class="awards-categories-btn">Awards Categories</a>
      <a v-if="award?.ticket_url" :href="award?.ticket_url" class="get-tickets-btn">Vote here!</a>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "awards-hero",
  props: {
    award: null,
  },
  computed: {
    moment: () => moment,
  },
};
</script>

<style scoped lang="scss">
.hero {
  background: #0d0141;
  position: relative;
  overflow: hidden;

  .left-ball {
    position: absolute;
    width: 45vw;
    top: -5vw;
    left: -15vw;
  }

  .content {
    width: 500px;
    margin: 0 auto;
    padding-top: 180px;

    .details {
      position: relative;
      bottom: 110px;
      margin-bottom: -110px;
      color: #fff;
      font-family: DrukWideItalic, Sans-serif;
      font-size: 35px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1.3em;
      text-align: center;
      max-width: 250px;

      .date {
        border-bottom: 1px solid #fff;
      }

      .place {
        margin-top: 10px;
      }
    }
  }

  .right-ball {
    position: absolute;
    width: 25vw;
    bottom: -2vw;
    right: -10vw;
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 50px;

  .awards-categories-btn {
    font-family: DrukWideItalic, Sans-serif;
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
    fill: #e2ff00;
    color: #e2ff00;
    background-color: #e2ff0000;
    border-style: solid;
    border-width: 1px;
    border-color: #e2ff00;
    border-radius: 100px;
    padding: 20px 30px;
    text-decoration: none;
    line-height: 1;
  }

  .get-tickets-btn {
    font-family: DrukWideItalic, Sans-serif;
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
    fill: #003c80;
    color: #003c80;
    background-color: #e2ff00;
    border-style: solid;
    border-width: 1px;
    border-color: #e2ff00;
    border-radius: 100px;
    padding: 20px 30px;
    text-decoration: none;
    line-height: 1;
  }
}

@media only screen and (max-width: 1200px) {
  .hero {
    .content {
      text-align: center;

      img {
        width: 400px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .hero {
    .content {
      img {
        width: 280px;
      }

      .details {
        bottom: 70px;
        font-size: 24px;
      }
    }

    .buttons-wrapper {
      gap: 10px;
      margin-top: 100px;

      .awards-categories-btn,
      .get-tickets-btn {
        font-size: 24px;
        padding: 20px;
      }
    }
  }
}
</style>
