import axios from "axios";

export default {
  getTeamCompetitions(competitionId) {
    return axios
      .get(process.env.VUE_APP_API_URL + "competition-team/all", { params: { competition_id: competitionId } })
      .then((response) => {
        return {
          belgium: response.data.data
            .filter((competition) => competition?.club?.name === "Belgium")
            .sort((a, b) => (a.name > b.name ? 1 : -1)),
          netherlands: response.data.data
            .filter((competition) => competition?.club?.name === "Netherlands")
            .sort((a, b) => (a.name > b.name ? 1 : -1)),
        };
      });
  },
};
