import axios from "axios";

export default {
  getPlayerStatistics(competitionId, params) {
    return axios
      .get(process.env.VUE_APP_API_URL + "player/average-rankings/" + competitionId, {
        params,
      })
      .then((response) => {
        return response.data;
      });
  },

  getTeamStatistics(competitionId, params) {
    return axios
      .get(process.env.VUE_APP_API_URL + "competition-team/average-rankings/" + competitionId, {
        params,
      })
      .then((response) => {
        return response.data;
      });
  },
};
