import About from "@/views/about/about";

export const aboutRoutes = [
  {
    lang: "en",
    path: "/en/about",
    name: "About",
    component: About,
  },
  {
    lang: "fr",
    path: "/fr/a-propos-de",
    name: "À propos de",
    component: About,
  },
  {
    lang: "nl",
    path: "/nl/over",
    name: "Over",
    component: About,
  },
];
