module.exports = {
  getVideoId(videoUrl) {
    return videoUrl.split("=")[1];
  },

  getRouteNameByLocale(allRoutes, currentComponentName) {
    const currentComponent = allRoutes.find((route) => route.name === currentComponentName)?.component?.name;
    const currentLanguage = localStorage.getItem("lang");
    const correctRoute = allRoutes
      .filter((route) => route.component?.name === currentComponent)
      .find((route) => route.lang === currentLanguage);

    return correctRoute?.name;
  },

  getMenuItemHash(menuName) {
    let hash = null;

    switch (menuName) {
      case "Individual":
        hash = "#statistics-individual";
        break;
      case "Teams":
        hash = "#teams";
        break;
      case "Total Teams":
        hash = "#total-teams";
        break;
    }
    return hash;
  },

  isDataLoaded(loaders) {
    return Object.values(loaders).every((loader) => loader === true);
  },

  scrollTo(selector) {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.querySelector(selector).getBoundingClientRect().top - document.body.getBoundingClientRect().top - 80,
    });
  },

  formatNumber(num, hideDecimals) {
    if (hideDecimals) {
      return Math.floor(num) || 0;
    } else {
      return isNaN(num) ? 0 : num % 1 ? num.toFixed(1) : num;
    }
  },

  putInstagramScript(className) {
    const instagramScript = document.createElement("script");
    instagramScript.setAttribute("src", "//www.instagram.com/embed.js");
    const embeds = document.getElementsByClassName(className);

    Array.prototype.forEach.call(embeds, function (embed) {
      embed.append(instagramScript);
    });
  },

  hideRecaptchaBadge() {
    const badge = document.getElementsByClassName("grecaptcha-badge")[0];
    badge.classList.add("hidden");
  },

  sortMenuItemsByRecursion(menuList) {
    menuList.sort((a, b) => a.order - b.order);

    menuList.forEach((menuItem) => {
      if (menuItem.children.length) {
        this.sortMenuItemsByRecursion(menuItem.children);
      }
    });
    return menuList;
  },
};
