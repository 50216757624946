<template>
  <div class="table-wrapper">
    <table class="table table-bordered bnxt-table">
      <thead>
        <tr>
          <th style="text-align: center">#</th>
          <th style="text-align: center">Team</th>
          <th style="text-align: center">Games</th>
          <th style="text-align: center">WON</th>
          <th style="text-align: center">LOST</th>

          <th style="text-align: center" v-if="competitionId == 22">%</th>
          <th style="text-align: center" v-else>POINTS</th>

          <th style="text-align: center">+/-</th>
          <th style="text-align: center">DIFF</th>
          <th style="text-align: center">HOME</th>
          <th style="text-align: center">AWAY</th>
          <th style="text-align: center">LAST-5</th>
          <th style="text-align: center">STREAK</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in standings">
          <td>{{ row.position }}</td>
          <td class="team">
            <a href="javascript:void(0)">
              <div class="logo-wrapper">
                <img
                  v-if="row.team.team_logo"
                  :alt="row.team.team_logo.alt"
                  :src="row.team.team_logo.thumbnails.small"
                />
              </div>
              {{ row.team.name }}
            </a>
          </td>
          <td>{{ row.played }}</td>
          <td>{{ row.win }}</td>
          <td>{{ row.loss }}</td>
          <td>
            <b v-if="competitionId == 22">{{ row.percent }}</b>
            <b v-else>{{ row.point }}</b>
          </td>
          <td>{{ row.score }} - {{ row.against }}</td>
          <td>{{ row.score_difference }}</td>
          <td>{{ row.win_loss.home.win }} - {{ row.win_loss.home.loss }}</td>
          <td>{{ row.win_loss.away.win }} - {{ row.win_loss.away.loss }}</td>
          <td>{{ row.last5.win }} - {{ row.last5.loss }}</td>
          <td><span v-if="!row.streak.is_win">-</span>{{ row.streak.count }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import standingsService from "@/services/StandingsService";
import seasonService from "@/services/SeasonService";
import phaseService from "@/services/PhaseService";
import misc from "@/core/helpers/misc";

export default {
  name: "IframeTabella",
  data() {
    return {
      standings: null,
      misc: misc,
      competitionId: 3,
    };
  },

  mounted() {
    const phaseId = this.$route.query.phaseId;
    this.competitionId = this.$route.query.competitionId || 3;
    /*this.getSeasons().then((currentSeason) =>
      this.getPhases(currentSeason, false).then((competitionId) => this.getStandings(competitionId, phaseId))
    );*/
    this.getStandings(this.competitionId, phaseId);
  },

  methods: {
    async getSeasons() {
      const seasons = await seasonService.getSeasons();
      return seasons[0].season;
    },

    async getPhases(season, isStandings) {
      const data = await phaseService.getPhases(season, isStandings);
      return data[0].competition.id;
    },

    async getStandings(competitionId, phaseId) {
      this.standings = await standingsService.getStandingsByCompetitionId(competitionId, phaseId);
    },
  },
};
</script>

<style scoped lang="scss">
.table-wrapper {
  table thead tr th {
    &:first-child {
      min-width: 74px;
    }
    &:nth-child(2) {
      min-width: 270px;
    }
    &:nth-child(7),
    &:nth-child(11) {
      min-width: 80px;
    }
  }

  .team {
    img {
      margin-right: 10px;
    }
  }
}
</style>
