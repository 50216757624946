<template>
  <div class="hero">
    <div class="content-top">
      <img v-if="selectedTeam?.team_logo?.thumbnails?.small" :src="selectedTeam.team_logo.thumbnails.small" />
      <div class="team-name">
        <h4>{{ selectedTeam?.short_name }}</h4>
        <h2>{{ selectedTeam?.name }}</h2>
      </div>
    </div>
    <div class="content-bottom">
      <div class="filters">
        <div class="select-wrapper">
          <label>{{ $t("season") }}</label>
          <select v-model="filters.season" @change="onChangeSeason">
            <option v-for="season in seasons" :value="season.season">
              {{ season.season - 1 }} - {{ season.season }}
            </option>
          </select>
        </div>

        <div class="select-wrapper">
          <label>{{ $t("team") }}</label>
          <select v-model="filters.teamId" @change="onChangeTeam">
            <option v-for="team in teams" :value="team.id">{{ team.name }}</option>
          </select>
        </div>
      </div>

      <div class="stat-box">
        <div class="flex-container">
          <div class="label">FG%</div>
          <div class="value">{{ selectedTeam?.statistics?.field_goal_percent }}%</div>
        </div>
        <div class="flex-container">
          <div class="label">AVG Points made per game</div>
          <div class="value">{{ selectedTeam?.statistics?.average_score_for }}</div>
        </div>
        <div class="flex-container">
          <div class="label">AVG Points allowed per game</div>
          <div class="value">{{ selectedTeam?.statistics?.average_score_against }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <!-- Team Statistics -->
    <div class="mt-5">
      <div v-if="!playerAverages?.length">{{ $t("statistics.no-data") }}</div>
    </div>
    <div class="table-wrapper" v-if="playerAverages?.length">
      <h4>Team Statistics</h4>
      <table class="table table-bordered bnxt-table">
        <thead>
          <tr>
            <th colspan="4">{{ selectedTeam?.name }}</th>
            <th colspan="2">GAME</th>
            <th colspan="3">2P</th>
            <th colspan="3">3P</th>
            <th colspan="3">FG</th>
            <th colspan="3">FT</th>
            <th colspan="3">REBOUNDS</th>
            <th colspan="2">FOULS</th>
            <th colspan="3">&nbsp;</th>
            <th colspan="2">BLOCKS</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <th class="border-left-0 border-right-0">PHOTO</th>
            <th class="border-left-0 border-right-0">NAME</th>
            <th class="border-left-0 border-right-0">PTS</th>
            <th class="border-title border-left-0">MIN</th>
            <th class="border-right-0">G</th>
            <th class="border-title border-left-0">GS</th>
            <th class="border-right-0">2PM</th>
            <th class="border-left-0 border-right-0">2PA</th>
            <th class="border-title border-left-0">2P%</th>
            <th class="border-left-0 border-right-0">3PM</th>
            <th class="border-left-0 border-right-0">3PA</th>
            <th class="border-title border-left-0">3P%</th>
            <th class="border-left-0 border-right-0">FGM</th>
            <th class="border-left-0 border-right-0">FGA</th>
            <th class="border-title border-left-0">FG%</th>
            <th class="border-left-0 border-right-0">FTM</th>
            <th class="border-left-0 border-right-0">FTA</th>
            <th class="border-title border-left-0">FT%</th>
            <th class="border-left-0 border-right-0">DR</th>
            <th class="border-left-0 border-right-0">OR</th>
            <th class="border-title border-left-0">TOT</th>
            <th class="border-left-0 border-right-0">FP</th>
            <th class="border-title border-left-0">DF</th>
            <th class="border-left-0 border-right-0">AST</th>
            <th class="border-left-0 border-right-0">ST</th>
            <th class="border-title border-left-0">TO</th>
            <th class="border-left-0 border-right-0">BS</th>
            <th class="border-title border-left-0">BR</th>
            <th class="border-left-0 border-right-0">EFF</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="player in playerAverages">
            <td>
              <img
                :src="player.player.profile_image.thumbnails.small"
                class="img-fluid profile-image"
                v-if="player?.player?.profile_image?.thumbnails?.small"
              />
              <img src="../../assets/img/image_not_found.png" class="img-fluid profile-image" v-else />
            </td>
            <td>
              <router-link
                :to="{
                  name: misc.getRouteNameByLocale($router.options.routes, 'Player-profile'),
                  query: { player_id: player.roster_id, team_id: competitionTeamId },
                }"
                class="player"
              >
                {{ player.player.first_name }} {{ player.player.last_name.toUpperCase() }}
              </router-link>
            </td>
            <td>{{ misc.formatNumber(player.points) }}</td>
            <td>{{ misc.formatNumber(player.minute) }}</td>
            <td>{{ misc.formatNumber(player.played_games) }}</td>
            <td>{{ misc.formatNumber(player.starter) }}</td>

            <td>{{ misc.formatNumber(player.dunk_made + player.two_point_made) }}</td>
            <td>{{ misc.formatNumber(player.dunk_all + player.two_point_all) }}</td>
            <td>
              {{
                misc.formatNumber(
                  ((player.dunk_made + player.two_point_made) / (player.dunk_all + player.two_point_all)) * 100
                )
              }}
            </td>

            <td>{{ misc.formatNumber(player.three_point_made) }}</td>
            <td>{{ misc.formatNumber(player.three_point_all) }}</td>
            <td>{{ misc.formatNumber(player.three_point_percent) }}</td>

            <td>{{ misc.formatNumber(player.dunk_made + player.two_point_made + player.three_point_made) }}</td>
            <td>{{ misc.formatNumber(player.dunk_all + player.two_point_all + player.three_point_all) }}</td>
            <td>
              {{
                misc.formatNumber(
                  ((player.dunk_made + player.two_point_made + player.three_point_made) /
                    (player.dunk_all + player.two_point_all + player.three_point_all)) *
                    100,
                  true
                )
              }}
            </td>

            <td>{{ misc.formatNumber(player.free_throw_made) }}</td>
            <td>{{ misc.formatNumber(player.free_throw_all) }}</td>
            <td>{{ misc.formatNumber(player.free_throw_percent) }}</td>

            <td>{{ misc.formatNumber(player.defensive_rebound) }}</td>
            <td>{{ misc.formatNumber(player.offensive_rebound) }}</td>
            <td>{{ misc.formatNumber(player.defensive_rebound + player?.offensive_rebound) }}</td>
            <td>{{ misc.formatNumber(player.foul) }}</td>
            <td>{{ misc.formatNumber(player.defensive_foul) }}</td>
            <td>{{ misc.formatNumber(player.assist) }}</td>
            <td>{{ misc.formatNumber(player.steal) }}</td>
            <td>{{ misc.formatNumber(player.turnover) }}</td>
            <td>{{ misc.formatNumber(player.block) }}</td>
            <td>{{ misc.formatNumber(player.block_against) }}</td>
            <td>{{ misc.formatNumber(player.value) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- GAME STATISTICS -->
    <div class="table-wrapper" v-for="phase in matchStatistics">
      <h4>GAME STATISTICS - {{ phase[0]?.phase?.name }}</h4>
      <table class="table table-bordered bnxt-table">
        <thead>
          <tr>
            <th colspan="4"></th>
            <th colspan="3">2P</th>
            <th colspan="3">3P</th>
            <th colspan="3">FG</th>
            <th colspan="3">FT</th>
            <th colspan="3">REBOUNDS</th>
            <th colspan="2">FOULS</th>
            <th colspan="3">&nbsp;</th>
            <th colspan="2">BLOCKS</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <th class="border-right-0">GAME DATE</th>
            <th class="border-left-0 border-right-0">GAME</th>
            <th class="border-left-0 border-right-0">RESULT</th>
            <th class="border-title border-left-0">PTS</th>
            <th class="border-right-0">2PM</th>
            <th class="border-left-0 border-right-0">2PA</th>
            <th class="border-title border-left-0">2P%</th>
            <th class="border-left-0 border-right-0">3PM</th>
            <th class="border-left-0 border-right-0">3PA</th>
            <th class="border-title border-left-0">3P%</th>
            <th class="border-left-0 border-right-0">FGM</th>
            <th class="border-left-0 border-right-0">FGA</th>
            <th class="border-title border-left-0">FG%</th>
            <th class="border-left-0 border-right-0">FTM</th>
            <th class="border-left-0 border-right-0">FTA</th>
            <th class="border-title border-left-0">FT%</th>
            <th class="border-left-0 border-right-0">DR</th>
            <th class="border-left-0 border-right-0">OR</th>
            <th class="border-title border-left-0">TOT</th>
            <th class="border-left-0 border-right-0">FP</th>
            <th class="border-title border-left-0">DF</th>
            <th class="border-left-0 border-right-0">AST</th>
            <th class="border-left-0 border-right-0">ST</th>
            <th class="border-title border-left-0">TO</th>
            <th class="border-left-0 border-right-0">BS</th>
            <th class="border-title border-left-0">BR</th>
            <th class="border-left-0 border-right-0">EFF</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="stat in phase">
            <td>
              {{ moment(stat.game_time).format("D-MMM-YYYY") }}
              <br />
              {{ moment(stat.game_time).format("HH:mm") }}
            </td>
            <td v-if="selectedTeam?.name === stat.competitors[0]?.competition_team?.name">
              <span v-if="stat.competitors[0].side === 1">vs.</span>
              <span v-else>@</span>
              {{ stat.competitors[1].competition_team.name }}
            </td>
            <td v-else>
              <span v-if="stat.competitors[1].side === 1">vs.</span>
              <span v-else>@</span>
              {{ stat.competitors[0]?.competition_team?.name }}
            </td>

            <td>{{ stat?.competitors[0]?.finalScore }} - {{ stat.competitors[1]?.finalScore }}</td>
            <td>{{ misc.formatNumber(stat?.total?.points) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.dunk_made + stat?.total?.two_point_made) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.dunk_all + stat?.total?.two_point_all) }}</td>
            <td>
              {{
                misc.formatNumber(
                  ((stat?.total?.dunk_made + stat?.total?.two_point_made) /
                    (stat?.total?.dunk_all + stat?.total?.two_point_all)) *
                    100
                )
              }}
            </td>

            <td>{{ misc.formatNumber(stat?.total?.three_point_made) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.three_point_all) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.three_point_percent) }}</td>

            <td>
              {{
                misc.formatNumber(stat?.total?.dunk_made + stat?.total?.two_point_made + stat?.total?.three_point_made)
              }}
            </td>
            <td>
              {{ misc.formatNumber(stat?.total?.dunk_all + stat?.total?.two_point_all + stat?.total?.three_point_all) }}
            </td>
            <td>
              {{
                misc.formatNumber(
                  ((stat?.total?.dunk_made + stat?.total?.two_point_made + stat?.total?.three_point_made) /
                    (stat?.total?.dunk_all + stat?.total?.two_point_all + stat?.total?.three_point_all)) *
                    100,
                  true
                )
              }}
            </td>

            <td>{{ misc.formatNumber(stat?.total?.free_throw_made) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.free_throw_all) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.free_throw_percent) }}</td>

            <td>{{ misc.formatNumber(stat?.total?.defensive_rebound) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.offensive_rebound) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.defensive_rebound + stat?.total?.offensive_rebound) }}</td>

            <td>{{ misc.formatNumber(stat?.total?.foul) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.defensive_foul) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.assist) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.steal) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.turnover) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.block) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.block_against) }}</td>
            <td>{{ misc.formatNumber(stat?.total?.value) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import teamService from "@/services/TeamService";
import seasonService from "@/services/SeasonService";
import moment from "moment";
import misc from "@/core/helpers/misc";
import sportService from "@/services/SportService";
import phaseService from "@/services/PhaseService";

export default {
  name: "team-statistics",
  data() {
    return {
      selectedTeam: null,
      teams: [],
      seasons: [],
      playerAverages: [],
      matchStatistics: [],
      misc: misc,
      filters: {
        season: null,
        teamId: null,
      },
      loaders: {
        competitionTeam: false,
        seasons: false,
        playerAverages: false,
        matchStatistics: false,
        teams: false,
      },
      isSeasonChanged: false,
      competitionTeamId: null,
    };
  },

  mounted() {
    this.competitionTeamId = this.$route.query.team_id;
    this.getCompetitionTeam(this.competitionTeamId);
    this.getSeasons();
    this.getPlayerAverages(this.competitionTeamId);
    this.getStatisticsByGame(this.competitionTeamId);
  },

  methods: {
    async getCompetitionTeam(competitionTeamId) {
      this.selectedTeam = await teamService.getCompetitionTeam(competitionTeamId);
      this.filters.season = this.selectedTeam.competition.season;
      this.filters.teamId = this.selectedTeam.id;

      this.getTeamsByCompetitionId(this.selectedTeam.competition.id);
      this.loaders.competitionTeam = true;
    },

    async getSeasons() {
      this.seasons = await seasonService.getSeasons();
      this.loaders.seasons = true;
    },

    async getTeamsByCompetitionId(competitionId) {
      const teamCompetitions = await sportService.getTeamCompetitions(competitionId);
      this.teams = [...teamCompetitions.belgium, ...teamCompetitions.netherlands];

      if (this.isSeasonChanged) {
        this.filters.teamId = this.teams[0].id;
        this.onChangeTeam();
        this.isSeasonChanged = false;
      }

      this.loaders.teams = true;
    },

    async getPlayerAverages(competitionTeamId) {
      this.playerAverages = await teamService.getPlayerAverages(competitionTeamId);
      this.loaders.playerAverages = true;
    },

    async getStatisticsByGame(competitionTeamId) {
      const matchStatistics = await teamService.getStatisticsByGame(competitionTeamId);
      this.selectedTeamFgm = this.getTeamFg(matchStatistics, competitionTeamId, "home");
      this.selectedTeamFga = this.getTeamFg(matchStatistics, competitionTeamId, "away");

      this.matchStatistics = [];
      let phases = [];
      phases = [...new Set(matchStatistics.map((stat) => stat.phase.id))];
      phases.forEach((phase) => {
        this.matchStatistics["phase" + phase] = matchStatistics.filter((stat) => stat.phase.id === phase);
      });
      this.matchStatistics = Object.values(this.matchStatistics);
      this.selectedTeamFgPercent = this.getTeamFgPercent(this.matchStatistics[0]);
      this.loaders.matchStatistics = true;
    },

    async getPhases(season, isStandings) {
      this.phasesComp = await phaseService.getPhases(season, isStandings);
      this.getTeamsByCompetitionId(this.phasesComp[0].competition.id);
    },

    onChangeSeason() {
      this.isSeasonChanged = true;
      this.loaders.teams = false;
      this.getPhases(this.filters.season);
    },

    onChangeTeam() {
      this.changeTeamIdInUrl();
      this.loaders.teams = false;
      this.loaders.playerAverages = false;
      this.loaders.matchStatistics = false;
      this.getCompetitionTeam(this.filters.teamId);
      this.getPlayerAverages(this.filters.teamId);
      this.getStatisticsByGame(this.filters.teamId);
    },

    changeTeamIdInUrl() {
      this.$router.push({
        query: { team_id: this.filters.teamId },
      });
    },

    getTeamFgPercent(matchStatistics) {
      const calculatedAverages = [];

      matchStatistics.forEach((avg) => {
        calculatedAverages.push(
          ((avg.total.two_point_made + avg.total.three_point_made) /
            (avg.total.two_point_all + avg.total.three_point_all)) *
            100 || 0
        );
      });
      return calculatedAverages.length ? calculatedAverages.reduce((a, b) => a + b) / calculatedAverages.length : 0;
    },

    getTeamFg(statistics, competitionTeamId, team) {
      const teamPoints =
        team === "home"
          ? statistics.map(
              (stat) =>
                stat.competitors.find((competitor) => competitor.competition_team.id === Number(competitionTeamId))
                  .finalScore
            )
          : statistics.map(
              (stat) =>
                stat.competitors.find((competitor) => competitor.competition_team.id !== Number(competitionTeamId))
                  .finalScore
            );
      return teamPoints.length ? teamPoints.reduce((a, b) => a + b) / teamPoints.length : 0;
    },
  },

  computed: {
    moment: () => moment,
  },
};
</script>

<style scoped lang="scss">
.hero {
  min-height: 550px;
  background: url("../../assets/img/hero/bnx_slider_3-min-scaled.jpg") left bottom;
  padding: 8% 8% 0 8%;

  .content-top {
    display: flex;
    padding-top: 50px;
    gap: 40px;
    align-items: center;
    border-bottom: 1px solid hsl(0deg 0% 100%/60%);
    padding-bottom: 25px;

    img {
      max-width: 130px;
    }

    .team-name {
      h4 {
        margin: 0;
        font-size: 35px;
        text-transform: uppercase;
        line-height: 40px;
        font-weight: 700;
        color: #fff;
      }

      h2 {
        padding: 0;
        margin-bottom: 0;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-weight: 600;
        font-size: 60px;
        text-transform: uppercase;
        color: #fff;
        margin-top: 5px;
        line-height: 67px;
      }
    }
  }

  .content-bottom {
    display: flex;
    gap: 20px;
    padding: 30px 0 30px 10px;
    justify-content: space-between;

    .filters {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;

      .select-wrapper {
        width: 300px;

        label {
          color: #fff;
          margin-bottom: 5px;
          padding-top: 0;
          font-family: DrukWide;
          font-weight: 500;
        }
      }
    }

    .stat-box {
      background-color: rgb(0 0 0/30%);
      border-radius: 15px;
      padding: 15px 60px 15px 20px;
      color: #fff;
      max-width: 440px;
      width: 100%;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

      .flex-container {
        display: flex;
        justify-content: space-between;

        .value {
          width: 32%;
          text-align: center;
        }
      }
    }
  }
}

.content {
  padding: 0 8% 4%;

  .table-wrapper {
    padding: 90px 0 40px;

    h4 {
      border: 0;
      color: #000;
      padding: 0;
      margin: 0 0 25px;
      text-transform: uppercase;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-weight: 600;
      font-size: 28px;
    }

    table {
      text-align: center !important;

      thead {
        tr {
          border: 1px solid;

          &:nth-child(2) {
            th {
              border-radius: 0 !important;
            }
          }
        }
      }

      tbody {
        .profile-image {
          max-width: 35px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .hero {
    padding: 8% 20px 0 20px;
  }
}

@media only screen and (max-width: 980px) {
  .hero {
    .content-top {
      padding-top: 120px;
      gap: 20px;

      img {
        max-width: 90px;
      }

      .team-name {
        h4 {
          font-size: 27px;
          line-height: 32px;
        }

        h2 {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }

    .content-bottom {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .hero {
    .content-bottom {
      .filters {
        justify-content: center;
      }
    }
  }
}
</style>
