import TeamsView from "@/views/teams/TeamsView";
import TeamView from "@/views/teams/team/team-view";
import TeamStatistics from "@/views/teams/team-statistics";

export const teamsRoutes = [
  {
    lang: "en",
    path: "/en/teams",
    name: "Teams",
    component: TeamsView,
  },
  {
    lang: "fr",
    path: "/fr/teams",
    name: "Équipes",
    component: TeamsView,
  },
  {
    lang: "nl",
    path: "/nl/teams",
    name: "Teams nl",
    component: TeamsView,
  },
  {
    lang: "en",
    path: "/en/team-statistics/",
    name: "Team Statistics",
    component: TeamStatistics,
  },
  {
    lang: "fr",
    path: "/fr/team-statistics/",
    name: "Team Statistics FR",
    component: TeamStatistics,
  },
  {
    lang: "nl",
    path: "/nl/team-statistics/",
    name: "Team Statistics NL",
    component: TeamStatistics,
  },
  {
    lang: "en",
    path: "/en/team",
    name: "Team",
    component: TeamView,
  },
  {
    lang: "fr",
    path: "/fr/team",
    name: "Équipe",
    component: TeamView,
  },
  {
    lang: "nl",
    path: "/nl/team",
    name: "Team NL",
    component: TeamView,
  },
];
