import Videos from "@/views/news-and-videos/Videos";
import News from "@/views/news-and-videos/News";
import SinglePost from "@/views/news-and-videos/single-post";

export const newsAndVideosRoutes = [
  {
    lang: "en",
    path: "/en/videos",
    name: "Videos EN",
    component: Videos,
  },
  {
    lang: "fr",
    path: "/fr/videos",
    name: "Videos FR",
    component: Videos,
  },
  {
    lang: "nl",
    path: "/nl/videos",
    name: "Videos NL",
    component: Videos,
  },
  {
    lang: "en",
    path: "/en/news",
    name: "News EN",
    component: News,
  },
  {
    lang: "fr",
    path: "/fr/news",
    name: "News FR",
    component: News,
  },
  {
    lang: "nl",
    path: "/nl/news",
    name: "Nieuws",
    component: News,
  },

  {
    lang: "en",
    path: "/en/newsvideo/:slug",
    name: "SinglePost",
    component: SinglePost,
  },
  {
    lang: "fr",
    path: "/fr/newsvideo/:slug",
    name: "SinglePost FR",
    component: SinglePost,
  },
  {
    lang: "nl",
    path: "/nl/nieuwsvideo/:slug",
    name: "SinglePost NL",
    component: SinglePost,
  },
];
