<template>
  <div class="player" :class="background">
    <div class="img-holder">
      <img
        v-if="player?.player?.profile_image?.thumbnails?.medium || player?.staff?.profile_image?.thumbnails?.medium"
        :src="player?.player?.profile_image?.thumbnails?.medium || player?.staff?.profile_image?.thumbnails?.medium"
      />
      <img v-else src="../../../assets/img/image_not_found.png" class="not-found" />
    </div>
    <div class="player-details">
      <div class="number">{{ player?.jersey }}</div>
      <div class="last-name">{{ player.player?.last_name?.toUpperCase() || player?.staff?.name }}</div>
      <div class="first-name">{{ player.player?.first_name }}</div>
      <div class="position" v-if="player?.player_position?.name">
        {{ $t("player_positions." + player.player_position.name) }}
      </div>
      <div class="position" v-else-if="isStaff && player?.title?.name">{{ player.title.name }}</div>
    </div>
    <div class="hidden-details" v-show="!background">
      <div class="detail">
        <div class="label">{{ $t("player.height") }}</div>
        <div class="data">{{ player?.height }}</div>
      </div>
      <div class="detail">
        <div class="label">{{ $t("player.born") }}</div>
        <div class="data">{{ player.player?.birthdate }}</div>
      </div>
      <div class="detail">
        <div class="label">{{ $t("player.country") }}</div>
        <div class="data">{{ player.player?.nationality_code }}</div>
      </div>
      <router-link
        :to="{
          name: misc.getRouteNameByLocale($router.options.routes, 'Player-profile'),
          query: { player_id: player?.id, team_id: player?.competition_team_id },
        }"
        class="profile-btn"
      >
        {{ $t("player.view_profile") }}
      </router-link>
    </div>
  </div>
</template>
<script>
import misc from "@/core/helpers/misc";
export default {
  props: {
    player: {},
    background: String,
  },
  data() {
    return {
      misc: misc,
    };
  },
};
</script>

<style scoped lang="scss">
.player {
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    position: absolute;
    left: 18px;
    top: 18px;
    width: 100%;
    height: 100%;
    background-color: #6441ff;
    z-index: -1;
  }

  &.orange:after {
    background-color: #f75f00;
  }

  &:hover .hidden-details {
    opacity: 1;
    height: 100%;
  }

  .img-holder {
    background-color: #000;
    float: left;
    width: 100%;
    height: 24vw;
    padding: 0 20px;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      position: absolute;
      left: 0;
      top: 30px;

      &.not-found {
        height: 90%;
        object-fit: cover;
        bottom: 0;
        top: auto;
      }
    }
  }

  &-details {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));

    .number,
    .first-name,
    .last-name {
      text-transform: uppercase;
      color: #fff;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-weight: 600;
      font-size: 3vw;
      line-height: 0.85em;
    }

    .position {
      margin-bottom: 0;
      margin-top: 8px;
      font-family: DrukWide;
      font-weight: 500;
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .hidden-details {
    position: absolute;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 20px;
    background-color: rgb(83, 0, 81, 0.9);
    overflow: hidden;
    transition: 0.4s ease;
    opacity: 0;

    .detail {
      border-bottom: 1px solid #bababa;
      padding: 4px 0;

      .label {
        display: inline-block;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        font-family: DrukWide;
        font-weight: 500;
        color: #fff;
        text-transform: uppercase;
      }

      .data {
        display: inline-block;
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
      }
    }

    .profile-btn {
      font-family: DrukWideItalic, Sans-serif;
      color: #fff;
      border: solid 2px #fff;
      border-radius: 100px;
      padding: 6px 20px;
      background-color: transparent;
      font-size: 24px;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      margin-top: 15px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .player {
    .img-holder {
      min-height: 36vw;
    }
  }
}

@media only screen and (max-width: 768px) {
  .player {
    .img-holder {
      min-height: 50vw;
    }

    &-details {
      .number,
      .first-name,
      .last-name {
        font-size: 7vw;
        line-height: 0.85em;
      }
    }

    .hidden-details {
      .label,
      .data {
        font-size: 12px !important;
      }

      a {
        font-size: 20px !important;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .player {
    .img-holder {
      min-height: 100vw;
    }

    .hidden-details {
      .detail {
        padding: 12px 0;

        .label {
          font-size: 20px !important;
          padding-bottom: 10px;
        }

        .data {
          font-size: 20px !important;
        }
      }

      a {
        font-size: 32px !important;
        margin-top: 25px !important;
      }
    }
  }
}
</style>
