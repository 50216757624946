<template>
  <div class="mvp">
    <img src="../../assets/img/awards/bnx_comet-big-blue-min-979x1024.png" class="background-img-left" />
    <img src="../../assets/img/awards/bnx_comet-small-purple-min-979x1024.png" class="background-img-right" />

    <div class="header">
      <h2 class="title">{{ awardCategory?.title }}</h2>
      <p class="description">{{ awardCategory?.description }}</p>
    </div>

    <div class="content">
      <slot v-for="player in awardCategory?.rosters">
        <AwardsPlayerCard
          :player="player"
          :is-active="player?.is_winner === 1"
          background-color="#000"
          background-image="bnx_bg-mvp-bubble-min.png"
          :box-shadow="'green'"
          category-image="BNXT_Awards-winner-mvp-min.png"
        />
      </slot>
      <slot v-for="player in getPlaceholderCount(awardCategory)">
        <AwardsPlayerCard
          :player="null"
          :is-active="false"
          background-color="#000"
          background-image="bnx_bg-mvp-bubble-min.png"
          :box-shadow="'green'"
          category-image="BNXT_Awards-winner-mvp-min.png"
        />
      </slot>
    </div>
  </div>

  <div class="section-filler"></div>
</template>

<script>
import AwardsPlayerCard from "@/views/awards/awards-player-card";
export default {
  name: "awards-mvp",
  components: { AwardsPlayerCard },
  props: {
    awardCategory: null,
  },
  methods: {
    getPlaceholderCount(awardCategory) {
      let length = 0;
      if (awardCategory) {
        length = awardCategory.rosters.length;
        return awardCategory.placeholder_count - length > 0 ? awardCategory.placeholder_count - length : 0;
      }
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
.mvp {
  position: relative;
  padding: 6% 8% 0;
  background: #0d0141;

  .background-img-left {
    position: absolute;
    top: -100%;
    left: 150px;
    width: 800px;
  }

  .background-img-right {
    position: absolute;
    top: 10%;
    right: 15%;
    width: 500px;
  }

  .header {
    max-width: 800px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    z-index: 1;

    .title {
      color: #fff;
      font-family: DrukWide, Sans-serif;
      font-size: 4vw;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.85em;
      margin-bottom: 20px;
    }

    .description {
      text-align: center;
      color: #fff;
      font-size: 18px;
      line-height: 1.4em;
      margin-bottom: 40px;
    }
  }

  .content {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 70px;
  }
}

.section-filler {
  height: 320px;
  background-image: linear-gradient(180deg, #0c0041 0%, #6441ff 100%);
}

@media only screen and (max-width: 1200px) {
  .mvp {
    padding: 4%;

    .background-img-left {
      width: 400px;
      top: -300px;
      left: 50px;
    }

    .header {
      .title {
        font-size: 6vw;
      }

      .description {
        font-size: 16px;
      }
    }
  }

  .section-filler {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .mvp {
    .background-img-left {
      top: -200px;
      max-width: calc(90vw - 50px);
    }

    .background-img-right {
      top: 0;
      max-width: calc(90vw - 50px);
    }

    .header {
      .title {
        font-size: 24px;
      }

      .description {
        font-size: 14px;
      }
    }

    .content {
      display: grid;
      grid-template-columns: auto;
      gap: 70px;
    }
  }

  .section-filler {
    height: 170px;
  }
}
</style>
