<template>
  <div v-if="overviewData?.game_leaders[0]?.leaders?.assist">
    <div class="key-statistics-wrapper" v-if="teamTotals?.home">
      <GameReportOverviewKeyStatistics :key-statistics="teamTotals" />
    </div>

    <div class="flex-container">
      <div class="top-performers-wrapper" v-if="topPerformers?.home">
        <GameReportOverviewTopPerformers :top-performers="topPerformers" />
      </div>
      <div class="game-leaders-wrapper" v-if="gameLeaders?.home">
        <GameReportOverviewGameLeaders :game-leaders="gameLeaders" />
      </div>
    </div>
  </div>

  <div v-else>{{ $t("game_report.no_statistics") }}</div>
</template>

<script>
import GameReportOverviewKeyStatistics from "@/views/game-report/game-report-overview/game-report-overview-key-statistics";
import GameReportOverviewTopPerformers from "@/views/game-report/game-report-overview/game-report-overview-top-performers";
import GameReportOverviewGameLeaders from "@/views/game-report/game-report-overview/game-report-overview-game-leaders";
export default {
  name: "game-report-overview",
  components: {
    GameReportOverviewGameLeaders,
    GameReportOverviewTopPerformers,
    GameReportOverviewKeyStatistics,
  },
  props: {
    overviewData: null,
  },
  data() {
    return {
      teamTotals: { home: null, away: null },
      topPerformers: { home: null, away: null },
      gameLeaders: { home: null, away: null },
    };
  },

  watch: {
    overviewData(data) {
      this.teamTotals.home = data.team_totals.find((team) => team.is_home);
      this.teamTotals.away = data.team_totals.find((team) => !team.is_home);
      this.topPerformers.home = data.top_performer.find((performer) => performer.is_home);
      this.topPerformers.away = data.top_performer.find((performer) => !performer.is_home);
      this.gameLeaders.home = data.game_leaders.find((leader) => leader.is_home);
      this.gameLeaders.away = data.game_leaders.find((leader) => !leader.is_home);
    },
  },
};
</script>

<style scoped lang="scss">
.key-statistics-wrapper {
  width: 80%;
  margin-left: 10%;
  margin-bottom: 30px;
}

.flex-container {
  display: flex;
  gap: 20px;

  .top-performers-wrapper,
  .game-leaders-wrapper {
    width: 50%;
  }
}

@media only screen and (max-width: 1200px) {
  .key-statistics-wrapper {
    width: 100%;
    margin-left: 0;
  }

  .flex-container {
    gap: 30px;
    flex-direction: column;

    .top-performers-wrapper,
    .game-leaders-wrapper {
      width: 100%;
    }
  }
}
</style>
