import Statistics from "@/views/statistics/statistics";

export const statisticsRoutes = [
  {
    lang: "en",
    path: "/en/statistics/",
    name: "Statistics",
    component: Statistics,
  },
  {
    lang: "fr",
    path: "/fr/statistiques/",
    name: "Statistiques",
    component: Statistics,
  },
  {
    lang: "nl",
    path: "/nl/statistieken/",
    name: "Statistieken",
    component: Statistics,
  },
];
