import axios from "axios";

export default {
  getPhases(season, isStandings) {
    return axios.get(process.env.VUE_APP_API_URL + "phase/season/" + season).then((response) => {
      let data = response.data.data;

      if (isStandings) {
        data[0].phases = data[0].phases.filter((res) => res.standings_visible);
      }

      return data;
    });
  },
};
