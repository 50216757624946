import axios from "axios";

export default {
  getSurveyBySlug(slug) {
    return axios.get(process.env.VUE_APP_API_URL + "survey/find/" + slug).then((response) => {
      return response.data.data;
    });
  },

  sendSurveyData(surveyData) {
    return axios.post(process.env.VUE_APP_API_URL + "survey/fill", surveyData).then(
      (response) => {
        return response.data.data;
      },
      (err) => {
        return err.response.data.errors;
      }
    );
  },
};
