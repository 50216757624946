<template>
  <!-- TODO: set social properties if there is DATA from parent component -->
  <div class="survey-social">
    <div class="content">
      <h2 class="title">SHARE WITH YOUR FRIENDS AND FAMILY</h2>
      <div class="social">
        <ShareNetwork
          network="twitter"
          :url="'https://bnxtleague.com' + $route.fullPath"
          :title="article?.title"
          :description="article?.lead"
        >
          <font-awesome-icon icon="fa-brands fa-twitter" />
        </ShareNetwork>
        <ShareNetwork
          network="facebook"
          :url="'https://bnxtleague.com' + $route.fullPath"
          :title="article?.title"
          :description="article?.lead"
        >
          <font-awesome-icon icon="fa-brands fa-facebook" />
        </ShareNetwork>
        <ShareNetwork
          network="whatsapp"
          :url="'https://bnxtleague.com' + $route.fullPath"
          :title="article?.title"
          :description="article?.lead"
        >
          <font-awesome-icon icon="fa-brands fa-whatsapp" />
        </ShareNetwork>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "survey-social",
};
</script>

<style scoped lang="scss">
.survey-social {
  background: #000;
  padding: 120px 0;

  .content {
    max-width: 500px;
    margin: 0 auto;
    text-align: center;

    .title {
      color: #fff;
      font-family: DrukWideItalic, Sans-serif;
      font-size: 80px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.85em;
    }

    .social {
      display: flex;
      justify-content: space-evenly;
      gap: 20px;
      margin-top: 40px;

      svg {
        color: #f75f00;
        font-size: 80px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .survey-social {
    .content {
      .title {
        font-size: 60px;
      }

      .social {
        svg {
          font-size: 60px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .survey-social {
    .content {
      .social {
        svg {
          font-size: 30px;
        }
      }
    }
  }
}
</style>
