import PlayerList from "@/views/players/player-list";
import PlayerProfile from "@/views/players/player-profile";

export const playersRoutes = [
  {
    lang: "en",
    path: "/en/players",
    name: "Players",
    component: PlayerList,
  },
  {
    lang: "fr",
    path: "/fr/players",
    name: "Joueurs",
    component: PlayerList,
  },
  {
    lang: "nl",
    path: "/nl/players",
    name: "Spelers",
    component: PlayerList,
  },
  {
    lang: "en",
    path: "/en/player-statistics/",
    name: "Player-profile",
    component: PlayerProfile,
  },
  {
    lang: "fr",
    path: "/fr/player-statistics/",
    name: "Player-profile FR",
    component: PlayerProfile,
  },
  {
    lang: "nl",
    path: "/nl/player-statistics/",
    name: "Player-profile NL",
    component: PlayerProfile,
  },
];
