import { createRouter, createWebHistory } from "vue-router";
import PageNotFound from "@/views/page-not-found/page-not-found";
import { homeRoutes } from "@/router/home-routes";
import { newsAndVideosRoutes } from "@/router/news-and-videos-routes";
import { calendarRoutes } from "@/router/calendar-routes";
import { statisticsRoutes } from "@/router/statistics-routes";
import { teamsRoutes } from "@/router/teams-routes";
import { standingsRoutes } from "@/router/standings-routes";
import { playersRoutes } from "@/router/players-routes";
import { eventsRoutes } from "@/router/events-routes";
import { aboutRoutes } from "@/router/about-routes";
import { staticPageRoutes } from "@/router/static-page-routes";
import { awardsRoutes } from "@/router/awards-routes";
import { surveyRoutes } from "@/router/survey-routes";
import store from "@/store";
import { iFrameRoutes } from "@/router/iframe-routes";
import { refereesRoutes } from "@/router/referees-routes";

const routes = [
  {
    path: "/",
    name: "MainRoot",
    redirect: localStorage.getItem("lang") || process.env.VUE_APP_I18N_LOCALE,
  },
];

const allRoutes = homeRoutes.concat(
  newsAndVideosRoutes,
  calendarRoutes,
  statisticsRoutes,
  standingsRoutes,
  teamsRoutes,
  playersRoutes,
  eventsRoutes,
  aboutRoutes,
  awardsRoutes,
  surveyRoutes,
  iFrameRoutes,
  refereesRoutes,
  staticPageRoutes
);
allRoutes.forEach((route) => {
  routes.push(route);
});

routes.push({ path: "/page-not-found", name: "PageNotFound", component: PageNotFound });
routes.push({ path: "/:locale/:pathMatch(.*)*", redirect: { name: "PageNotFound" } });

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (from.path.includes("statistics") && to.path.includes("statistics")) {
      return;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // Save current language to localstorage
  const urlLang = to.path.split("/")[1];

  if (process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",").includes(urlLang)) {
    localStorage.setItem("lang", urlLang);
  } else {
    //localStorage.setItem("lang", process.env.VUE_APP_I18N_LOCALE);
  }

  // If translations are ready, route is loading
  if (store.state.translations.en) {
    next();
  } else {
    let interval = setInterval(() => {
      if (store.state.translations.en) {
        clearInterval(interval);
        next();
      }
    }, 1);
  }

  /*console.log(to, from);
  const locale = to.params.locale;
  const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
  if (!supported_locales.includes(locale)) return next("en");
  if (i18n.locale !== locale) {
    i18n.locale = locale;
  }
  return next();*/
});

export default router;
