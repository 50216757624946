<template>
  <div class="carousel-wrapper">
    <div class="text-wrapper">
      {{ award?.description }}
    </div>

    <swiper
      :modules="modules"
      :slides-per-view="slidesPerView"
      :space-between="50"
      :loop="true"
      :autoplay="{
        delay: 1,
        disableOnInteraction: true,
      }"
      :free-mode="{
        momentum: false,
        sticky: true,
      }"
      :speed="7500"
    >
      <swiper-slide v-for="(item, index) in images" :key="item" @click="() => show(index)">
        <img :src="item.store_path" />
      </swiper-slide>
    </swiper>
  </div>

  <vue-easy-lightbox :visible="visibleRef" :imgs="imgs" :index="indexRef" :move-disabled="true" @hide="onHide" />
</template>

<script>
import { Autoplay, FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { useEasyLightbox } from "vue-easy-lightbox";

export default {
  name: "awards-carousel",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    award: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      slidesPerView: 2,
      imgs: [],
    };
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  watch: {
    images: {
      handler() {
        this.imgs = this.images.map((image, index) => {
          return {
            src: image.store_path,
            index,
          };
        });
      },
      immediate: true,
    },
  },

  methods: {
    onResize() {
      if (window.innerWidth > 1200) {
        this.slidesPerView = 2;
      } else if (window.innerWidth <= 767) {
        this.slidesPerView = 1;
      }
    },
  },

  setup() {
    const { show, onHide, visibleRef, indexRef, imgsRef } = useEasyLightbox({
      initIndex: 0,
    });

    return {
      modules: [Autoplay, FreeMode],
      visibleRef,
      indexRef,
      imgsRef,
      show,
      onHide,
    };
  },
};
</script>

<style scoped lang="scss">
.carousel-wrapper {
  background: #0d0141;
  padding: 10% 0;

  .text-wrapper {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4em;
  }

  :deep(.swiper-free-mode > .swiper-wrapper) {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }

  .swiper {
    margin-top: 70px;
    margin-bottom: 10px;

    .swiper-slide {
      img {
        width: 100%;
        height: 500px;
        aspect-ratio: 3/2;
        object-fit: cover;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .carousel-wrapper {
    .text-wrapper {
      max-width: 500px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .carousel-wrapper {
    .text-wrapper {
      font-size: 16px;
    }

    .swiper {
      .swiper-slide {
        img {
          height: 240px;
        }
      }
    }
  }
}
</style>
