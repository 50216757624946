<template>
  <AppLoader v-if="!misc.isDataLoaded(loaders)" />

  <Hero
    bg-image="bnx_slider_1-min-scaled.jpg"
    :title="$t('calendar.header.title')"
    :subtitle="$t('calendar.header.subtitle')"
    title-color="#fff"
    :height="'550px'"
  />

  <div class="content">
    <h2 class="title">{{ $t("calendar.pageTitle") }}</h2>

    <div class="mt-4">
      <CalendarFilter
        :current-month-number="currentMonthNumber"
        @phaseId="hasPhaseId($event)"
        @filters-changed="filtersChanged($event)"
      />
    </div>

    <div class="mt-5">
      <slot v-if="!isMobileMode"><CalendarTable :schedules="schedules" /></slot>
      <slot v-else><CalendarTableMobile :schedules="schedules" /></slot>
      <div v-if="!schedules.length">{{ $t("no_games") }}</div>
    </div>
  </div>
  <OurPartners @partnersLoaded="loaders.partners = true" />
</template>

<script>
import Hero from "@/components/shared/hero";
import CalendarFilter from "@/views/calendar/calendar-filter";
import CalendarTable from "@/views/calendar/calendar-table";
import OurPartners from "@/components/shared/our-partners";
import scheduleService from "@/services/ScheduleService";
import AppLoader from "@/components/shared/loader/AppLoader";
import CalendarTableMobile from "@/views/calendar/calendar-table-mobile";
import misc from "@/core/helpers/misc";
import moment from "moment";
export default {
  name: `CalendarView`,
  components: { AppLoader, Hero, CalendarFilter, CalendarTable, OurPartners, CalendarTableMobile },
  data() {
    return {
      schedules: [],
      isMobileMode: window.innerWidth <= 1000,
      loaders: {
        schedules: false,
      },
      misc: misc,
      currentMonthNumber: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.currentMonthNumber = moment().format("M");
  },

  methods: {
    onResize() {
      this.isMobileMode = window.innerWidth <= 1000;
    },

    getRecentSchedules(phaseId) {
      this.getSchedules(process.env.VUE_APP_ACTIVE_SEASON, phaseId, null, null, this.currentMonthNumber).then(() => {
        if (!this.schedules.length) {
          this.loaders.schedules = false;
          this.currentMonthNumber = -1;
          this.getSchedules(process.env.VUE_APP_ACTIVE_SEASON, phaseId, 3);
        }
      });
    },

    async getSchedules(season, phaseId, monthCount, teamId, month) {
      this.schedules = await scheduleService.getSchedules(season, phaseId, monthCount, teamId, month);
      this.schedules.sort((a, b) => new Date(a.game_time).getTime() - new Date(b.game_time).getTime());
      this.loaders.schedules = true;
    },

    hasPhaseId(phaseId) {
      this.getRecentSchedules(phaseId);
    },

    filtersChanged(filters) {
      this.loaders.schedules = false;
      this.getSchedules(filters.season, filters.phase, null, filters.team, filters.month);
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 4% 8%;

  .title {
    color: #000;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 42px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
  }
}
</style>
