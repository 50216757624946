import axios from "axios";
import store from "@/store";

export default function setup() {
  let languages = [];
  let responseLangs = {};
  axios.get(process.env.VUE_APP_API_URL + "server-config").then((response) => {
    languages = response.data.available_lang_array;
    languages.forEach((object) => {
      responseLangs[object.alpha2] = object.resources;
    });
  });
  store.commit("setTranslations", responseLangs);

  return responseLangs;
}
