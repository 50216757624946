<template>
  <div class="teams-statistics container">
    <h3 class="title">{{ $t("statistics.teams.title") }}</h3>
    <TeamsList :competition="competition" />
  </div>
</template>

<script>
import TeamsList from "@/components/shared/teams-list";
export default {
  name: "teams-statistics",
  components: { TeamsList },
  props: {
    competition: {},
  },
};
</script>

<style scoped lang="scss">
.teams-statistics {
  background-color: #fff;
  padding: 2%;
  border-radius: 20px;

  .title {
    color: #000;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 35px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
