<template>
  <div class="hero">
    <div class="team-wrapper">
      <div class="team-logo">
        <img :src="team?.team_logo?.thumbnails.small" />
      </div>
      <div class="flex-wrapper">
        <div class="team-short-name">{{ team?.short_name }}</div>
        <div class="team-long-name">{{ team?.name }}</div>
      </div>
    </div>

    <div class="btn-wrapper">
      <a v-if="team?.ticket_url" :href="team?.ticket_url" class="ticket-btn">Get tickets</a>
    </div>
  </div>

  <div class="tabs-wrapper">
    <ul class="tabs">
      <li :class="{ active: activeTab === 1 }">
        <a href="javascript:void(0)" @click="onChangeTab(1)">{{ $t("team.tabs.roster") }}</a>
      </li>
      <li :class="{ active: activeTab === 2 }">
        <a href="javascript:void(0)" @click="onChangeTab(2)">{{ $t("team.tabs.calendar") }}</a>
      </li>
      <li :class="{ active: activeTab === 3 }">
        <a href="javascript:void(0)" @click="onChangeTab(3)">{{ $t("team.tabs.team_statistics") }}</a>
      </li>
    </ul>
  </div>

  <div class="content">
    <div v-show="activeTab === 1">
      <div class="team-img">
        <img :src="team?.team_image?.public_url" />
      </div>

      <TeamRoster :players="players" :staffs="team?.staffs" />
    </div>
    <div v-show="activeTab === 2">
      <TeamCalendar :schedules="schedules" />
    </div>
    <div v-show="activeTab === 3">
      <TeamStatisticsSingle :player-averages="playerAverages" />
    </div>
  </div>

  <LatestNewsByTeam :team-name="team?.name" @latestNewsLoaded="loaders.latestNews = true" style="background: #f2faff" />
  <VideoHighlights @videoHighlightsLoaded="loaders.videoHighlights = true" @play-video="showVideoModal($event)" />

  <VideoModal :video-id="currentVideoId" @close="closeVideoModal" v-if="isVideoModalVisible" />
</template>

<script>
import playersService from "@/services/PlayersService";
import teamService from "@/services/TeamService";
import VideoHighlights from "@/components/shared/video-highlights";
import misc from "@/core/helpers/misc";
import VideoModal from "@/components/shared/modals/video-modal";
import LatestNewsByTeam from "@/components/shared/latest-news-by-team";
import TeamRoster from "@/views/teams/team/team-roster";
import TeamCalendar from "@/views/teams/team/team-calendar";
import TeamStatisticsSingle from "@/views/teams/team/team-statistics-single";
import scheduleService from "@/services/ScheduleService";
export default {
  name: "team",
  components: {
    TeamCalendar,
    TeamRoster,
    LatestNewsByTeam,
    VideoHighlights,
    VideoModal,
    TeamStatisticsSingle,
  },
  data() {
    return {
      players: [],
      schedules: [],
      playerAverages: [],
      team: null,
      isVideoModalVisible: false,
      currentVideoId: String,
      misc: misc,
      activeTab: 1,
      loaders: {
        latestNews: false,
        videoHighlights: false,
        players: false,
        team: false,
        schedules: false,
        playerAverages: false,
      },
    };
  },
  mounted() {
    const teamId = this.$route.query.team_id;
    const actualSeason = process.env.VUE_APP_ACTIVE_SEASON;
    this.getPlayersByTeamId(teamId);
    this.getTeamById(teamId);
    this.getSchedules(actualSeason, teamId);
    this.getPlayerAverages(teamId);
  },
  methods: {
    async getPlayersByTeamId(teamId) {
      await playersService.getPlayersByTeamId(teamId).then((res) => {
        this.players = res.sort((a, b) => {
          if (!!a.player.profile_image?.thumbnails?.medium && !b.player.profile_image?.thumbnails?.medium) return -1;
          if (!a.player.profile_image?.thumbnails?.medium && !!b.player.profile_image?.thumbnails?.medium) return 1;
          return a.player.last_name.localeCompare(b.player.last_name);
        });
        this.loaders.players = true;
      });
    },

    async getTeamById(teamId) {
      this.team = await teamService.getCompetitionTeam(teamId);
      this.loaders.team = true;
    },

    async getSchedules(season, teamId) {
      this.schedules = await scheduleService.getSchedules(season, null, null, teamId, -1);
      this.loaders.schedules = true;
    },

    async getPlayerAverages(competitionTeamId) {
      this.playerAverages = await teamService.getPlayerAverages(competitionTeamId);
      this.loaders.playerAverages = true;
    },

    onChangeTab(tabId) {
      this.activeTab = tabId;
    },

    showVideoModal(videoUrl) {
      this.currentVideoId = misc.getVideoId(videoUrl);
      this.isVideoModalVisible = true;
    },

    closeVideoModal() {
      this.currentVideoId = null;
      this.isVideoModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.hero {
  background: url("../../../assets/img/hero/bnx_bg-pink.jpg") no-repeat top left;
  background-size: cover;
  height: 450px;
  padding: 8% 8% 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .team-wrapper {
    display: flex;
    align-items: center;
    gap: 40px;

    .team-logo {
      img {
        max-width: 130px;
      }
    }

    .flex-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .team-short-name {
        font-size: 28px;
        line-height: 34px;
        font-family: DrukWide;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
      }

      .team-long-name {
        font-family: DrukWideBoldItalic, Sans-serif;
        font-weight: 600;
        font-size: 60px;
        text-transform: uppercase;
        color: #fff;
        line-height: 67px;
      }
    }
  }

  .btn-wrapper {
    margin-top: 45px;

    .ticket-btn {
      font-family: DrukWideItalic, Sans-serif;
      border: solid 2px #fff;
      border-radius: 100px;
      padding: 8px 20px;
      box-shadow: none;
      font-size: 20px;
      color: #000;
      text-transform: uppercase;
      background-color: #fff;
      font-weight: 500;
      text-decoration: none;
      transition: 0.3s;

      &:hover {
        color: #212529;
        background-color: #c36;
      }
    }
  }
}

.tabs-wrapper {
  position: relative;
  top: -27px;
  padding: 0 8%;
  margin-bottom: -54px;

  .tabs {
    padding: 0;
    list-style: none;
    background: #fff;
    border-radius: 25px;
    margin: 0 auto;
    max-width: 100%;
    float: none;
    display: inline-block;
    box-shadow: 0 10px 5px #def3ff;

    li {
      display: inline-block;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-weight: 600;
      font-size: 24px;
      padding: 10px 40px;
      border-radius: 50px;
      margin: -1px;
      text-transform: uppercase;

      a {
        color: #003c80;
        text-decoration: none;
      }

      &.active {
        background: #eb3ce7;

        a {
          color: #fff;
        }
      }
    }
  }
}

.content {
  padding: 90px 8% 20px;
  background: #f2faff;

  .team-img {
    margin-bottom: 25px;

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .hero {
    padding-left: 20px;
    padding-right: 20px;

    .team-wrapper {
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .hero {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 400px;

    .team-wrapper {
      gap: 0;

      .team-logo {
        img {
          max-width: 90px;
        }
      }

      .flex-wrapper {
        gap: 0;

        .team-short-name {
          font-size: 17px;
          line-height: 24px;
        }

        .team-long-name {
          font-size: 30px;
          line-height: 38px;
        }
      }
    }

    .btn-wrapper {
      width: 100%;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 767px) {
  .tabs-wrapper {
    text-align: center;

    .tabs {
      li {
        width: 100%;
      }
    }
  }
}
</style>
