<template>
  <div id="categories">
    <div class="container">
      <h2 class="title-1">BNXT</h2>
      <h2 class="title-2">Awards</h2>
      <h2 class="title-3">Categories</h2>
      <p class="description">{{ award?.categories_description }}</p>

      <div class="categories-wrapper">
        <div class="category" v-for="category in awardCategories">{{ category?.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "awards-categories",
  props: {
    award: null,
  },
  data() {
    return {
      awardCategories: null,
    };
  },
  watch: {
    award(award) {
      this.awardCategories = award.award_categories.filter((cat) => {
        if (!cat.title.includes("hidden")) {
          cat.title = cat.title.split("(")[0];
        }
        return !cat.title.includes("hidden");
      });
    },
  },
};
</script>

<style scoped lang="scss">
#categories {
  background: #0d0141;
  padding: 6% 0;

  .container {
    max-width: 800px;
    text-align: center;

    .title-1 {
      color: #fff;
      font-family: DrukWide, Sans-serif;
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.85em;
    }

    .title-2 {
      color: #ffffff00;
      font-family: DrukWide, Sans-serif;
      font-size: 100px;
      font-weight: 500;
      text-transform: uppercase;
      -webkit-text-stroke: 2px #fff;
      line-height: 1;
    }

    .title-3 {
      color: #fff;
      font-family: DrukWide, Sans-serif;
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.85em;
    }

    .description {
      text-align: center;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4em;
      margin: 0;
      padding: 30px 0 100px;
      position: relative;
      z-index: 1;
    }

    .categories-wrapper {
      position: relative;
      z-index: 1;

      .category {
        font-family: DrukWideItalic, Sans-serif;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 0.85em;
        fill: #fff;
        color: #fff;
        background-color: #6441ff;
        border-radius: 100px;
        padding: 10px 30px;
        display: inline-block;
        margin: 10px 5px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  #categories {
    .container {
      .title-1 {
        font-size: 24px;
      }

      .title-2 {
        font-size: 60px;
      }

      .title-3 {
        font-size: 24px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  #categories {
    .container {
      .title-1 {
        font-size: 20px;
      }

      .title-2 {
        font-size: 42px;
      }

      .title-3 {
        font-size: 20px;
      }

      .description {
        font-size: 16px;
        padding-bottom: 50px;
      }

      .categories-wrapper {
        .category {
          font-size: 20px;
          padding: 10px 15px;
          margin: 5px;
        }
      }
    }
  }
}
</style>
