<template>
  <div class="latest-results">
    <h2 class="title">{{ $t("home.latest_results.title") }}</h2>

    <div class="flex-container">
      <a href="https://bnxt.tv/" target="_blank" class="league-logo">
        <img src="../../assets/img/bnxt_tv_black.png" class="img-fluid league-image" />
      </a>

      <div class="swiper" v-if="computedInitialSlideIndex !== null">
        <swiper
          :initial-slide="computedInitialSlideIndex"
          :modules="modules"
          :space-between="20"
          :navigation="{
            nextEl: '.swiper-button-next.latest-results-swiper-nav',
            prevEl: '.swiper-button-prev.latest-results-swiper-nav',
          }"
          :pagination="{ clickable: true }"
          :breakpoints="breakpoints"
          :slides-offset-after="180"
          class="swiper-latest-results"
        >
          <swiper-slide v-for="(item, index) of slides" :key="item">
            <router-link
              class="swiper-box-link"
              v-if="item?.status === 'finished'"
              :to="{
                name: misc.getRouteNameByLocale($router.options.routes, 'Game report'),
                params: { gameId: item.id },
              }"
            >
              <home-latest-result-box :item="item" />
            </router-link>
            <div v-else>
              <home-latest-result-box :item="item" />
            </div>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev latest-results-swiper-nav">
          <font-awesome-icon icon="fa-solid fa-angle-left" />
        </div>
        <div class="swiper-button-next latest-results-swiper-nav">
          <font-awesome-icon icon="fa-solid fa-angle-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper";
import HomeLatestResultBox from "@/views/home/home-latest-result-box";
import misc from "@/core/helpers/misc";
import moment from "moment/moment";

export default {
  name: "home-latest-results",
  components: { Swiper, SwiperSlide, HomeLatestResultBox },

  props: {
    slides: null,
  },

  watch: {
    slides(val) {
      let initialSlideIndex = 0;
      const firstLiveMatch = val.find((match) => match.status === "live");
      const finishedMatches = val.filter((match) => match.status === "finished");
      // If there is live state match we should display the first one as first slide

      if (firstLiveMatch) {
        initialSlideIndex = val.findIndex((match) => match.id === firstLiveMatch.id);

        // If there is no live state match but finished state match, then we should display the penultimate as first slide
      } else if (finishedMatches && finishedMatches.length > 0) {
        const lastFinishedMatch = finishedMatches[finishedMatches.length - 1];
        const lastFinishedMatchIndex = val.findIndex((match) => match.id === lastFinishedMatch.id);

        if (this.isMobileMode) {
          initialSlideIndex = lastFinishedMatchIndex;
        } else {
          initialSlideIndex = lastFinishedMatchIndex > 0 ? lastFinishedMatchIndex - 1 : lastFinishedMatchIndex;
        }
      }
      this.initialSlideIndex = initialSlideIndex;
    },
  },

  computed: {
    moment: () => moment,
    computedInitialSlideIndex() {
      return this.initialSlideIndex;
    },
  },

  data() {
    return {
      misc: misc,
      initialSlideIndex: null,
      isMobileMode: window.innerWidth <= 1250,
      breakpoints: {
        1780: {
          slidesPerView: 5.5,
          slidesOffsetAfter: 200,
        },
        1300: {
          slidesPerView: 4,
          slidesOffsetAfter: 200,
        },
        850: {
          slidesPerView: 2,
          slidesOffsetAfter: 120,
        },
        1: {
          slidesPerView: 1,
          slidesOffsetAfter: 30,
        },
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  methods: {
    onResize() {
      this.isMobileMode = window.innerWidth <= 1250;
    },
  },

  setup() {
    return {
      modules: [Navigation],
    };
  },
};
</script>

<style scoped lang="scss">
.swiper-box-link {
  text-decoration: none;
  font-family: DrukWide, Sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  color: inherit;
}

.latest-results {
  margin-top: 95px;
  padding: 50px 8%;
  background: #000;

  .title {
    color: #fff;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 80px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 30px;
  }

  .flex-container {
    display: flex;
    align-items: stretch;

    &.team-wrapper {
      background: #bababa;
      padding: 5px;
      align-items: center;
    }

    .league-logo {
      width: 15%;
      background: #ffffff;
      height: 160px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      .league-image {
        max-width: 65%;
      }
    }

    .swiper {
      width: 90%;

      .swiper-button-prev,
      .swiper-button-next {
        font-size: 30px;
        color: #6441ff;
        background-color: #fff;
        padding: 1.55vw;
        margin-top: 0;
        height: 100%;
        top: 0;
        opacity: 0.5;
      }

      .swiper-button-disabled {
        opacity: 0.8;
      }

      .swiper-button-next {
        //right: 6%;
      }

      :deep(.swiper-button-prev:after),
      :deep(.swiper-button-next:after) {
        content: none;
      }

      &-slide {
        &:nth-child(4n + 1) {
          .flex-container {
            &:nth-child(2) {
              background: #f75f00;
            }

            &:nth-child(3) {
              background: #e2ff00;
            }
          }
        }

        &:nth-child(4n + 2) {
          .flex-container {
            &:nth-child(2) {
              background: #63c5ff;
            }

            &:nth-child(3) {
              background: #eab0ee;
            }
          }
        }

        &:nth-child(4n + 3) {
          .flex-container {
            &:nth-child(2) {
              background: #bababa;
            }

            &:nth-child(3) {
              background: #eeffd8;
            }
          }
        }

        &:nth-child(4n + 4) {
          .flex-container {
            &:nth-child(2) {
              background: #37d69e;
            }

            &:nth-child(3) {
              background: #ff0028;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1249px) {
  .league-logo {
    display: none !important;
  }

  .swiper {
    width: 100% !important;
  }

  .swiper.swiper-latest-results {
    width: calc(100% - 120px) !important;
  }

  .latest-results .flex-container .swiper .swiper-button-prev {
    left: 0;
    padding: 30px;
  }

  .latest-results .flex-container .swiper .swiper-button-next {
    right: 0;
    padding: 30px;
  }
}
</style>
