<template>
  <div class="flex-container">
    <span>{{ $t("filter") }}</span>

    <div class="selects">
      <div class="select-wrapper">
        <label>{{ $t("season") }}</label>
        <select v-model="filters.season" @change="onChangeSeason">
          <option v-for="season in seasons" :value="season.season">
            {{ season.season - 1 }} - {{ season.season }}
          </option>
        </select>
      </div>

      <div class="select-wrapper" v-if="showMonthSelector">
        <label>{{ $t("month") }}</label>
        <select v-model="filters.month" @change="emitFilters">
          <option value="-1">{{ $t("all_months") }}</option>
          <option :value="1">{{ $t("months.january") }}</option>
          <option :value="2">{{ $t("months.february") }}</option>
          <option :value="3">{{ $t("months.march") }}</option>
          <option :value="4">{{ $t("months.april") }}</option>
          <option :value="5">{{ $t("months.may") }}</option>
          <option :value="6">{{ $t("months.june") }}</option>
          <option :value="7">{{ $t("months.july") }}</option>
          <option :value="8">{{ $t("months.august") }}</option>
          <option :value="9">{{ $t("months.september") }}</option>
          <option :value="10">{{ $t("months.october") }}</option>
          <option :value="11">{{ $t("months.november") }}</option>
          <option :value="12">{{ $t("months.december") }}</option>
        </select>
      </div>

      <div class="select-wrapper">
        <label>{{ $t("phase") }}</label>
        <select v-model="filters.phase" @change="emitFilters">
          <option :value="null" v-if="isOverall">{{ $t("overall") }}</option>
          <option v-for="phase in phases" @click="filters.phaseName = phase.name" :value="phase.id">
            {{ phase.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>
<script>
import seasonService from "@/services/SeasonService";
import phaseService from "@/services/PhaseService";
export default {
  props: {
    isStandings: {
      default: false,
      type: Boolean,
    },
    isOverall: {
      default: false,
      type: Boolean,
    },
    showMonthSelector: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      seasons: [],
      phasesComp: [],
      phases: [],
      filters: {
        season: null,
        phase: null,
        phaseName: "",
        competitionId: null,
        month: -1,
      },
    };
  },
  mounted() {
    this.getSeasons();
  },
  methods: {
    async getSeasons() {
      this.seasons = await seasonService.getSeasons();
      this.filters.season = this.seasons[0].season;
      this.getPhases(this.seasons[0].season, this.isStandings);
    },

    async getPhases(season, isStandings) {
      this.phasesComp = await phaseService.getPhases(season, isStandings);

      this.phases = this.phasesComp[0].phases;

      this.filters.phase = this.isOverall ? null : this.phases[0]?.id;
      this.filters.competitionId = this.phasesComp[0]?.competition?.id;
      this.filters.phaseName = this.phases[0]?.name;
      this.filters.isStandingsVisible = this.phasesComp[0].competition.standings_visible;
      this.emitFilters();
    },

    onChangeSeason() {
      this.getPhases(this.filters.season, this.isStandings);
    },

    emitFilters() {
      this.filters.phaseName = this.phases.find((phase) => phase.id === this.filters.phase)?.name;
      if (this.filters.season && !this.isOverall) {
        this.$emit("filters", this.filters);
      } else if (this.filters.season && this.isOverall) {
        this.$emit("filters", this.filters);
      } else if (this.filters.month) {
        this.$emit("filters", this.filters);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 20px;

  span {
    font-family: DrukWideBoldItalic, Sans-serif;
    font-weight: 600;
    color: #000;
    width: 100px;
    font-size: 30px;
    text-transform: uppercase;
  }

  .selects {
    width: 100%;
    display: flex;
    gap: 20px;

    .select-wrapper {
      width: 48%;

      label {
        font-family: DrukWide;
        font-weight: 500;
        color: #000;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .flex-container {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }

  .selects {
    flex-direction: column;

    .select-wrapper {
      width: 100% !important;
    }
  }
}
</style>
