<template>
  <div class="video-highlights" v-if="videoHighlights?.length">
    <div class="running-text-wrapper">
      <div class="running-text original">{{ $t("video_highlights.running_text") }}</div>
      <div class="running-text copy">{{ $t("video_highlights.running_text") }}</div>
    </div>

    <div class="content">
      <h3 class="title">{{ $t("video_highlights.title") }}</h3>

      <div class="swiper wrapper">
        <swiper
          :modules="modules"
          :space-between="30"
          :navigation="{
            nextEl: '.swiper-button-next.video-highlights-swiper-nav',
            prevEl: '.swiper-button-prev.video-highlights-swiper-nav',
          }"
          :pagination="{ clickable: true }"
          :breakpoints="breakpoints"
        >
          <swiper-slide v-for="item of videoHighlights" :key="item">
            <HomeVideoBox :video-article="item" @play-video="$emit('playVideo', $event)" />
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev video-highlights-swiper-nav">
          <font-awesome-icon icon="fa-solid fa-angle-left" />
        </div>
        <div class="swiper-button-next video-highlights-swiper-nav">
          <font-awesome-icon icon="fa-solid fa-angle-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeVideoBox from "@/views/home/home-video-box";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
import newsAndVideosService from "@/services/NewsAndVideosService";

export default {
  name: "video-highlights",
  components: { HomeVideoBox, Swiper, SwiperSlide },

  data() {
    return {
      videoHighlights: [],
      breakpoints: {
        1200: {
          slidesPerView: 3.5,
          slidesOffsetAfter: 180,
        },
        767: {
          slidesPerView: 2.2,
          slidesOffsetAfter: 120,
        },
        1: {
          slidesPerView: 1.055,
          slidesOffsetAfter: 30,
        },
      },
    };
  },

  mounted() {
    this.getVideoHighlights();
  },

  methods: {
    async getVideoHighlights() {
      await newsAndVideosService.getVideos().then((res) => (this.videoHighlights = res.splice(0, 11)));
      this.$emit("videoHighlightsLoaded");
    },
  },

  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped lang="scss">
.video-highlights {
  background: url("../../assets/img/bnxt_bg_black_video-min.jpg") no-repeat top left;
  background-size: cover;
  padding: 4% 0;

  .running-text-wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
    white-space: nowrap;

    .running-text {
      font-family: DrukWide, Sans-serif;
      font-size: 100px;
      text-transform: uppercase;
      line-height: 1.3em;
      -webkit-text-stroke-color: #eb3ce782;
      -webkit-text-stroke-width: 2px;
      color: transparent;

      &.original {
        /* animation properties */
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);

        -moz-animation: text-slide 20s linear infinite;
        -webkit-animation: text-slide 20s linear infinite;
        animation: text-slide 20s linear infinite;
      }
      &.copy {
        /* animation properties */
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);

        -moz-animation: text-slide-copy 20s linear infinite;
        -webkit-animation: text-slide-copy 20s linear infinite;
        animation: text-slide-copy 20s linear infinite;

        position: absolute;
        top: 0;
        left: 100px;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
    }
  }

  .content {
    position: relative;
    z-index: 2;
    top: -80px;
    margin-bottom: -80px;

    .title {
      color: #fff;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-size: 80px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.3em;
      padding: 0 8% 50px;
    }

    .swiper-button-prev,
    .swiper-button-next {
      font-size: 30px;
      color: #fff;
      background-color: #f75f00;
      border-radius: 100px 100px 100px 100px;
      box-shadow: 0 5px 20px 0 #f75f00;
      padding: 30px;
      top: calc(50% - 30px);
    }

    .swiper-button-prev {
      left: 6%;
    }

    .swiper-button-next {
      right: 6%;
    }

    :deep(.swiper-wrapper) {
      padding-left: 8%;
      padding-bottom: 50px;
    }

    :deep(.swiper-button-prev:after),
    :deep(.swiper-button-next:after) {
      content: none;
    }

    :deep(.swiper-pagination-bullet) {
      margin: 0 6px !important;
      background: #fff;
      width: 6px;
      height: 6px;
    }
  }

  /* for Firefox */
  @-moz-keyframes text-slide {
    from {
      -moz-transform: translateX(0);
    }
    to {
      -moz-transform: translateX(-100%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes text-slide {
    from {
      -webkit-transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @keyframes text-slide {
    from {
      -moz-transform: translateX(0);
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  /* for Firefox */
  @-moz-keyframes text-slide-copy {
    from {
      -moz-transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(0);
    }
  }

  /* for Chrome */
  @-webkit-keyframes text-slide-copy {
    from {
      -webkit-transform: translateX(100%);
    }
    to {
      -webkit-transform: translateX(0);
    }
  }

  @keyframes text-slide-copy {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(0);
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .video-highlights {
    .content {
      .title {
        font-size: 50px;
        padding-left: 58px;
        padding-bottom: 20px;
      }

      .swiper-button-prev {
        left: 3%;
      }

      .swiper-button-next {
        right: 3%;
      }

      :deep(.swiper-wrapper) {
        padding-left: 58px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .video-highlights {
    .content {
      top: -20px;
      margin-bottom: -20px;

      .title {
        font-size: 42px;
        padding-left: 20px;
      }

      :deep(.swiper-wrapper) {
        padding-left: 20px;
      }
    }

    .running-text-wrapper {
      .running-text {
        font-size: 30px;
      }
    }
  }
}
</style>
