<template>
  <div class="loader-overlay"></div>

  <img src="../../../assets/img/ajax-loader.gif" class="loader-img" />
</template>

<script>
export default {
  name: "AppLoader",
};
</script>

<style scoped lang="scss">
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background: rgba(255, 255, 255, 0.6);
}

.loader-img {
  position: fixed;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  z-index: 999999;
}
</style>
