<template>
  <div class="referee">
    <div class="img-holder">
      <img :src="referee.profile_image.thumbnails.medium" v-if="referee?.profile_image?.thumbnails?.medium" />
      <img src="../../assets/img/image_not_found.png" class="not-found" v-else />
    </div>

    <div class="referee-details">
      <div class="name">{{ referee?.name?.toUpperCase() }}</div>
      <div class="position">{{ referee?.title?.name }}</div>
    </div>
  </div>
</template>
<script>
import misc from "@/core/helpers/misc";
export default {
  props: {
    referee: {},
  },
  data() {
    return {
      misc: misc,
    };
  },
};
</script>

<style scoped lang="scss">
.referee {
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    position: absolute;
    left: 5.6%;
    top: 18px;
    width: 100%;
    height: 100%;
    background-color: #6441ff;
    z-index: -1;
  }

  .img-holder {
    background-color: #000;
    float: left;
    width: 100%;
    height: 24vw;
    padding: 0 20px;
    position: relative;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;

      &.not-found {
        height: 90%;
        object-fit: cover;
        bottom: 0;
        top: auto;
      }
    }
  }

  &-details {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));

    .name {
      text-transform: uppercase;
      color: #fff;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-weight: 600;
      font-size: 3vw;
      line-height: 0.85em;
    }

    .position {
      margin-bottom: 0;
      margin-top: 8px;
      font-family: DrukWide;
      font-weight: 500;
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}

@media only screen and (max-width: 980px) {
  .referee {
    .img-holder {
      min-height: 36vw;
    }
  }
}

@media only screen and (max-width: 768px) {
  .referee {
    .img-holder {
      min-height: 50vw;
    }

    &-details {
      .name {
        font-size: 7vw;
        line-height: 0.85em;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  .referee {
    .img-holder {
      min-height: 100vw;
    }
  }
}
</style>
