<template>
  <div class="event">
    <div class="event-date">
      <div class="day">{{ moment(event.event_date).format("DD") }}</div>
      <div class="month">{{ moment(event.event_date).format("MMM") }}</div>
      <div class="time">{{ moment(event.event_date).format("h:mm a") }}</div>
    </div>

    <div class="event-img">
      <img v-if="event.logo" :src="event.logo.thumbnails.small" alt="" class="img-fluid" />
    </div>

    <div class="event-details">
      <h5 class="cup-name" v-if="event.sub_name">{{ event.sub_name }}</h5>
      <h3 class="event-name">{{ event.name }}</h3>

      <p class="event-description" v-html="event.description"></p>

      <div class="event-buttons">
        <a v-if="event.details_url" :href="event.details_url" class="more-details">more details</a>
        <a v-if="event.ticket_url" :href="event.ticket_url" target="_blank" class="tickets">Website</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "event-box",
  props: ["event"],
  computed: { moment: () => moment },
};
</script>

<style scoped lang="scss">
.event {
  display: flex;
  align-items: stretch;
  box-shadow: 0 10px 30px 0 rgb(50 100 255 / 50%);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 10px 20px 40px;
  padding: 36px;
  border-radius: 12px;

  &-date {
    width: 15%;
    display: flex;
    gap: 5px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 0 1px 0 0;
    border-color: #f0f0f0;

    .day {
      color: #000;
      font-family: DrukWide, Sans-serif;
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1em;
    }

    .month {
      color: #000;
      font-family: DrukWide, Sans-serif;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1em;
    }

    .time {
      color: #000;
      font-family: DrukWide, Sans-serif;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1em;
      letter-spacing: 1px;
      text-align: center;
    }
  }

  &-img {
    width: 20%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 150px;
      width: 100%;
    }
  }

  &-details {
    width: 64.664%;

    .cup-name {
      color: #003c80;
      font-family: DrukWide, Sans-serif;
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1em;
    }

    .event-name {
      color: #003c80;
      font-family: DrukWideItalic, Sans-serif;
      font-size: 42px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 1em;
      margin-bottom: 10px;
    }

    .event-description {
      margin-top: 0;
      padding-right: 70px;
    }

    .event-buttons {
      .more-details {
        background-color: #ffffff00;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1em;
        color: #6441ff;
        border-color: #6441ff;
        border-width: 2px;
        border-radius: 100px 100px 100px 100px;
        padding: 10px 20px;
        cursor: pointer;
        border-style: solid;
        transition: 0.25s;
        display: inline-block;
        text-decoration: none;

        &:hover {
          color: #fff;
          background: #6441ff;
          border-color: #6441ff;
        }
      }

      .tickets {
        background-color: #6441ff;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1em;
        color: #fff;
        border-color: #6441ff;
        border-width: 2px;
        border-radius: 100px 100px 100px 100px;
        padding: 10px 20px;
        cursor: pointer;
        border-style: solid;
        transition: 0.25s;
        display: inline-block;
        margin: 0 10px;
        text-decoration: none;

        &:hover {
          color: #fff;
          background: #3264ff;
          border-color: #3264ff;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .event {
    &-details {
      padding: 10px 20px;

      .cup-name {
        font-size: 12px !important;
      }

      .event-name {
        font-size: 35px !important;
      }

      .event-description {
        font-size: 14px !important;
        padding-right: 0 !important;
      }

      .event-buttons {
        a {
          font-size: 20px !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .event {
    flex-wrap: wrap;
    padding: 25px 5px;
    margin: 10px 0 20px;
    align-items: flex-start;

    &-date {
      width: 100%;
      flex-direction: row;
      padding-bottom: 20px;
      border: none;
      border-bottom: 1px solid #f0f0f0;

      .day {
        font-size: 20px;
      }

      .month,
      .time {
        font-size: 16px;
      }
    }

    &-details {
      width: 75%;

      .cup-name {
        font-size: 10px !important;
      }

      .event-name {
        font-size: 30px !important;
      }

      .event-description {
        font-size: 12px !important;
      }

      .event-buttons {
        a {
          font-size: 16px !important;
        }
      }
    }

    &-img {
      margin-top: 20px;
      width: 25%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .event-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .more-details,
    .tickets {
      text-align: center;
      margin: 0 !important;
    }
  }
}
</style>
