<template>
  <vue-cookie-accept-decline
    :debug="false"
    :disableDecline="false"
    :showPostponeButton="false"
    elementId="myPanel1"
    position="bottom-left"
    ref="myPanel1"
    transitionName="slideFromBottom"
    type="floating"
    class="cookie-bar"
  >
    <!-- Optional -->
    <!--    <template #postponeContent>&times;</template>-->

    <!-- Optional -->
    <!--    <template #message>
      {{ $t("cookie_bar.message") }}
    </template>-->

    <!-- Optional -->
    <!--    <template #declineContent>{{ $("cookie_bar.decline") }}</template>-->

    <!-- Optional -->
    <!--    <template #acceptContent>{{ $("cookie_bar.accept") }}</template>-->
  </vue-cookie-accept-decline>
</template>

<script>
export default {
  name: "cookie-bar",
};
</script>

<style scoped lang="scss">
.cookie-bar {
  background-color: rgb(10, 10, 10);
  color: #fff;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 9999;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  padding: 20px;
  font-size: 14px;
  font-weight: bold;
  width: 100%;

  :deep(.cookie__floating__wrap) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      border-radius: 100px;
      background-color: #e2ff32 !important;
      font-family: DrukWide;
      color: #000 !important;
      font-size: 14px;
      padding: 8px 16px;

      &:focus {
        opacity: 0.7;
      }

      &.cookie__floating__buttons__button--decline {
        display: none;
      }
    }
  }
}
</style>
