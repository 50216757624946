import axios from "axios";

export default {
  getWelcomePopup() {
    return axios.get(process.env.VUE_APP_API_URL + "banner/popup").then((response) => {
      return response.data.data;
    });
  },
  getBannerList() {
    return axios.get(process.env.VUE_APP_API_URL + "banner/list").then((response) => {
      return response.data.data;
    });
  },
};
