<template>
  <div class="survey-fans">
    <!-- <div class="content">
      <div class="images">
        <img src="../../assets/img/survey-img.jpg" class="img-fluid" />
      </div>
      <p class="description">
        The BNXT League and its title sponsor betFIRST partnered up in 2021 to initiate the one and only cross-border
        Belgian-Dutch basketball league. Together with the renowned Belgian provider of online Sports Betting and Casino
        games, we would like to give something back to the fans.
      </p>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "survey-fans",
};
</script>

<style scoped lang="scss">
.survey-fans {
  background: #000;
  padding: 150px 0;

  .content {
    display: flex;
    gap: 100px;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;

    .images {
      width: 50%;
    }

    .description {
      width: 50%;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.4em;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .survey-fans {
    .content {
      .images {
        img {
          &:first-child {
            width: 200px;
          }
        }
      }

      .description {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .survey-fans {
    .content {
      flex-direction: column;
      gap: 20px;

      .images {
        width: 100%;

        img {
          &:first-child {
            width: 200px;
          }
        }
      }

      .description {
        width: 100%;
        font-size: 18px;
      }
    }
  }
}
</style>
