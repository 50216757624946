import Standings from "@/views/standings/Standings";

export const standingsRoutes = [
  {
    lang: "en",
    path: "/en/standings",
    name: "Standings",
    component: Standings,
  },
  {
    lang: "fr",
    path: "/fr/standings",
    name: "Classement",
    component: Standings,
  },
  {
    lang: "nl",
    path: "/nl/standings",
    name: "Klassement",
    component: Standings,
  },
];
