import axios from "axios";

export default {
  getPlayersByTeamId(teamId, params) {
    return axios.get(process.env.VUE_APP_API_URL + "roster/team-players/" + teamId, { params }).then((response) => {
      return response.data.data;
    });
  },

  getPlayersBySeason(season, params) {
    return axios.get(process.env.VUE_APP_API_URL + "roster/season-players/" + season, { params }).then((response) => {
      return response.data.data;
    });
  },

  getPlayerCareerAverages(rosterId) {
    return axios.get(process.env.VUE_APP_API_URL + "player/career-averages/" + rosterId).then((response) => {
      return response.data.data;
    });
  },

  getPlayerSeasonGames(rosterId) {
    return axios.get(process.env.VUE_APP_API_URL + "boxscore/player/season-games/" + rosterId).then((response) => {
      return response.data.data;
    });
  },

  getPlayerCountries() {
    return axios
      .get(process.env.VUE_APP_API_URL + "roster/season-nationality-codes/" + process.env.VUE_APP_ACTIVE_SEASON)
      .then((response) => {
        return response.data.filter((country) => country).sort();
      });
  },

  getRosterByRosterId(rosterId) {
    return axios.get(process.env.VUE_APP_API_URL + "roster/find/" + rosterId).then((response) => {
      return response.data.data;
    });
  },

  getPlayerOverallStats(averages) {
    let stats = {
      minutes: 0,
      points: 0,
      rebounds: 0,
      assists: 0,
    };
    averages.forEach(function (item) {
      if (item.competition.season == process.env.VUE_APP_ACTIVE_SEASON) {
        stats.minutes += item.minute;
        stats.points += item.points;
        stats.rebounds += item.total_rebound;
        stats.assists += item.assist;
      }
    });
    return stats;
  },
};
