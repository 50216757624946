<template>
  <div class="page-not-found">
    <div class="flex-container">
      <h1 class="title">404</h1>
      <h2 class="subtitle">{{ $t("page_not_found.title") }}</h2>
      <a :href="'/' + currentLocale" class="back-btn">{{ $t("page_not_found.button") }}</a>
    </div>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";
export default {
  name: "page-not-found",
  data() {
    return {
      misc: misc,
      currentLocale: localStorage.getItem("lang"),
    };
  },
};
</script>

<style scoped lang="scss">
.page-not-found {
  height: 100vh;
  background: url("../../assets/img/slider/bnx_slider_3-min-scaled.jpg") no-repeat center center;
  background-size: cover;

  .flex-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      color: #fff;
      font-family: "DrukWide", Sans-serif;
      font-size: 160px;
      font-weight: 600;
      line-height: 1em;
      letter-spacing: 4px;
    }

    .subtitle {
      color: #fff;
      font-family: "DrukWideItalic", Sans-serif;
      font-size: 90px;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 1em;
    }

    .back-btn {
      font-family: "DrukWideBoldItalic", Sans-serif;
      font-size: 2rem;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1em;
      color: #fff;
      border-color: #f75f00;
      border-width: 2px;
      border-radius: 100px;
      padding: 15px 40px;
      background: #f75f00;
      text-decoration: none;
      margin-top: 20px;
      transition: 0.3s;

      &:hover {
        background: #fff;
        color: #f75f00;
      }
    }
  }
}
</style>
