<template>
  <div class="result">
    <div class="flex-container">
      <div class="league">{{ item?.phase?.name }}</div>
      <div class="round"></div>
    </div>
    <div class="flex-container team-wrapper">
      <div class="team">
        <img
          :src="item.competitors[0].competition_team.team_logo.thumbnails.small"
          class="team-logo"
          v-if="item?.competitors[0]?.competition_team?.team_logo?.thumbnails?.small"
        />
        <div class="team-name">{{ item?.competitors[0]?.competition_team?.short_name }}</div>
      </div>
      <div class="score">{{ item?.competitors[0]?.finalScore }}</div>
    </div>
    <div class="flex-container team-wrapper">
      <div class="team">
        <img
          :src="item.competitors[1].competition_team.team_logo.thumbnails.small"
          class="team-logo"
          v-if="item?.competitors[1]?.competition_team?.team_logo?.thumbnails?.small"
        />
        <div class="team-name">{{ item?.competitors[1]?.competition_team?.short_name }}</div>
      </div>
      <div class="score">{{ item?.competitors[1]?.finalScore }}</div>
    </div>
    <div class="flex-container">
      <a
        :href="item.video_stream_url || 'https://www.bnxt.tv'"
        target="_blank"
        class="view live"
        v-if="item?.status === 'live'"
      >
        {{ $t("home.latest_results.live_button") }}
      </a>

      <a
        :href="'https://fibalivestats.dcd.shared.geniussports.com/u/BNXT/' + item?.genius_id"
        target="_blank"
        class="view stats"
        v-if="item?.status === 'live'"
      >
        {{ $t("home.latest_results.live_stats_button") }}
      </a>

      <div class="date" v-else-if="item?.status !== 'live'">
        {{ moment(item?.game_time).format("MMM DD,") }}
        <br />
        {{ moment(item?.game_time).format("HH:mm") }}
      </div>

      <router-link
        :to="{
          name: misc.getRouteNameByLocale($router.options.routes, 'Game report'),
          params: { gameId: item.id },
        }"
        class="view"
        v-if="item?.status === 'finished'"
      >
        {{ $t("home.latest_results.finished_button") }}
      </router-link>

      <div
        class="button-wrapper"
        v-if="item?.competitors[0]?.competition_team?.ticket_url && !['live', 'finished'].includes(item?.status)"
      >
        <a :href="item.competitors[0].competition_team.ticket_url" class="get-tickets-btn">
          {{ $t("get_tickets") }}
        </a>
      </div>
      <!-- <div class="button-wrapper" v-if="item?.status !== 'finished' && item.competition.ticket_url">
        <a :href="item.competition.ticket_url" target="_blank" class="get-tickets-btn">
          {{ $t("tickets") }}
        </a>
      </div> -->
    </div>
  </div>
</template>

<script>
import moment from "moment/moment";
import misc from "@/core/helpers/misc";
export default {
  name: "home-latest-result-box",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    moment: () => moment,
  },
  data() {
    return {
      misc: misc,
    };
  },
};
</script>

<style scoped lang="scss">
.latest-results {
  margin-top: 30px;
  padding: 50px 8%;
  background: #6441ff;

  .flex-container {
    display: flex;
    align-items: stretch;

    .button-wrapper {
      margin-right: 6px;
      display: inline;
      .get-tickets-btn {
        background-color: #6441ff;
        font-family: DrukWideItalic, Sans-serif;
        text-transform: uppercase;
        color: #fff;
        border-color: #6441ff;
        border-width: 2px;
        border-radius: 100px 100px 100px 100px;
        padding: 1px 15px;
        border-style: solid;
        text-decoration: none;
        font-size: 20px;
      }
    }

    &.team-wrapper {
      background: #bababa;
      padding: 5px;
      align-items: center;
    }

    .league-logo {
      width: 15%;
      background: #000000;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      .league-image {
        max-width: 65%;
      }
    }

    .swiper {
      width: 90%;

      &-slide {
        &:nth-child(4n + 1) {
          .flex-container {
            &:nth-child(2) {
              background: #f75f00;
            }

            &:nth-child(3) {
              background: #e2ff00;
            }
          }
        }

        &:nth-child(4n + 2) {
          .flex-container {
            &:nth-child(2) {
              background: #63c5ff;
            }

            &:nth-child(3) {
              background: #eab0ee;
            }
          }
        }

        &:nth-child(4n + 3) {
          .flex-container {
            &:nth-child(2) {
              background: #bababa;
            }

            &:nth-child(3) {
              background: #eeffd8;
            }
          }
        }

        &:nth-child(4n + 4) {
          .flex-container {
            &:nth-child(2) {
              background: #37d69e;
            }

            &:nth-child(3) {
              background: #ff0028;
            }
          }
        }
      }

      .result {
        background: #fff;
        padding: 10px;
        height: 160px;

        .flex-container {
          display: flex;
          justify-content: space-between;
          line-height: 1;

          &:not(:last-child) {
            margin-bottom: 10px;
          }

          .a {
            text-decoration: none;
            font-family: DrukWide, Sans-serif;
            font-size: 18px;
            text-transform: uppercase;
          }

          .league,
          .round {
            font-family: DrukWide, Sans-serif;
            font-size: 10px;
            text-transform: uppercase;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .team {
            display: flex;
            align-items: center;
            gap: 5px;

            &-logo {
              max-width: 30px;
              height: 30px;
            }

            &-name {
              font-family: DrukWide, Sans-serif;
              font-size: 18px;
              text-transform: uppercase;
            }
          }

          .score {
            font-family: DrukWide, Sans-serif;
            font-size: 18px;
            text-transform: uppercase;
            text-decoration: none;
          }

          .date {
            font-family: DrukWide, Sans-serif;
            font-size: 10px;
            text-transform: uppercase;
          }

          .view {
            font-family: DrukWide, Sans-serif;
            font-size: 10px;
            text-transform: uppercase;
            text-decoration: none;
            color: #000;

            &.live {
              color: red;
              position: relative;
              padding-left: 15px;

              &:before {
                content: "";
                position: absolute;
                background: red;
                width: 10px;
                height: 10px;
                left: 0;
                top: -1px;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1249px) {
  .league-logo {
    display: none !important;
  }

  .swiper {
    width: 100% !important;
  }

  .swiper.swiper-latest-results {
    width: calc(100% - 120px) !important;
  }

  .latest-results .flex-container .swiper .swiper-button-prev {
    left: 0;
    padding: 30px;
  }

  .latest-results .flex-container .swiper .swiper-button-next {
    right: 0;
    padding: 30px;
  }
}
</style>
