<template>
  <HomeLatestResults :slides="latestResults" />
  <HomePlayoffBracket :playoffBracket="playoffBracket" />
  <HomeHero :slides="heroSlides" />
  <!--  <banner />-->
  <LatestNews @latestNewsLoaded="loaders.latestNews = true" />
  <HomeStandings :standings="standings" v-if="isStandingsVisible" />

  <div class="individual-statistics-wrapper">
    <IndividualStatistics
      :show-header="false"
      :is-home-component="true"
      @statisticsLoaded="loaders.individualStatistics = true"
    />
  </div>

  <VideoHighlights @videoHighlightsLoaded="loaders.videoHighlights = true" @play-video="showVideoModal($event)" />
  <OurPartners @partnersLoaded="loaders.partners = true" />
  <HomeBnxtTv />

  <VideoModal :video-id="currentVideoId" @close="closeVideoModal" v-if="isVideoModalVisible" />
</template>

<script>
import HomeHero from "@/views/home/home-hero";
import OurPartners from "@/components/shared/our-partners";
import HomeBnxtTv from "@/views/home/home-bnxt-tv";
import LatestNews from "@/components/shared/latest-news";
import VideoHighlights from "@/components/shared/video-highlights";
import newsAndVideosService from "@/services/NewsAndVideosService";
import VideoModal from "@/components/shared/modals/video-modal";
import misc from "@/core/helpers/misc";
import HomeLatestResults from "@/views/home/home-latest-results";
import HomePlayoffBracket from "@/views/home/home-playoff-bracket";
import IndividualStatistics from "@/views/statistics/individual-statistics";
import scheduleService from "@/services/ScheduleService";
import phaseService from "@/services/PhaseService";
import HomeStandings from "@/views/home/home-standings";
import bannersService from "@/services/BannersService";
import standingsService from "@/services/StandingsService";
// import banner from "./banner.vue";

export default {
  name: `Home`,
  components: {
    HomeStandings,
    IndividualStatistics,
    HomeLatestResults,
    HomePlayoffBracket,
    HomeHero,
    OurPartners,
    HomeBnxtTv,
    LatestNews,
    VideoHighlights,
    VideoModal,
    // banner,
  },

  data() {
    return {
      heroSlides: [],
      latestResults: [],
      actualSeason: process.env.VUE_APP_ACTIVE_SEASON,
      standings: {
        belgium: {
          name: null,
          standings: [],
        },
        netherlands: {
          name: null,
          standings: [],
        },
      },
      isStandingsVisible: false,
      currentVideoId: String,
      isVideoModalVisible: false,
      loaders: {
        heroSlides: false,
        latestResults: false,
        standings: false,
        individualStatistics: false,
        latestNews: false,
        videoHighlights: false,
        partners: false,
      },
      misc: misc,
      playoffBracket: null,
    };
  },

  mounted() {
    this.getHeroSlides();
    this.getLatestResults();
    this.getOverallStandings(this.actualSeason, true);
    this.getPlayoffBracket();
  },

  methods: {
    async getHeroSlides() {
      this.heroSlides = await newsAndVideosService.getSliderArticles();
      this.loaders.heroSlides = true;
    },

    async getLatestResults() {
      await phaseService.getPhases(process.env.VUE_APP_ACTIVE_SEASON).then(async (res) => {
        this.latestResults = await scheduleService.getLatestResults(res[0].competition.id);
        this.loaders.latestResults = true;
      });
    },

    async getOverallStandings(season, isStandings) {
      const phasesComp = await phaseService.getPhases(season, isStandings);
      this.isStandingsVisible = phasesComp[0]?.competition.standings_visible;

      if (this.isStandingsVisible) {
        this.standings.netherlands.name = phasesComp[0]?.phases[0]?.name;
        this.standings.belgium.name = phasesComp[0]?.phases[1]?.name;

        this.standings.netherlands.standings = await this.getStandings(
          phasesComp[0].competition.id,
          phasesComp[0].phases[0].id
        );
        this.standings.belgium.standings = await this.getStandings(
          phasesComp[0].competition.id,
          phasesComp[0].phases[1].id
        );
        this.loaders.standings = true;
      }
    },

    async getStandings(competitionId, phaseId) {
      return await standingsService.getStandingsByCompetitionId(competitionId, phaseId);
    },

    async getPlayoffBracket() {
      await bannersService.getBannerList().then((res) => {
        res.forEach((item) => {
          if (item.position === "playoff_bracket") {
            this.playoffBracket = item;
          }
        });
      });
    },

    showVideoModal(videoUrl) {
      this.currentVideoId = misc.getVideoId(videoUrl);
      this.isVideoModalVisible = true;
    },

    closeVideoModal() {
      this.currentVideoId = null;
      this.isVideoModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.individual-statistics-wrapper {
  padding: 0 8%;
}
</style>
