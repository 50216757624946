<template>
  <div class="team-calendar">
    <slot v-if="!isMobileMode"><CalendarTable :schedules="orderedSchedules" /></slot>
    <slot v-else><CalendarTableMobile :schedules="orderedSchedules" /></slot>
    <div v-if="!schedules.length">{{ $t("no_players") }}</div>
  </div>
</template>

<script>
import CalendarTable from "@/views/calendar/calendar-table";
import CalendarTableMobile from "@/views/calendar/calendar-table-mobile";

export default {
  name: "team-calendar",
  components: { CalendarTable, CalendarTableMobile },
  props: {
    schedules: null,
  },

  data() {
    return {
      isMobileMode: window.innerWidth <= 1000,
    };
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  methods: {
    onResize() {
      this.isMobileMode = window.innerWidth <= 1000;
    },
  },

  computed: {
    orderedSchedules() {
      return [...this.schedules].sort((a, b) => {
        return new Date(a.game_time) - new Date(b.game_time);
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
