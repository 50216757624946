import axios from "axios";

export default {
  getReferees() {
    const params = { title_id: 2 };
    return axios.get(process.env.VUE_APP_API_URL + "staff/list", { params }).then((response) => {
      return response.data.data;
    });
  },
};
