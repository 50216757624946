import axios from "axios";

export default {
  getCompetitionTeam(id) {
    return axios.get(process.env.VUE_APP_API_URL + "competition-team/find/" + id).then((response) => {
      return response.data.data;
    });
  },

  getPlayerAverages(competitionId) {
    return axios
      .get(process.env.VUE_APP_API_URL + "competition-team/player-averages/" + competitionId)
      .then((response) => {
        return response.data.data;
      });
  },

  getStatisticsByGame(competitionId) {
    return axios
      .get(process.env.VUE_APP_API_URL + "competition-team/statistics-by-game/total/" + competitionId)
      .then((response) => {
        return response.data.data;
      });
  },
};
