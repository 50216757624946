<template>
  <div class="latest-news-by-team">
    <h2 class="section-title">{{ $t("home.latest_news_title") }}</h2>

    <div class="swiper">
      <swiper
        :modules="modules"
        :space-between="20"
        :navigation="{
          nextEl: '.swiper-button-next.latest-news-swiper-nav',
          prevEl: '.swiper-button-prev.latest-news-swiper-nav',
        }"
        :pagination="{ clickable: true }"
        :breakpoints="breakpoints"
        :slides-offset-after="180"
        class="swiper-latest-news"
      >
        <swiper-slide v-for="item of latestNews" :key="item">
          <NewsBox :article="item" :is-description-visible="false" />
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev latest-news-swiper-nav"><font-awesome-icon icon="fa-solid fa-angle-left" /></div>
      <div class="swiper-button-next latest-news-swiper-nav"><font-awesome-icon icon="fa-solid fa-angle-right" /></div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
import NewsBox from "@/views/news-and-videos/news-box";
import searchService from "@/services/SearchService";

export default {
  name: "latest-news-by-team",
  components: { Swiper, SwiperSlide, NewsBox },
  props: {
    teamName: null,
  },

  data() {
    return {
      latestNews: [],
      breakpoints: {
        1200: {
          slidesPerView: 3.5,
          slidesOffsetAfter: 180,
        },
        767: {
          slidesPerView: 2.2,
          slidesOffsetAfter: 120,
        },
        1: {
          slidesPerView: 1.055,
          slidesOffsetAfter: 30,
        },
      },
    };
  },

  mounted() {},

  watch: {
    teamName(teamName) {
      this.getSearchResultsByKeyword(teamName);
    },
  },

  methods: {
    async getSearchResultsByKeyword(teamName) {
      const results = await searchService.getSearchResultsByKeyword({ per_page: 10, keyword: teamName });
      this.latestNews = results.articles.data;
      this.$emit("latestNewsLoaded");
    },
  },

  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped lang="scss">
.latest-news-by-team {
  padding-bottom: 4%;

  .section-title {
    color: #000;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 80px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
    padding: 4% 8% 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    font-size: 30px;
    color: #fff;
    background-color: #6441ff;
    border-radius: 100px 100px 100px 100px;
    box-shadow: 0 5px 20px 0 rgb(100 65 255 / 52%);
    padding: 30px;
    top: calc(50% - 30px);
  }

  .swiper-button-prev {
    left: 6%;
  }

  .swiper-button-next {
    right: 6%;
  }

  :deep(.swiper-wrapper) {
    padding-left: 8%;
    padding-bottom: 50px;
  }

  :deep(.swiper-button-prev:after),
  :deep(.swiper-button-next:after) {
    content: none;
  }

  :deep(.swiper-pagination-bullet) {
    margin: 0 6px !important;
    background: #000;
    width: 6px;
    height: 6px;
  }
}

@media only screen and (max-width: 1200px) {
  .latest-news {
    .section-title {
      font-size: 50px;
      padding: 4%;
    }

    .swiper-button-prev {
      left: 3%;
    }

    .swiper-button-next {
      right: 3%;
    }

    :deep(.swiper-wrapper) {
      padding-left: 4%;
    }
  }
}

@media only screen and (max-width: 767px) {
  :deep(.swiper-wrapper) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>
