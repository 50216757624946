import axios from "axios";

export default {
  getSeasons() {
    return axios
      .get(process.env.VUE_APP_API_URL + "competition-team/club/seasons", {
        params: {
          "clubs[0]": 1,
          "clubs[1]": 2,
        },
      })
      .then((response) => {
        return response.data.data.sort((a, b) => b.season - a.season);
      });
  },
};
