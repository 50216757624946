<template>
  <div class="flex-container">
    <h3>{{ $t("statistics.individual." + type) }}</h3>
    <a href="javascript:void(0)" class="go-back-btn" @click="$emit('viewLeaderboard')">{{ $t("view-leaderboard") }}</a>
  </div>
  <div class="table-wrapper">
    <table class="table table-bordered bnxt-table">
      <thead>
        <tr>
          <th>#</th>
          <th>{{ $t("photo") }}</th>
          <th>{{ $t("name") }}</th>
          <th>{{ $t("statistics.individual." + type) }}</th>
          <th v-if="isThrow(type)">{{ $t("statistics.individual.made_all") }}</th>
          <th>{{ $t("team_name") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr height="16" v-for="(player, index) in leaderboard[type]?.slice(0, 75)">
          <td>{{ index + 1 }}</td>
          <td>
            <router-link
              :to="{
                name: misc.getRouteNameByLocale($router.options.routes, 'Player-profile'),
                query: { player_id: player.player.id, team_id: player.team.id },
              }"
            >
              <div class="player-img">
                <img
                  :src="player.player.profile_image.thumbnails.small"
                  v-if="player?.player?.profile_image?.thumbnails?.small"
                />
                <img src="../../../assets/img/image_not_found.png" v-else />
              </div>
            </router-link>
          </td>
          <td>
            <router-link
              :to="{
                name: misc.getRouteNameByLocale($router.options.routes, 'Player-profile'),
                query: { player_id: player.roster_id, team_id: player.team.id },
              }"
            >
              {{ player.player.first_name }} {{ player.player.last_name?.toUpperCase() }}
            </router-link>
          </td>
          <td>{{ misc.formatNumber(player.value) }}</td>
          <td v-if="isThrow(type)">
            {{ misc.formatNumber(player.throw_made) }} / {{ misc.formatNumber(player.throw_attempted) }}
          </td>
          <td>{{ player.team.name }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import statisticsService from "@/services/StatisticsService";
import misc from "@/core/helpers/misc";

export default {
  name: "individual-leaderboard",
  props: {
    type: String,
    filters: {
      competitionId: null,
      phase: null,
      month: null,
    },
  },

  data() {
    return {
      leaderboard: [],
      misc: misc,
      loaders: {
        playerStatistics: false,
      },
    };
  },

  mounted() {
    this.getPlayerStatistics(this.filters.competitionId, { type: this.$props.type, phase_id: this.filters.phase });
  },

  methods: {
    async getPlayerStatistics(competitionId, params) {
      this.leaderboard = await statisticsService.getPlayerStatistics(competitionId, params);
      this.loaders.playerStatistics = true;
    },
    isThrow(type) {
      return type === "two_point_percent" || type === "three_point_percent" || type === "free_throw_percent";
    },
  },

  watch: {
    filters(filters) {
      this.loaders.playerStatistics = false;
      this.getPlayerStatistics(filters.competitionId, {
        type: this.$props.type,
        phase_id: filters.phase,
        month: filters.month > 0 ? filters.month : null,
      });
    },
    type(type) {
      console.log(type);
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  color: #000;
  font-family: DrukWideBoldItalic, Sans-serif;
  font-size: 56px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3em;
  display: inline-block;
  width: 55%;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .go-back-btn {
    padding-left: 35px;
    background-image: url("../../../assets/img/icons/back-arrow.png");
    background-repeat: no-repeat;
    background-position: top 10px left;
    background-size: 25px;
    color: #000;
    text-transform: uppercase;
    font-size: 26px;
    font-family: DrukWideBoldItalic, Sans-serif;
    text-decoration: none;
  }
}

table {
  thead {
    tr {
      th {
        border: 0 !important;
        text-align: center;
      }
    }
  }

  tbody {
    td {
      padding: 0 !important;

      .player-img {
        text-align: center;
        padding: 10px 0 0 !important;

        img {
          width: 45px;
        }
      }
    }
  }
}
</style>
