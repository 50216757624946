<template>
  <div class="bnxt-tv">
    <div class="running-text-wrapper">
      <div class="running-text original">{{ $t("home.bnxt_tv.running_text") }}</div>
      <div class="running-text copy">{{ $t("home.bnxt_tv.running_text") }}</div>
    </div>

    <div class="flex-container">
      <div class="left">
        <img src="../../assets/img/C-BNXTTV_logo_white.png" alt="BNXT.TV" class="img-fluid" />
        <h4>{{ $t("home.bnxt_tv.title") }}</h4>
        <p>{{ $t("home.bnxt_tv.description") }}</p>
        <a href="https://www.bnxt.tv/" target="_blank" class="btn">{{ $t("home.bnxt_tv.button") }}</a>
      </div>
      <div class="right">
        <img src="../../assets/img/bnx_bnxt-tv-min.png" alt="" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home-bnxt-tv",
};
</script>

<style scoped lang="scss">
.bnxt-tv {
  background: #000;
  padding: 10% 0;

  .running-text-wrapper {
    overflow: hidden;
    position: relative;
    display: flex;
    white-space: nowrap;

    .running-text {
      font-family: DrukWide, Sans-serif;
      font-size: 100px;
      text-transform: uppercase;
      line-height: 1.3em;
      -webkit-text-stroke-color: #e2ff00;
      -webkit-text-stroke-width: 2px;
      color: transparent;

      &.original {
        /* animation properties */
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);

        -moz-animation: text-slide 20s linear infinite;
        -webkit-animation: text-slide 20s linear infinite;
        animation: text-slide 20s linear infinite;
      }
      &.copy {
        /* animation properties */
        -moz-transform: translateX(100%);
        -webkit-transform: translateX(100%);
        transform: translateX(100%);

        -moz-animation: text-slide-copy 20s linear infinite;
        -webkit-animation: text-slide-copy 20s linear infinite;
        animation: text-slide-copy 20s linear infinite;

        position: absolute;
        top: 0;
        left: 50px;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }
    }
  }

  .flex-container {
    display: flex;
    padding: 0 8%;

    .left {
      width: 55%;
      padding-right: 12%;

      img {
        width: 400px;
        margin: 10px 0 20px;
      }

      h4 {
        color: #fff;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 42px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1em;
        margin-bottom: 30px;
      }

      p {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        line-height: 1.3em;
        margin-bottom: 10%;
      }

      .btn {
        font-family: DrukWideItalic, Sans-serif;
        font-size: 30px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1.3em;
        color: #fff;
        border-width: 0;
        border-radius: 100px 100px 100px 100px;
        padding: 15px 40px 12px;
        text-decoration: none;
        background: #f75f00;

        &:hover {
          background: #e2ff00;
          color: #003c80;
        }
      }
    }

    .right {
      width: 45%;
      text-align: center;
      background: url("../../assets/img/bnxt-blob-purple-1024x966.png") no-repeat 0 0;
      background-size: 90%;
      padding-bottom: 25px;
      top: -80px;
      position: relative;
      margin-bottom: -80px;

      img {
        width: 100%;
        max-width: 650px;
      }
    }
  }

  /* for Firefox */
  @-moz-keyframes text-slide {
    from {
      -moz-transform: translateX(0);
    }
    to {
      -moz-transform: translateX(-100%);
    }
  }

  /* for Chrome */
  @-webkit-keyframes text-slide {
    from {
      -webkit-transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(-100%);
    }
  }

  @keyframes text-slide {
    from {
      -moz-transform: translateX(0);
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
    to {
      -moz-transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
    }
  }

  /* for Firefox */
  @-moz-keyframes text-slide-copy {
    from {
      -moz-transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(0);
    }
  }

  /* for Chrome */
  @-webkit-keyframes text-slide-copy {
    from {
      -webkit-transform: translateX(100%);
    }
    to {
      -webkit-transform: translateX(0);
    }
  }

  @keyframes text-slide-copy {
    from {
      -moz-transform: translateX(100%);
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
    to {
      -moz-transform: translateX(0);
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .bnxt-tv {
    .running-text-wrapper {
      .running-text {
        font-size: 60px;
      }
    }

    .flex-container {
      flex-direction: column-reverse;
      align-items: center;
      padding: 40px 50px 0;

      .left {
        width: 100%;
        padding: 0;
        text-align: center;

        img {
          width: 300px;
        }

        h4 {
          font-size: 50px;
        }

        p {
          font-size: 16px;
          margin-bottom: 15px;
        }

        .btn {
          font-size: 24px;
          padding: 12px 30px 10px;
        }
      }

      .right {
        width: 500px;
        margin: 45px 0 -80px;
        padding: 0;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .bnxt-tv {
    .flex-container {
      padding: 10px 20px;

      .left {
        img {
          width: 200px;
        }

        h4 {
          font-size: 35px;
          margin-bottom: 20px;
        }

        .btn {
          padding: 10px 25px 8px;
        }
      }

      .right {
        width: 100%;
      }
    }
  }
}
</style>
