import axios from "axios";

export default {
  getArticles(params) {
    return axios.get(process.env.VUE_APP_API_URL + "article/list", { params }).then((response) => {
      return response.data.data;
    });
  },

  getSliderArticles() {
    const params = {
      "filters[highlight]": "slider",
    };

    return axios.get(process.env.VUE_APP_API_URL + "article/list", { params }).then((response) => {
      return response.data.data;
    });
  },

  getNews(params) {
    return axios.get(process.env.VUE_APP_API_URL + "article/list", { params: params }).then((response) => {
      return response.data;
    });
  },

  getVideosForNewsPage(params) {
    return axios.get(process.env.VUE_APP_API_URL + "video/list", { params: params }).then((response) => {
      return response.data;
    });
  },

  searchVideos(params) {
    return axios.get(process.env.VUE_APP_API_URL + "video/search", { params: params }).then((response) => {
      return response.data;
    });
  },

  searchNews(params) {
    return axios.get(process.env.VUE_APP_API_URL + "article/search", { params: params }).then((response) => {
      return response.data;
    });
  },

  getArticleBySlug(slug) {
    return axios.get(process.env.VUE_APP_API_URL + "article/find/" + slug).then((response) => {
      return response.data.data;
    });
  },

  getVideos() {
    return axios.get(process.env.VUE_APP_API_URL + "video/list", { params: { per_page: 15 } }).then((response) => {
      return response.data.data;
    });
  },
};
