<template>
  <div class="parallax">
    <p ref="bnxt">BNXT</p>
    <p ref="unites">UNITES</p>
    <p ref="empowers">EMPOWERS</p>
    <p ref="cares">CARES</p>
    <p ref="never">NEVER</p>
    <p ref="stops">STOPS</p>
  </div>
</template>

<script>
export default {
  name: "about-parallax",
  data() {
    return {
      show: false,
    };
  },

  mounted() {
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      Object.keys(this.$refs).forEach((el) => {
        if (
          this.$refs[el].getBoundingClientRect().top - window.innerHeight <= 0 &&
          this.$refs[el].getBoundingClientRect().bottom - 200 > 0
        ) {
          this.$refs[el].style.opacity = -(this.$refs[el].getBoundingClientRect().top - window.innerHeight) / 200;
        } else if (this.$refs[el].getBoundingClientRect().bottom - window.innerHeight <= 0) {
          this.$refs[el].style.opacity = (-1 - (this.$refs[el].getBoundingClientRect().bottom - 200) / 100) * -1;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.parallax {
  background-color: #000;
  padding: 100px 0;

  p {
    color: #e3ff00;
    font-family: DrukWide, Sans-serif;
    font-size: 120px;
    font-weight: 400;
    text-transform: uppercase;
    font-style: normal;
    line-height: 1em;
    text-align: center;
    margin: 20px 0 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .parallax {
    p {
      font-size: 50px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .parallax {
    p {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }
}
</style>
