<template>
  <AppLoader v-if="!misc.isDataLoaded(loaders) && !isHomeComponent" />

  <div class="team-filter-wrapper" v-show="showHeader">
    <h3 v-if="!selectedLeaderboard">{{ $t("statistics.individual") }}</h3>
    <TeamFilter :is-overall="true" @filters="filtersChanged($event)" />
  </div>

  <slot v-if="!selectedLeaderboard && blocks.assist?.length > 0">
    <h2 class="home-component-title" v-if="isHomeComponent">{{ $t("leaderboard") }}</h2>

    <div class="grid-container" v-if="blocks.assist?.length">
      <div class="stat-box-wrapper" v-for="(block, index) in blocks">
        <StatisticsBox :block="block" :title="index" @viewAll="selectedLeaderboard = index" />
      </div>
    </div>

    <div class="mt-5">
      <div v-if="!blocks.assist?.length">{{ $t("statistics.no-data") }}</div>
    </div>
  </slot>

  <div class="leaderboard" v-if="selectedLeaderboard && blocks.assist?.length > 0">
    <h2 class="home-component-title" v-if="isHomeComponent">{{ $t("leaderboard") }}</h2>

    <IndividualLeaderboard
      :type="selectedLeaderboard"
      :filters="leaderboardFilters"
      @viewLeaderboard="selectedLeaderboard = null"
    />
  </div>
</template>

<script>
import TeamFilter from "@/components/simple/filters/team-filter/TeamFilter";
import statisticsService from "@/services/StatisticsService";
import StatisticsBox from "@/views/statistics/shared/statistics-box";
import IndividualLeaderboard from "@/views/statistics/shared/individual-leaderboard";
import misc from "@/core/helpers/misc";
import AppLoader from "@/components/shared/loader/AppLoader";

export default {
  name: "individual-statistics",
  components: { AppLoader, IndividualLeaderboard, TeamFilter, StatisticsBox },
  props: {
    showHeader: {
      default: true,
      type: Boolean,
    },
    isHomeComponent: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      selectedLeaderboard: null,
      blocks: [],
      leaderboardFilters: {
        competitionId: null,
        phase: null,
      },
      loaders: {
        teamStatistics: false,
      },
      misc: misc,
    };
  },
  methods: {
    async getPlayerStatistics(competitionId, phaseId, season, month) {
      try {
        this.blocks = await statisticsService.getPlayerStatistics(competitionId, {
          phase_id: this.isHomeComponent ? null : phaseId,
          season,
          month: month > 0 ? month : null,
          type: "all",
          limit: 5,
        });
        this.$emit("statisticsLoaded");
        this.loaders.teamStatistics = true;
      } catch (e) {
        console.log(e);
        this.loaders.teamStatistics = false;
      }
    },

    filtersChanged(filters) {
      this.leaderboardFilters = {
        phase: filters.phase,
        competitionId: filters.competitionId,
        month: filters.month,
      };

      if ((filters.season || filters.month) && !this.selectedLeaderboard) {
        this.loaders.teamStatistics = false;
        this.getPlayerStatistics(filters.competitionId, filters.phase, filters.season, filters.month);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.team-filter-wrapper {
  display: flex;
  margin-bottom: 60px;

  :deep(.flex-container) {
    max-width: 1030px;
  }

  h3 {
    color: #000;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
    display: inline-block;
    width: 55%;
  }
}

.home-component-title {
  color: #000;
  font-family: DrukWideBoldItalic, Sans-serif;
  font-size: 80px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3em;
  margin: 60px 0 10px;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  gap: 20px;
}

@media only screen and (max-width: 980px) {
  .team-filter-wrapper {
    flex-direction: column;

    h3 {
      font-size: 40px;
    }
  }

  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  }
}

@media only screen and (max-width: 640px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
</style>
