<template>
  <div class="dream-team">
    <img src="../../assets/img/awards/bnx_comet-big-red-min-979x1024.png" class="background-img-left" />
    <img src="../../assets/img/awards/bnx_comet-small-blue-min-979x1024.png" class="background-img-right" />

    <div class="header">
      <h2 class="title">{{ awardCategory?.title }}</h2>
      <p class="description">{{ awardCategory?.description }}</p>
    </div>

    <div class="content">
      <div class="flex-container">
        <div class="player-wrapper" v-for="player in awardCategory?.rosters?.slice(0, 3)">
          <AwardsPlayerCard
            :player="player"
            :is-active="player?.is_winner === 1"
            background-color="#000"
            background-image="bnx_bg-mvp-bubble-min.png"
            category-image="BNXT_Awards-winner-dreamteam-min.png"
            :box-shadow="'green'"
          />
        </div>
        <div class="player-wrapper" v-for="player in getPlaceholderRowCount(awardCategory, 0)">
          <AwardsPlayerCard
            :player="null"
            :is-active="false"
            background-color="#000"
            background-image="bnx_bg-mvp-bubble-min.png"
            category-image="BNXT_Awards-winner-dreamteam-min.png"
            :box-shadow="'green'"
          />
        </div>
      </div>

      <div class="flex-container">
        <div class="player-wrapper" v-for="player in awardCategory?.rosters?.slice(3, 5)">
          <AwardsPlayerCard
            :player="player"
            :is-active="player?.is_winner === 1"
            background-color="#000"
            background-image="bnx_bg-mvp-bubble-min.png"
            category-image="BNXT_Awards-winner-dreamteam-min.png"
            :box-shadow="'green'"
          />
        </div>
        <div class="player-wrapper" v-for="player in getPlaceholderRowCount(awardCategory, 1)">
          <AwardsPlayerCard
            :player="null"
            :is-active="false"
            background-color="#000"
            background-image="bnx_bg-mvp-bubble-min.png"
            category-image="BNXT_Awards-winner-dreamteam-min.png"
            :box-shadow="'green'"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="section-filler"></div>
</template>

<script>
import AwardsPlayerCard from "@/views/awards/awards-player-card";

export default {
  name: "awards-bnxt-dream-team",
  components: { AwardsPlayerCard },
  props: {
    awardCategory: null,
  },
  methods: {
    getPlaceholderCount(awardCategory) {
      let length = 0;
      if (awardCategory) {
        length = awardCategory.rosters.length;
        return awardCategory.placeholder_count - length > 0 ? awardCategory.placeholder_count - length : 0;
      }
      return 0;
    },
    getPlaceholderRowCount(awardCategory, index) {
      if (awardCategory) {
        let count = awardCategory.placeholder_count;
        let firstRow = count > 3 ? 3 : count;
        let secondRow = count > 3 ? count - 3 : 0;
        let length = 0;

        length = awardCategory.rosters.length;
        if (length > count) {
          length = count;
        }

        if (index === 0) {
          return firstRow - (length > 3 ? 3 : length);
        } else if (index === 1) {
          return secondRow - (length > 3 ? length - 3 : 0);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dream-team {
  position: relative;
  padding: 6% 8% 0;
  background: #003c80;

  .background-img-left {
    position: absolute;
    top: -450px;
    left: 25%;
    width: 600px;
  }

  .background-img-right {
    position: absolute;
    top: 25%;
    right: 25%;
    width: 400px;
  }

  .header {
    display: flex;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;

    .title {
      color: #fff;
      font-family: DrukWide, Sans-serif;
      font-size: 4vw;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.85em;
      width: 50%;
      padding-right: 10px;
    }

    .description {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.4em;
      width: 50%;
    }
  }

  .content {
    display: flex;
    gap: 70px;
    flex-direction: column;

    .flex-container {
      display: flex;
      gap: 70px;
      justify-content: center;

      .player-wrapper {
        width: 33.33%;
        max-width: 487px;
      }
    }
  }
}

.section-filler {
  height: 320px;
  background-image: linear-gradient(180deg, #003c80 0%, #3264ff 100%);
}

@media only screen and (max-width: 1200px) {
  .dream-team {
    padding: 4%;

    .background-img-left {
      width: 400px;
      top: -300px;
      left: 50px;
    }

    .header {
      .title {
        font-size: 6vw;
      }
      .description {
        font-size: 16px;
      }
    }
  }

  .section-filler {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .dream-team {
    .background-img-left {
      top: -200px;
      max-width: calc(90vw - 50px);
    }

    .background-img-right {
      top: 0;
      max-width: calc(90vw - 50px);
    }

    .header {
      flex-direction: column;
      .title {
        font-size: 24px;
      }
      .description {
        font-size: 14px;
        width: 100%;
      }
    }

    .content {
      .flex-container {
        flex-direction: column;

        .player-wrapper {
          width: 100%;
          max-width: none;
        }
      }
    }
  }

  .section-filler {
    height: 170px;
  }
}
</style>
