<template>
  <div class="coach-of-the-year">
    <img src="../../assets/img/awards/bnx_comet-big-purple-min-979x1024.png" class="background-img-left" />
    <img src="../../assets/img/awards/bnx_comet-small-yellow-min-979x1024.png" class="background-img-right" />

    <div class="header">
      <h2 class="title">{{ awardCategoryBelgium?.title }}</h2>
      <p class="description">{{ awardCategoryBelgium?.description }}</p>
    </div>

    <div class="content">
      <div class="country">
        <div class="country-name">
          <img src="../../assets/img/flags/bnx_belgium.svg" />
          Belgium
        </div>
        <div class="grid-container">
          <slot v-for="player in awardCategoryBelgium?.staff">
            <AwardsPlayerCard
              :player="player"
              :is-active="player?.is_winner === 1"
              background-color="#ffe15a"
              background-image="BNXT_bubble.png"
              category-image="BNXT_Awards-winner-coach-min.png"
              :box-shadow="'gray'"
            />
          </slot>
          <slot v-for="player in getPlaceholderCount(awardCategoryBelgium)">
            <AwardsPlayerCard
              :player="null"
              :is-active="false"
              background-color="#3264ff"
              background-image="BNXT_bubble.png"
              category-image="BNXT_Awards-winner-coach-min.png"
              :box-shadow="'gray'"
            />
          </slot>
        </div>
      </div>

      <div class="country">
        <div class="country-name">
          <img src="../../assets/img/flags/bnx_netherlands.svg" />
          Netherlands
        </div>
        <div class="grid-container">
          <slot v-for="player in awardCategoryNetherlands?.staff">
            <AwardsPlayerCard
              :player="player"
              :is-active="player?.is_winner === 1"
              background-color="#3264ff"
              background-image="BNXT_bubble.png"
              category-image="BNXT_Awards-winner-coach-min.png"
              :box-shadow="'light-green'"
            />
          </slot>
          <slot v-for="player in getPlaceholderCount(awardCategoryNetherlands)">
            <AwardsPlayerCard
              :player="null"
              :is-active="false"
              background-color="#3264ff"
              background-image="BNXT_bubble.png"
              category-image="BNXT_Awards-winner-coach-min.png"
              :box-shadow="'light-green'"
            />
          </slot>
        </div>
      </div>
    </div>
  </div>

  <div class="section-filler"></div>
</template>

<script>
import AwardsPlayerCard from "@/views/awards/awards-player-card";

export default {
  name: "awards-coach-of-the-year",
  components: { AwardsPlayerCard },
  props: {
    awardCategoryBelgium: null,
    awardCategoryNetherlands: null,
  },
  methods: {
    getPlaceholderCount(awardCategory) {
      let length = 0;
      if (awardCategory) {
        length = awardCategory.staff.length;
        return awardCategory.placeholder_count - length > 0 ? awardCategory.placeholder_count - length : 0;
      }
      return 0;
    },
  },
};
</script>

<style scoped lang="scss">
.coach-of-the-year {
  background-color: #37d69e;
  padding: 6% 8% 0;
  position: relative;

  .background-img-left {
    position: absolute;
    top: -450px;
    width: 900px;
  }

  .background-img-right {
    position: absolute;
    width: 500px;
    right: 15%;
    top: 15%;
  }

  .header {
    display: flex;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;

    .title {
      color: #fff;
      font-family: DrukWide, Sans-serif;
      font-size: 4vw;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 0.85em;
      width: 50%;
    }

    .description {
      color: #fff;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.4em;
      width: 50%;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 70px;
    position: relative;
    z-index: 1;

    .country {
      &-name {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 20px;
        font-family: DrukWideBoldItalic;
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1em;
        color: #fff;

        img {
          width: 50px;
        }
      }

      .grid-container {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 70px;
      }
    }
  }
}

.section-filler {
  height: 320px;
  background-image: linear-gradient(180deg, #37d69e 0%, #b9f569 100%);
}

@media only screen and (max-width: 1200px) {
  .coach-of-the-year {
    padding: 4%;

    .background-img-left {
      width: 400px;
      top: -300px;
      left: 50px;
    }

    .header {
      .title {
        font-size: 6vw;
      }

      .description {
        font-size: 16px;
      }
    }

    .content {
      .country {
        &-name {
          gap: 10px;
          font-size: 24px;

          img {
            width: 30px;
          }
        }
      }
    }
  }

  .section-filler {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  .coach-of-the-year {
    .background-img-left {
      top: -200px;
      max-width: calc(90vw - 50px);
    }

    .background-img-right {
      top: 0;
      max-width: calc(90vw - 50px);
    }

    .header {
      flex-direction: column;

      .title {
        font-size: 24px;
        margin-bottom: 20px;
        width: 100%;
      }

      .description {
        font-size: 14px;
        width: 100%;
      }
    }

    .content {
      .country {
        .grid-container {
          display: grid;
          grid-template-columns: auto;
          gap: 70px;
        }
      }
    }
  }

  .section-filler {
    height: 170px;
  }
}
</style>
