<template>
  <AppLoader v-if="!misc.isDataLoaded(loaders)" />
  <div class="flex-container">
    <span class="filter-label">Filter</span>
    <div class="select-wrapper">
      <label>{{ $t("season") }}</label>
      <select v-model="filters.season" @change="changeSeason">
        <option :value="season.season" v-for="season in seasons">{{ season.season - 1 }} - {{ season.season }}</option>
      </select>
    </div>
  </div>
  <ul class="tabs" v-show="windowWidth <= 1200">
    <li :class="{ active: activeTab === 1 }" @click="onChangeTab(1)">
      <img src="../../assets/img/flags/bnx_belgium.svg" alt="Belgium" /><span>Belgium</span>
    </li>
    <li :class="{ active: activeTab === 2 }" @click="onChangeTab(2)">
      <img src="../../assets/img/flags/bnx_netherlands.svg" alt="Netherlands" /><span>Netherlands</span>
    </li>
  </ul>
  <div class="flex-container">
    <div class="club" v-show="(activeTab === 1 && windowWidth <= 1200) || windowWidth > 1200">
      <div class="club-header" v-show="windowWidth > 1200">
        <img src="../../assets/img/flags/bnx_belgium.svg" alt="Belgium" />
        <div class="club-country">Belgium</div>
      </div>
      <div class="team" v-for="item in competitions?.belgium">
        <img :src="item?.team_logo?.public_url" :alt="item?.team_logo?.alt" />
        <div class="team-name">
          <div class="short-name">{{ item?.short_name }}</div>
          <router-link
            :to="{ name: misc.getRouteNameByLocale($router.options.routes, 'Team'), query: { team_id: item?.id } }"
            class="long-name"
          >
            {{ item?.name }}
          </router-link>
        </div>
        <div class="buttons">
          <router-link
            :to="{
              name: misc.getRouteNameByLocale($router.options.routes, 'Team Statistics'),
              query: { team_id: item?.id },
            }"
          >
            {{ $t("statistics") }}
          </router-link>
          <a v-if="item.ticket_url" :href="item.ticket_url">Tickets</a>
        </div>
      </div>
    </div>

    <div class="club" v-show="(activeTab === 2 && windowWidth <= 1200) || windowWidth > 1200">
      <div class="club-header" v-show="windowWidth > 1200">
        <img src="../../assets/img/flags/bnx_netherlands.svg" alt="Netherlands" />
        <div class="club-country">Netherlands</div>
      </div>
      <div class="team" v-for="item in competitions?.netherlands">
        <img :src="item?.team_logo?.public_url" :alt="item?.team_logo?.alt" />
        <div class="team-name">
          <div class="short-name">{{ item?.short_name }}</div>
          <router-link
            :to="{ name: misc.getRouteNameByLocale($router.options.routes, 'Team'), query: { team_id: item?.id } }"
            class="long-name"
          >
            {{ item?.name }}
          </router-link>
        </div>
        <div class="buttons">
          <router-link
            :to="{
              name: misc.getRouteNameByLocale($router.options.routes, 'Team Statistics'),
              query: { team_id: item?.id },
            }"
          >
            {{ $t("statistics") }}
          </router-link>
          <a v-if="item.ticket_url" :href="item.ticket_url">Tickets</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";
import seasonService from "@/services/SeasonService";
import phaseService from "@/services/PhaseService";
import sportService from "@/services/SportService";
import AppLoader from "@/components/shared/loader/AppLoader";

export default {
  name: "teams-list",
  components: { AppLoader },
  props: {},

  data() {
    return {
      activeTab: 1,
      windowWidth: window.innerWidth,
      misc: misc,
      seasons: [],
      competitions: {},
      filters: {
        season: process.env.VUE_APP_ACTIVE_SEASON,
      },
      loaders: {
        teams: false,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.getSeasons();
    this.getTeamCompetitions();
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    onChangeTab(tabId) {
      this.activeTab = tabId;
    },
    async getSeasons() {
      this.seasons = await seasonService.getSeasons();
    },

    async getTeamCompetitions() {
      await phaseService.getPhases(this.filters.season).then(async (res) => {
        this.competitions = await sportService.getTeamCompetitions(res[0].competition.id);
        this.loaders.teams = true;
      });
    },
    changeSeason() {
      this.loaders.teams = false;
      this.getTeamCompetitions();
    },
  },
};
</script>

<style scoped lang="scss">
.tabs {
  padding: 0;
  list-style: none;
  background: #fff;
  margin: 0 auto;
  max-width: 100%;
  float: none;
  display: inline-block;
  border-radius: 100px;
  box-shadow: 0 5px 20px rgb(103 200 255 / 60%);
  width: 100%;
  margin-top: 10px;

  li {
    display: inline-block;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-weight: 600;
    font-size: 34px;
    padding: 10px 25px;
    border-radius: 50px;
    margin: -1px;
    text-transform: uppercase;
    width: 50%;
    text-align: center;
    line-height: 1;

    &.active {
      background: #3264ff;
      color: #fff;
    }

    & > * {
      position: relative;
      top: -4px;
    }

    img {
      width: 50px;
      margin-right: 12px;
    }

    span {
      vertical-align: sub;
    }
  }
}

.flex-container {
  display: flex;

  span.filter-label {
    font-family: DrukWideBoldItalic, Sans-serif;
    font-weight: 600;
    color: #000;
    min-width: 100px;
    font-size: 30px;
    text-transform: uppercase;
  }

  .select-wrapper {
    width: 300px;

    label {
      font-family: DrukWide;
      font-weight: 500;
      color: #000;
      font-size: 12px;
    }
  }
  .club {
    width: 50%;
    margin: 10px;
    padding: 30px 5px;
    border-radius: 12px;

    &:nth-child(1) {
      background: #003d80;

      .short-name {
        color: #6441ff;
      }

      .buttons {
        a {
          &:nth-child(1) {
            background-color: #6441ff;
            color: #fff;
            border-color: #6441ff;
          }

          &:nth-child(2) {
            color: #6441ff;
            border-color: #6441ff;
            transition: 0.3s;

            &:hover {
              background: #6441ff;
              color: #fff;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      background: #6441ff;

      .short-name {
        color: #f75f00;
      }

      .buttons {
        a {
          &:nth-child(1) {
            background-color: #f75f00;
            color: #fff;
            border-color: #f75f00;
          }

          &:nth-child(2) {
            color: #f75f00;
            border-color: #f75f00;
            transition: 0.3s;

            &:hover {
              background: #f75f00;
              color: #fff;
            }
          }
        }
      }
    }

    &-header {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-bottom: 35px;

      img {
        width: 80px;
      }

      .club-country {
        color: #fff;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 60px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1em;
      }
    }

    .team {
      box-shadow: 0 10px 30px 0 rgb(0 0 0 / 19%);
      margin: 5px 20px 15px;
      padding: 30px 3%;
      border-radius: 12px;
      background: #fff;
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 80px;
      }

      .short-name {
        margin-bottom: 10px;
        font-family: DrukWide, Sans-serif;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
      }

      .long-name {
        margin-bottom: 10px;
        color: #000;
        font-family: DrukWideItalic, Sans-serif;
        font-size: 32px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 0.9em;
      }

      .buttons {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;

        a {
          font-family: DrukWideBoldItalic, Sans-serif;
          font-size: 1.2rem;
          font-weight: 700;
          text-transform: uppercase;
          line-height: 1em;
          border-width: 2px;
          border-radius: 100px 100px 100px 100px;
          padding: 10px 28px;
          text-decoration: none;
          border-style: solid;
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .flex-container {
    margin-top: 10px;

    .club {
      width: 100%;

      &:nth-child(2) {
        background: #3264ff;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .tabs {
    li {
      img {
        width: 40px !important;
        margin: 0 0 5px !important;
      }

      span {
        display: block;
      }
    }
  }

  .team {
    flex-direction: column !important;

    &-name {
      .short-name {
        text-align: center;
      }
    }

    .buttons {
      width: 100% !important;
    }
  }
}
</style>
