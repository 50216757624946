<template>
  <div class="about">
    <h1>This is an event-view page</h1>
  </div>
</template>
<script>
export default {
  name: `EventView`,
};
</script>
