import Survey from "@/views/survey/survey";

export const surveyRoutes = [
  {
    lang: "en",
    path: "/en/survey/:slug",
    name: "Survey",
    component: Survey,
  },
  {
    lang: "fr",
    path: "/fr/survey/:slug",
    name: "Survey FR",
    component: Survey,
  },
  {
    lang: "nl",
    path: "/nl/survey/:slug",
    name: "Survey NL",
    component: Survey,
  },
];
