<template>
  <div class="team-roster">
    <h3 class="title">{{ $t("team.roster.title") }} {{ actualSeason - 1 }}-{{ actualSeason }}</h3>
    <div class="grid-container">
      <slot v-for="player in players">
        <PlayerCard :player="player" />
      </slot>
    </div>

    <div class="space-filler"></div>

    <h3 class="title">{{ $t("team.assistants.title") }}</h3>
    <div class="grid-container">
      <slot v-for="staff in staffs">
        <PlayerCard :player="staff" :is-staff="true" />
      </slot>
    </div>
  </div>
</template>

<script>
import PlayerCard from "@/components/simple/player/PlayerCard";

export default {
  name: "team-roster",
  components: { PlayerCard },
  props: {
    players: null,
    staffs: null,
  },
  data() {
    return {
      actualSeason: process.env.VUE_APP_ACTIVE_SEASON,
    };
  },
};
</script>

<style scoped lang="scss">
.team-roster {
  .title {
    color: #000;
    margin: 0 0 25px;
    text-transform: uppercase;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 28px;

    &:nth-child(2) {
      background: red;
    }
  }

  .grid-container {
    display: grid;
    grid-column-gap: 40px;
    grid-row-gap: 60px;
    grid-template-columns: auto auto auto auto;
  }

  .space-filler {
    margin: 100px 0;
  }
}

@media only screen and (max-width: 980px) {
  .team-roster {
    .grid-container {
      grid-template-columns: auto auto auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .team-roster {
    .grid-container {
      grid-template-columns: auto auto;
    }
  }
}

@media only screen and (max-width: 550px) {
  .team-roster {
    .grid-container {
      grid-template-columns: auto;
    }
  }
}
</style>
