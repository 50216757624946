<template>
  <div class="hero">
    <h1 class="title">{{ page?.title }}</h1>
  </div>
  <div class="flex-container">
    <div class="content">
      <h3 class="title">{{ page?.title }}</h3>
      <div v-html="page?.body"></div>
    </div>
  </div>
</template>

<script>
import pageService from "@/services/PageService";
import misc from "@/core/helpers/misc";
export default {
  name: "static-page",

  data() {
    return {
      page: null,
      misc: misc,
      loaders: {
        page: false,
      },
    };
  },
  mounted() {
    this.getPage(this.$route.params.staticPage);
  },
  methods: {
    async getPage(slug) {
      this.page = await pageService.getPageBySlug(slug);
      this.loaders.page = true;
    },
  },
};
</script>

<style scoped lang="scss">
.hero {
  background: url("../../assets/img/hero/bg.jpg") repeat center center;
  background-size: cover;
  height: 560px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    color: #fff;
    font-family: "DrukWideBoldItalic", Sans-serif;
    font-size: 10rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    text-shadow: 0 10px 30px rgb(0 0 0 / 35%);
    margin-top: 40px;
  }
}

.flex-container {
  display: flex;
  justify-content: center;
  padding: 4% 6%;

  .content {
    width: 700px;
    padding: 20px;

    .title {
      color: #000000;
      font-family: sans-serif;
      font-size: 32px;
      line-height: 1.4em;
      font-weight: 600;
      font-style: italic;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .hero {
    height: 340px;

    .title {
      font-size: 140px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .hero {
    height: 210px;

    .title {
      font-size: 80px;
    }
  }
}
</style>
