<template>
  <SurveyHero :survey="survey" />
  <SurveyFans />
  <SurveySteps />
  <SurveyContest :survey="survey" />
  <SurveySocial />
</template>

<script>
import SurveyHero from "@/views/survey/survey-hero";
import SurveyFans from "@/views/survey/survey-fans";
import SurveySteps from "@/views/survey/survey-steps";
import SurveyContest from "@/views/survey/survey-contest";
import SurveySocial from "@/views/survey/survey-social";
import surveyService from "@/services/SurveyService";
import misc from "@/core/helpers/misc";

export default {
  name: "survey",
  components: { SurveySocial, SurveyContest, SurveySteps, SurveyFans, SurveyHero },
  data() {
    return {
      survey: null,
      misc: misc,
      loaders: {
        survey: false,
      },
    };
  },
  mounted() {
    const surveySlug = this.$route.params.slug;
    this.getSurvey(surveySlug);
  },
  methods: {
    async getSurvey(slug) {
      this.survey = await surveyService.getSurveyBySlug(slug);
      this.loaders.survey = true;
    },
  },
};
</script>

<style scoped lang="scss"></style>
