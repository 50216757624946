<template>
  <div class="game-report-play-by-play" v-if="homeTeam && awayTeam">
    <div class="teams">
      <div class="team">
        <div class="team-name">{{ homeTeam?.name }}</div>
        <img :src="homeTeam?.team_logo?.public_url" />
      </div>
      <div class="team">
        <img :src="awayTeam?.team_logo?.public_url" />
        <div class="team-name">{{ awayTeam?.name }}</div>
      </div>
    </div>

    <div class="quarters">
      <div
        class="quarter"
        v-for="period in periodsCount"
        :class="{ active: selectedQuarter.id === period }"
        @click="setActiveQuarter(period)"
      >
        <span v-if="period <= 4">{{ $t("game_report.quarter_short") }}{{ period }}</span>
        <span v-else>OT{{ period - 4 }}</span>
      </div>
    </div>

    <div class="actions">
      <div class="action-row" v-for="row in selectedQuarter.quarter">
        <div class="home-team-action">
          <slot v-if="row?.is_home">
            <div class="player-holder">
              <div class="player">
                <span class="substitution in" v-if="row?.event_code === 1011 && row?.event_subcode === 0">
                  <font-awesome-icon icon="fa-solid fa-arrow-up" />
                </span>
                <span class="player-number"></span>
                <span class="player-last-name">{{ row?.player?.first_name }}</span>
                <span class="player-first-name">{{ row?.player?.last_name?.toUpperCase() }}</span>
              </div>
              <div class="player" v-if="row?.player2 && row?.event_code === 1011 && row?.event_subcode === 0">
                <span class="substitution out">
                  <font-awesome-icon icon="fa-solid fa-arrow-down" />
                </span>
                <span class="player-number"></span>
                <span class="player-last-name">{{ row.player2?.first_name }}</span>
                <span class="player-first-name">{{ row.player2?.last_name?.toUpperCase() }}</span>
              </div>
            </div>

            <div v-if="row?.action" class="action">{{ $t(row?.action) }}</div>
          </slot>
        </div>
        <div class="action-details">
          <span class="home-points">{{ row?.current_home_score }}</span>
          <div class="time">
            <span class="quarter">{{ $t("game_report.quarter_short") }}{{ row?.period }}</span>
            <span class="minutes">{{ row?.minute }}:{{ row?.second }}</span>
          </div>
          <span class="away-points">{{ row?.current_away_score }}</span>
        </div>
        <div class="away-team-action">
          <slot v-if="!row?.is_home">
            <div v-if="row?.action" class="action">{{ $t(row?.action) }}</div>
            <div class="player-holder">
              <div class="player">
                <span class="player-number"></span>
                <span class="player-last-name">{{ row?.player?.first_name }}</span>
                <span class="player-first-name">{{ row?.player?.last_name?.toUpperCase() }}</span>
                <span class="substitution in" v-if="row?.event_code === 1011 && row?.event_subcode === 0">
                  <font-awesome-icon icon="fa-solid fa-arrow-up" />
                </span>
              </div>
              <div class="player" v-if="row?.player2 && row?.event_code === 1011 && row?.event_subcode === 0">
                <span class="player-number"></span>
                <span class="player-last-name">{{ row.player2?.first_name }}</span>
                <span class="player-first-name">{{ row.player2?.last_name?.toUpperCase() }}</span>
                <span class="substitution out">
                  <font-awesome-icon icon="fa-solid fa-arrow-down" />
                </span>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
  <div v-else>{{ $t("game_report.no_statistics") }}</div>
</template>

<script>
import misc from "@/core/helpers/misc";
import matchEvents from "@/core/helpers/match-events";
export default {
  name: "game-report-play-by-play",
  props: {
    gameReports: [],
  },
  data() {
    return {
      misc: misc,
      matchEvents: matchEvents.matchEvents,
      selectedQuarter: {
        id: 1,
        quarter: null,
      },
      homeTeam: null,
      awayTeam: null,
      periodsCount: [],
    };
  },

  methods: {
    setActiveQuarter(quarterNumber) {
      this.selectedQuarter.id = quarterNumber;
      this.selectedQuarter.quarter = this.gameReports
        .filter((report) => report.period === quarterNumber)
        .sort((a, b) => a.order - b.order);
    },
  },

  watch: {
    gameReports(gameReports) {
      gameReports.forEach((report) => {
        this.periodsCount.push(report.period);
        this.matchEvents.forEach((event) => {
          if (report.event_code === event.code && report.event_subcode === event.sub_code) {
            report.action = "match_events." + event.code + "_" + event.sub_code;
          }
        });
      });
      this.periodsCount = new Set(this.periodsCount);
      this.homeTeam = gameReports.find((report) => report.is_home)?.team;
      this.awayTeam = gameReports.find((report) => !report.is_home)?.team;
      this.selectedQuarter.quarter = gameReports
        .filter((report) => report.period === 1)
        .sort((a, b) => a.order - b.order);

      /*      const events = [];
      gameReports.forEach((report) =>
        events.push({ event_code: report.event_code, event_subcode: report.event_subcode })
      );
      console.log("events", events);

      const listOfTags = events,
        keys = ["event_code", "event_subcode"],
        filtered = listOfTags.filter(
          (
            (s) => (o) =>
              ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
          )(new Set())
        );

      console.log(filtered.sort((a, b) => a.event_code - b.event_code));*/
    },
  },
};
</script>

<style scoped lang="scss">
.game-report-play-by-play {
  .teams {
    display: flex;
    gap: 100px;

    .team {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;

      img {
        width: 100px;
      }

      &-name {
        font-family: DrukWideBoldItalic;
        font-size: 32px;
        text-transform: uppercase;
        line-height: 1;
      }
    }
  }

  .quarters {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;

    .quarter {
      font-family: DrukWide;
      cursor: pointer;

      &.active {
        color: #6441ff;
      }
    }
  }

  .actions {
    .action-row {
      display: flex;
      margin-bottom: 10px;

      .home-team-action,
      .away-team-action {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1 0;
        padding: 10px;
        font-family: DrukWide;
        background: rgba(255, 255, 255, 0.6);

        .player {
          &-number {
            font-size: 16px;
          }

          &-last-name {
            font-size: 16px;
            font-style: italic;
            margin-right: 10px;
          }

          &-first-name {
            font-weight: bold;
            font-size: 16px;
          }

          .substitution {
            font-size: 20px;
            vertical-align: middle;

            &.in {
              color: green;
            }

            &.out {
              color: red;
            }

            svg {
              vertical-align: middle;
            }
          }
        }
      }

      .home-team-action {
        .player-holder {
          padding-right: 20px;
        }

        .player {
          .substitution {
            margin-right: 5px;
          }
        }

        .action {
          text-align: right;
        }
      }

      .away-team-action {
        .player-holder {
          padding-left: 20px;
        }

        .player {
          justify-content: flex-end;
          text-align: right;

          .substitution {
            margin-left: 5px;
          }
        }
      }

      .action-details {
        display: flex;
        align-items: center;
        padding: 0 10px;
        gap: 40px;

        .home-points {
          font-family: DrukWideBoldItalic;
          color: #6441ff;
          font-size: 36px;
        }

        .time {
          display: flex;
          flex-direction: column;
          align-items: center;

          .quarter {
            font-weight: bold;
          }

          .minutes {
            font-size: 14px;
          }
        }

        .away-points {
          font-family: DrukWideBoldItalic;
          color: #f75f00;
          font-size: 36px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .player {
    &-number {
      font-size: 14px !important;
    }

    &-last-name {
      font-size: 14px !important;
    }

    &-first-name {
      font-size: 14px !important;
    }
  }

  .home-team-action,
  .away-team-action {
    flex-direction: column;
    gap: 10px;

    .player-holder {
      padding: 0 !important;
      text-align: center !important;
    }

    .action {
      font-size: 14px !important;
      text-align: center !important;
    }
  }

  .teams {
    gap: 20px !important;

    .team {
      :nth-child(2) {
        .team-name {
          text-align: right;
          line-height: 1;
        }
      }

      img {
        width: 60px !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .home-team-action,
  .away-team-action {
    .player-first-name {
      font-size: 11px !important;
    }

    .player-last-name {
      font-size: 11px !important;
      display: block !important;
      margin: 0 !important;
    }

    .action {
      font-size: 10px !important;
    }
  }

  .home-team-action {
    align-items: flex-start !important;

    .player-holder {
      text-align: left !important;
    }

    .action {
      text-align: left !important;
    }
  }

  .away-team-action {
    align-items: flex-end !important;

    .player-holder {
      text-align: right !important;
    }

    .action {
      text-align: right !important;
    }
  }

  .action-details {
    gap: 0 !important;
    flex-wrap: wrap !important;
    justify-content: space-evenly !important;

    .home-points,
    .away-points {
      line-height: 1 !important;
      font-size: 28px !important;
    }

    .time {
      order: -1;
      width: 100%;
    }
  }
}
</style>
