<template>
  <div class="hero">
    <div class="hero-inner">
      <div class="left">
        <h1>{{ $t("game_report.title") }}</h1>
        <p>
          <img src="../../assets/img/icons/basketball.png" />
          <span>{{ schedule?.competition?.name }}</span>
        </p>
        <p>
          <img src="../../assets/img/icons/calendar.png" />
          <span>{{ moment(schedule?.game_time).format("dddd, D MMM YYYY, h:mm") }}</span>
        </p>
        <p>
          <img src="../../assets/img/icons/location-pin.png" />
          <span>{{ schedule?.arena?.name }}</span>
        </p>
      </div>
      <div class="right">
        <div class="teams-score">
          <div class="team team-left">
            <div class="flex-container">
              <img
                v-if="schedule?.competitors[0]?.competition_team?.team_logo"
                :src="schedule?.competitors[0].competition_team.team_logo.public_url"
              />
              <span class="team-short-name">{{ schedule?.competitors[0]?.competition_team?.short_name }} </span>
            </div>
            <p class="team-long-name">
              {{ schedule?.competitors[0]?.competition_team?.name }}
            </p>
          </div>

          <div class="score">
            {{ schedule?.competitors[0]?.finalScore }} - {{ schedule?.competitors[1]?.finalScore }}
          </div>

          <div class="team team-right">
            <div class="flex-container">
              <span class="team-short-name">{{ schedule?.competitors[1]?.competition_team?.short_name }}</span>
              <img
                v-if="schedule?.competitors[1]?.competition_team?.team_logo"
                :src="schedule?.competitors[1].competition_team.team_logo.public_url"
              />
            </div>
            <p class="team-long-name">
              {{ schedule?.competitors[1]?.competition_team?.name }}
            </p>
          </div>
        </div>

        <div class="results">
          <div class="result" v-for="(period, index) in schedule?.competitors[0]?.period_scores">
            <div class="header" v-if="index <= 3">{{ $t("game_report.quarter_short") }}{{ index + 1 }}</div>
            <div class="header" v-else>OT{{ index - 3 }}</div>
            <div class="score">
              {{ schedule?.competitors[0].period_scores[index]?.score }} -
              {{ schedule?.competitors[1].period_scores[index]?.score }}
            </div>
          </div>
        </div>

        <div class="watch-btn-wrapper" v-if="schedule?.video_url">
          <a :href="schedule.video_url" target="_blank" class="watch-btn">{{ $t("game_report.watch_game_button") }}</a>
        </div>

        <div class="watch-btn-wrapper" v-if="!schedule?.video_url && schedule?.competition?.video_url">
          <a :href="schedule.competition.video_url" target="_blank" class="watch-btn">{{
            $t("game_report.watch_game_button")
          }}</a>
        </div>
      </div>
    </div>
  </div>

  <div class="tabs-wrapper">
    <ul class="tabs">
      <li :class="{ active: activeTab === 1 }">
        <a href="javascript:void(0)" @click="onChangeTab(1)">{{ $t("game_report.tabs.overview") }}</a>
      </li>
      <li :class="{ active: activeTab === 2 }">
        <a href="javascript:void(0)" @click="onChangeTab(2)">{{ $t("game_report.tabs.boxscore") }}</a>
      </li>
      <li :class="{ active: activeTab === 3 }">
        <a href="javascript:void(0)" @click="onChangeTab(3)">{{ $t("game_report.tabs.play_by_play") }}</a>
      </li>
    </ul>
  </div>

  <div class="content">
    <div v-show="activeTab === 1">
      <GameReportOverview :overview-data="overviewData" />
    </div>

    <div v-show="activeTab === 2">
      <GameReportBoxscore :boxscores="boxScores" />
    </div>

    <div v-show="activeTab === 3">
      <GameReportPlayByPlay :game-reports="gameReports" />
    </div>
  </div>
</template>

<script>
import GameReportBoxscore from "@/views/game-report/game-report-boxscore";
import scheduleService from "@/services/ScheduleService";
import moment from "moment/moment";
import GameReportPlayByPlay from "@/views/game-report/game-report-play-by-play";
import GameReportOverview from "@/views/game-report/game-report-overview/game-report-overview";
import misc from "@/core/helpers/misc";
export default {
  name: "game-report",
  components: { GameReportOverview, GameReportPlayByPlay, GameReportBoxscore },
  props: ["gameId"],
  data() {
    return {
      boxScores: [],
      schedule: null,
      activeTab: 1,
      gameReports: [],
      overviewData: null,
      loaders: {
        schedule: false,
        gameReports: false,
        overviewData: false,
        boxScores: false,
      },
      misc: misc,
    };
  },

  mounted() {
    const hash = this.$route.hash;
    const gameId = this.$route.params.gameId;

    this.setActiveTabByHash(hash);

    this.getSchedule(gameId);
    this.getOverviewData(gameId);
  },

  watch: {
    "$route.hash": {
      handler(hash) {
        this.setActiveTabByHash(hash);
      },
    },
  },

  methods: {
    onChangeTab(tabId) {
      this.activeTab = tabId;
    },

    setActiveTabByHash(hash) {
      switch (hash) {
        case "#overview":
        default:
          this.activeTab = 1;
          break;
        case "#boxscore":
          this.activeTab = 2;
          break;
        case "#play-by-play":
          this.activeTab = 3;
          break;
      }
    },

    async getBoxScores(competitionId, gameId) {
      await scheduleService.getBoxscore(competitionId, gameId).then((res) => {
        res?.sort((a, b) => b.is_home - a.is_home);
        res?.forEach((score) => {
          score.players?.sort((a, b) => b.starter - a.starter || b.minute - a.minute);
        });
        this.boxScores = res;
        this.loaders.boxScores = true;
      });
    },

    async getSchedule(gameId) {
      await scheduleService.getScheduleById(gameId).then((res) => {
        this.schedule = res;
        this.loaders.schedule = true;

        this.getGameReports(res.competition.id, gameId);
        this.getBoxScores(res.competition.id, gameId);
      });
    },

    async getGameReports(competitionId, gameId) {
      await scheduleService.getGameReports(competitionId, gameId).then((res) => {
        this.gameReports = res;
        this.loaders.gameReports = true;
      });
    },

    async getOverviewData(gameId) {
      this.overviewData = await scheduleService.getGameReportsOverview(gameId);
      this.loaders.overviewData = true;
    },
  },

  computed: {
    moment: () => moment,
  },
};
</script>

<style scoped lang="scss">
.hero {
  background: url("../../assets/img/hero/bnx-bg-page-game-report-min.jpg") left;
  background-size: cover;
  min-height: 650px;
  padding: 8% 8% 0;

  &-inner {
    display: flex;
    padding-top: 120px;

    .left {
      width: 40%;

      h1 {
        color: #fff;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 7rem;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 0.95em;
        text-shadow: 0 10px 30px rgb(0 0 0 / 35%);
        margin-bottom: 20px;
        max-width: 300px;
      }

      p {
        color: #fff;
        margin-bottom: 0;

        span {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
            sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          vertical-align: middle;
          margin-left: 5px;
        }
      }
    }

    .right {
      width: 60%;

      .teams-score {
        display: flex;
        color: #fff;
        justify-content: space-around;

        .team {
          opacity: 0.5;

          .flex-container {
            display: flex;

            img {
              width: 45px;
            }

            .team-short-name {
              font-size: 30px;
              font-family: DrukWide;
            }
          }

          .team-long-name {
            font-weight: 700;
            font-size: 14px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
              sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            text-align: center;
          }
        }

        .score {
          font-family: DrukWide;
          font-size: 42px;
          font-weight: 800;
        }
      }

      .results {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 15px;
        margin-top: 20px;

        .result {
          background: #fff;
          color: #003c80;
          display: inline-block;
          border-radius: 10px;
          width: 100px;
          box-shadow: 0 7px 7px #256ead;
          line-height: 20px;
          text-align: center;
          padding: 10px;

          .header {
            font-family: DrukWide;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 5px;
          }

          .score {
            font-size: 12px;
            letter-spacing: 1px;
            font-family: DrukWide;
            font-weight: 700;
            color: #000;
          }
        }
      }

      .watch-btn-wrapper {
        text-align: center;
        margin-top: 40px;

        .watch-btn {
          background: #f75f00;
          border-radius: 50px;
          border: none;
          color: #fff;
          text-transform: uppercase;
          font-weight: 600;
          font-family: DrukWideBoldItalic, Sans-serif;
          padding: 7px 25px;
          font-size: 20px;
          text-decoration: none;
          transition: 0.3s;

          &:hover {
            color: #fff;
            background-color: #c36;
          }
        }
      }
    }
  }
}

.tabs-wrapper {
  position: relative;
  top: -27px;
  padding: 0 8%;
  margin-bottom: -54px;

  .tabs {
    padding: 0;
    list-style: none;
    background: #fff;
    border-radius: 25px;
    margin: 0 auto;
    max-width: 100%;
    float: none;
    display: inline-block;
    box-shadow: 0 10px 5px #def3ff;

    li {
      display: inline-block;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-weight: 600;
      font-size: 24px;
      padding: 10px 40px;
      border-radius: 50px;
      margin: -1px;
      text-transform: uppercase;

      a {
        color: #003c80;
        text-decoration: none;
      }

      &.active {
        background: #37d69e;

        a {
          color: #fff;
        }
      }
    }
  }
}

.content {
  background-color: #f2faff;
  padding: 74px 8% 4% 8%;
}

@media only screen and (max-width: 980px) {
  .hero {
    &-inner {
      flex-direction: column;

      .left {
        width: 100%;

        h1 {
          font-size: 75px;
          margin: 0 auto 20px;
          text-align: center;
        }

        p {
          text-align: center;
        }
      }

      .right {
        width: 100%;
        margin: 20px 0 80px;

        .teams-score {
          align-items: center;
          justify-content: space-between;

          .team {
            &-left {
              width: 100px;
              .flex-container {
                flex-direction: column;
              }
            }

            &-right {
              width: 100px;
              .flex-container {
                flex-direction: column-reverse;
              }
            }

            .team-long-name {
              font-size: 12px;
              text-align: center;
            }

            .flex-container {
              line-height: 1;
              gap: 20px;
              align-items: center;

              img {
                width: 80px;
              }

              .team-short-name {
                font-size: 20px;
              }
            }

            .team-long-name {
              line-height: 1;
              font-size: 12px;
            }
          }

          .score {
            font-size: 20px;
          }
        }

        .watch-btn-wrapper {
          margin-top: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .tabs-wrapper {
    text-align: center;

    .tabs {
      li {
        width: 100%;
      }
    }
  }
}
</style>
