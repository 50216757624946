<template>
  <div class="gamer-report-overview-key-statistics">
    <div class="header">
      <div class="team">
        <h2 class="team-short-name">{{ keyStatistics?.home?.competitor?.competition_team?.short_name }}</h2>
        <h5 class="team-long-name">{{ keyStatistics?.home?.competitor?.competition_team?.name }}</h5>
      </div>

      <h4 class="title">{{ $t("game_report.overview.key_statistics.title") }}</h4>

      <div class="team">
        <h2 class="team-short-name">{{ keyStatistics?.away?.competitor?.competition_team?.short_name }}</h2>
        <h5 class="team-long-name">{{ keyStatistics?.away?.competitor?.competition_team?.name }}</h5>
      </div>
    </div>

    <div class="content">
      <div class="stat-row">
        <h3 class="points">{{ keyStatistics?.home?.total?.steal }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.key_statistics.steals") }}</div>
        <h3 class="points">{{ keyStatistics?.away?.total?.steal }}</h3>
      </div>

      <div class="stat-row">
        <h3 class="points">{{ keyStatistics?.home?.total?.assist }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.key_statistics.assists") }}</div>
        <h3 class="points">{{ keyStatistics?.away?.total?.assist }}</h3>
      </div>

      <div class="stat-row">
        <h3 class="points">{{ keyStatistics?.home?.total?.turnover }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.key_statistics.turnovers") }}</div>
        <h3 class="points">{{ keyStatistics?.away?.total?.turnover }}</h3>
      </div>

      <div class="stat-row">
        <h3 class="points">{{ keyStatistics?.home?.total?.block }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.key_statistics.blocks") }}</div>
        <h3 class="points">{{ keyStatistics?.away?.total?.block }}</h3>
      </div>

      <div class="stat-row">
        <h3 class="points">{{ keyStatistics?.home?.total?.foul }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.key_statistics.fouls") }}</div>
        <h3 class="points">{{ keyStatistics?.away?.total?.foul }}</h3>
      </div>

      <div class="stat-row">
        <h3 class="points">{{ keyStatistics?.home?.total?.defensive_rebound }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.key_statistics.defensive_rebounds") }}</div>
        <h3 class="points">{{ keyStatistics?.away?.total?.defensive_rebound }}</h3>
      </div>

      <div class="stat-row">
        <h3 class="points">{{ keyStatistics?.home?.total?.offensive_rebound }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.key_statistics.offensive_rebounds") }}</div>
        <h3 class="points">{{ keyStatistics?.away?.total?.offensive_rebound }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "game-report-overview-key-statistics",
  props: {
    keyStatistics: null,
  },
};
</script>

<style scoped lang="scss">
.gamer-report-overview-key-statistics {
  background: #fff;
  box-shadow: 0 5px 5px #def3ff;
  border-radius: 10px;
  padding: 30px 15px;

  .header {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;

    .team {
      width: 25%;
      text-align: center;

      &-short-name {
        font-family: DrukWide;
        font-size: 28px;
        margin: 0;
        font-weight: 600;
      }

      &-long-name {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .title {
      width: 50%;
      text-align: center;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-size: 28px;
      text-transform: uppercase;
    }
  }

  .content {
    .stat-row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .points {
        width: 25%;
        text-align: center;
        font-family: DrukWide;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
      }

      .stat-name {
        width: 50%;
        text-align: center;
        border-radius: 20px;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 24px;
        font-weight: 600;
        color: #fff;
        background: #3264ff;
        text-transform: uppercase;
        padding: 2px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .gamer-report-overview-key-statistics {
    .header {
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .gamer-report-overview-key-statistics {
    .header {
      margin-bottom: 20px;

      .team {
        &-short-name {
          font-size: 22px;
        }

        &-long-name {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
