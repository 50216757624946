import Referees from "@/views/referees/Referees";

export const refereesRoutes = [
  {
    lang: "en",
    path: "/en/referees",
    name: "Referees",
    component: Referees,
  },
  {
    lang: "fr",
    path: "/fr/arbitres",
    name: "Arbitres",
    component: Referees,
  },
  {
    lang: "nl",
    path: "/nl/scheidsrechters",
    name: "Scheidsrechters",
    component: Referees,
  },
];
