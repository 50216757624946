<template>
  <div class="survey-hero">
    <div class="survey-bg-overlay"></div>
    <div class="content">
      <img :src="survey?.logo?.public_url" />
      <p class="description">
        {{ survey?.description }}
      </p>
      <div class="buttons-wrapper">
        <a href="#answering" class="to-answering" @click.prevent="misc.scrollTo('#answering')">Start Answering</a>
        <a href="#rules" class="to-rules" @click.prevent="misc.scrollTo('#rules')">Understand the rules</a>
      </div>
    </div>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";
export default {
  name: "survey-hero",
  props: {
    survey: null,
  },

  data() {
    return {
      misc: misc,
    };
  },
};
</script>

<style scoped lang="scss">
.survey-hero {
  background: url("../../assets/img/hero/bnx-win-bg-image-new-scaled-1.webp") no-repeat center center;
  background-size: cover;
  position: relative;

  .survey-bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, #00000000 30%, #000000 100%);
  }

  .content {
    padding: 300px 0 50px;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    text-align: center;

    img {
      width: 500px;
    }

    .description {
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3em;
      margin: 30px auto 40px;
      width: 480px;
    }

    .buttons-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .to-answering {
        font-family: "DrukWideItalic", Sans-serif;
        font-size: 30px;
        background-color: #e2ff00;
        color: #000;
        border-radius: 100px;
        border: none;
        padding: 12px 40px;
        text-decoration: none;
        text-transform: uppercase;
        max-width: fit-content;
      }

      .to-rules {
        font-family: "DrukWideItalic", Sans-serif;
        font-size: 30px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 0.85em;
        fill: #ffffff;
        color: #ffffff;
        background-color: #e2ff0000;
        border-radius: 100px;
        margin: 15px 0;
        text-decoration: none;
        transition: 0.3s;

        &:hover {
          color: #e2ff00;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .survey-hero {
    background: url("../../assets/img/hero/bnx-win-bg-image-tab-scaled-1.webp") no-repeat center center;
    background-size: cover;

    .content {
      max-width: 650px;

      img {
        width: 400px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .survey-hero {
    background: url("../../assets/img/hero/bnx-win-bg-image-mobile-scaled-1.webp") no-repeat top center;
    background-size: cover;

    .content {
      padding-top: 200px;

      img {
        width: 240px;
      }

      .description {
        font-size: 16px;
        width: auto;
        margin: 30px 20px 40px;
      }

      .buttons-wrapper {
        .to-answering,
        .to-rules {
          font-size: 24px;
        }
      }
    }
  }
}
</style>
