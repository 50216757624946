<template>
  <h3 class="title">{{ $t("game_report.overview.top_performers.title") }}</h3>
  <div class="game-report-overview-top-performers">
    <div class="players">
      <div class="player">
        <div class="first-name">{{ topPerformers?.home?.player?.player?.first_name }}</div>
        <div class="last-name">{{ topPerformers?.home?.player?.player?.last_name?.toUpperCase() }}</div>
      </div>
      <div class="player">
        <div class="first-name">{{ topPerformers?.away?.player?.player?.first_name }}</div>
        <div class="last-name">{{ topPerformers?.away?.player?.player?.last_name?.toUpperCase() }}</div>
      </div>
    </div>

    <div class="header">
      <div class="team">
        <h2 class="team-short-name">{{ topPerformers?.home?.competitor?.competition_team?.short_name }}</h2>
      </div>

      <div class="center">
        <h3 class="score">+{{ topPerformers?.home?.player?.value }}</h3>
        <h4 class="title">{{ $t("game_report.overview.top_performers.efficiency") }}</h4>
        <h3 class="score">+{{ topPerformers?.away?.player?.value }}</h3>
      </div>

      <div class="team">
        <h2 class="team-short-name">{{ topPerformers?.away?.competitor?.competition_team?.short_name }}</h2>
      </div>
    </div>

    <div class="content">
      <div class="stat-row">
        <h3 class="points">{{ topPerformers?.home?.player?.points }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.top_performers.points") }}</div>
        <h3 class="points">{{ topPerformers?.away?.player?.points }}</h3>
      </div>

      <div class="stat-row">
        <h3 class="points">{{ topPerformers?.home?.player?.total_rebound }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.top_performers.rebounds") }}</div>
        <h3 class="points">{{ topPerformers?.away?.player?.total_rebound }}</h3>
      </div>

      <div class="stat-row">
        <h3 class="points">{{ topPerformers?.home?.player?.assist }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.top_performers.assists") }}</div>
        <h3 class="points">{{ topPerformers?.away?.player?.assist }}</h3>
      </div>

      <div class="stat-row">
        <h3 class="points">{{ topPerformers?.home?.player?.steal }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.top_performers.steals") }}</div>
        <h3 class="points">{{ topPerformers?.away?.player?.steal }}</h3>
      </div>

      <div class="stat-row">
        <h3 class="points">{{ topPerformers?.home?.player?.block }}</h3>
        <div class="stat-name">{{ $t("game_report.overview.top_performers.blocks") }}</div>
        <h3 class="points">{{ topPerformers?.away?.player?.block }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "game-report-overview-top-performers",
  props: {
    topPerformers: null,
  },
};
</script>

<style scoped lang="scss">
.title {
  font-family: DrukWideBoldItalic, Sans-serif;
  font-size: 26px;
  font-weight: 600;
  text-transform: uppercase;
}

.game-report-overview-top-performers {
  background: #fff;
  box-shadow: 0 5px 5px #def3ff;
  border-radius: 10px;
  padding: 35px 15px 30px;

  .players {
    display: flex;
    gap: 20px;

    .player {
      width: 50%;
      border: 1px solid #c5d9f0;
      padding: 10px;
      color: #6441ff;
      border-radius: 10px;
      margin-bottom: 2%;

      .first-name {
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 26px;
        text-transform: uppercase;
        line-height: 1;
      }

      .last-name {
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 50px;
        text-transform: uppercase;
        line-height: 1;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .team {
      width: 25%;
      text-align: center;

      &-short-name {
        font-family: DrukWide;
        font-size: 28px;
        margin: 0;
        font-weight: 600;
      }
    }

    .center {
      width: 50%;
      font-family: DrukWideBoldItalic, Sans-serif;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      text-align: center;

      .score {
        width: 25%;
        font-size: 25px;
        font-family: DrukWide;
        color: #6441ff;
        margin: 0;
        font-weight: bold;
      }

      .title {
        width: 50%;
        font-size: 28px;
      }
    }
  }

  .content {
    .stat-row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .points {
        width: 25%;
        text-align: center;
        font-family: DrukWide;
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        line-height: 1;
      }

      .stat-name {
        width: 50%;
        text-align: center;
        border-radius: 20px;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 24px;
        font-weight: 600;
        color: #fff;
        background: #6441ff;
        text-transform: uppercase;
        padding: 2px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .game-report-overview-top-performers {
    .players {
      .player {
        .first-name {
          font-size: 18px;
        }

        .last-name {
          font-size: 30px;
        }
      }
    }
    .header {
      margin-bottom: 20px;

      .team {
        &-short-name {
          font-size: 22px;
        }
      }

      .center {
        flex-wrap: wrap;
        justify-content: space-between;

        .score {
          width: 50%;
          font-size: 22px;
        }

        .title {
          width: 100%;
          order: -1;
        }
      }
    }
  }
}
</style>
