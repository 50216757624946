import Awards from "@/views/awards/awards";

export const awardsRoutes = [
  {
    lang: "en",
    path: "/en/awards",
    name: "Awarded",
    component: Awards,
  },
  {
    lang: "fr",
    path: "/fr/bnxt-awards-2022-decerne",
    name: "Decerne",
    component: Awards,
  },
  {
    lang: "nl",
    path: "/nl/bnxt-awards-2022-toegekend",
    name: "Toegekend",
    component: Awards,
  },
];
