<template>
  <li class="nav-item dropdown">
    <a
      class="nav-link dropdown-toggle"
      href="javascript:void(0)"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <slot v-for="lang in availableLangs">
        <slot v-if="currentLang === lang.alpha2"> <img :src="lang.flag" />{{ lang.name }} </slot>
      </slot>
      <font-awesome-icon icon="fa-solid fa-angle-down" />
    </a>
    <ul class="dropdown-menu">
      <slot v-for="lang in availableLangs">
        <li v-if="currentLang !== lang.alpha2" @click="switchLocale(lang.alpha2)">
          <a class="dropdown-item" href="javascript:void(0)"><img :src="lang.flag" />{{ lang.name }}</a>
        </li>
      </slot>
    </ul>
  </li>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  data() {
    return {
      availableLangs: [
        {
          alpha2: "en",
          name: "English",
          flag: require("@/assets/img/language/en.png"),
        },
        {
          alpha2: "nl",
          name: "Nederlands",
          flag: require("@/assets/img/language/nl.png"),
        },
        {
          alpha2: "fr",
          name: "Français",
          flag: require("@/assets/img/language/fr.png"),
        },
      ],
      urlLocale: String,
      currentLang: localStorage.getItem("lang") || this.urlLocale,
    };
  },

  mounted() {
    // TODO: BUG => if there is no any cached lang in storage and go to /fr/
    this.urlLocale = this.$route.path.split("/")[1];
    this.$i18n.locale = this.urlLocale;
  },

  methods: {
    switchLocale(locale) {
      this.currentLang = locale;
      localStorage.setItem("lang", locale);
      this.$i18n.locale = locale;

      this.redirectToCurrentPageByLocale(locale);
      this.$emit("langChange", locale);
    },

    redirectToCurrentPageByLocale(locale) {
      const currentUrlPath = this.$route.path;
      const currentUrlQueries = this.$route.query;
      const allRoutes = this.$router.options.routes;
      const currentComponentName = allRoutes.find((route) => route.path === currentUrlPath)?.component.name;

      if (!currentComponentName && this.$route.name.includes("SinglePost")) {
        const componentPath = allRoutes.find(
          (route) => route.name.includes("SinglePost") && route.lang === this.currentLang
        ).path;
        this.$router?.push({ path: componentPath.split(":")[0] + this.$route.params.slug });
      } else if (!currentComponentName && this.$route.name.includes("Home")) {
        this.$router?.push({ path: "/" + this.currentLang });
      } else if (!currentComponentName && this.$route.name.includes("Static page")) {
        // TODO: handle Static page by BE (should have localed URL)
      } else if (!currentComponentName && this.$route.name.includes("Survey")) {
        const url = currentUrlPath.split("/");
        url[1] = this.currentLang;
        this.$router?.push({ path: url.join("/") });
      } else {
        const localedUrlPath = allRoutes
          .filter((route) => route?.component?.name === currentComponentName)
          .find((route) => route.lang === locale)?.path;
        this.$router?.push({ path: localedUrlPath, query: currentUrlQueries });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.nav-item {
  &::marker {
    content: none;
  }
}
a {
  img {
    width: 24px;
    border-radius: 50px;
    height: 24px;
    vertical-align: middle;
    top: -4px;
    position: relative;
    margin-right: 10px;
  }

  svg {
    margin-left: 5px;
  }
}
</style>
