<template>
  <div class="hero">
    <video src="../../assets/video/bnxt_about.mp4" muted autoplay></video>
    <div class="background-overlay"></div>
    <div class="hero-text-wrapper">
      <h1>{{ $t("about.title") }}</h1>
      <div class="play-video">
        <img src="../../assets/img/icons/bnxt_icon_play.svg" alt="Play video" @click="showVideoModal('P_1VQhpPY4Y')" />
      </div>
    </div>
  </div>

  <div class="videos">
    <h3>{{ $t("about.subtitle") }}</h3>

    <div class="flex-container">
      <div class="video-box">
        <div class="background-overlay"></div>
        <div class="title">INTRODUCING BNXT</div>
        <a href="javascript:void(0)" class="watch-now" @click="showVideoModal('bCnAIlHI-Ls')">
          <img src="../../assets/img/icons/bnxt_icon_play.svg" alt="Play video" />
          <span>Watch now</span>
        </a>
      </div>

      <div class="video-box">
        <div class="background-overlay"></div>
        <div class="title">BNXT DIGITAL PRESS CONFERENCE</div>
        <a href="javascript:void(0)" class="watch-now" @click="showVideoModal('afFJFM7mn10')">
          <img src="../../assets/img/icons/bnxt_icon_play.svg" alt="Play video" />
          <span>Watch now</span>
        </a>
      </div>

      <div class="video-box">
        <div class="background-overlay"></div>
        <div class="title">COMPETITION FORMAT</div>
        <a href="javascript:void(0)" class="watch-now" @click="showVideoModal('P_1VQhpPY4Y')">
          <img src="../../assets/img/icons/bnxt_icon_play.svg" alt="Play video" />
          <span>Watch now</span>
        </a>
      </div>
    </div>
  </div>

  <AboutParallax />

  <div class="explanation">
    <div class="flex-container">
      <div class="img-wrapper">
        <img src="../../assets/img/manefesto-title-min-363x1024.png" class="img-fluid sticky-top" />
      </div>

      <div class="texts-wrapper">
        <div class="text">
          <h4>BNXT is a mindset</h4>
          <p>A mindset that goes beyond the competition</p>
        </div>

        <div class="text">
          <h4>BNXT IS A CALL TO ACTION</h4>
          <p>
            to be the next contender, champion, player, talent, believer & beginner... in a new basketball community
            uniting the Netherlands & Belgium
          </p>
        </div>

        <div class="text">
          <h4>BNXT IS A MILESTONE</h4>
          <p>
            ta horizon that goes beyond basketball raising the popularity of the no.1 indoor sport focusing on its
            culture on and off the court
            <br />
            <br />
            BNXT aims for the future by empowering believers and challenging members
          </p>
        </div>

        <div class="text">
          <h4>BNXT LEAGUE IS A PROFESSIONAL COMPETITION</h4>
          <p>
            games with impact, events growing the popularity of the game,
            <br />
            basketball as a way of life by the players / for the fans
          </p>
        </div>

        <div class="text">
          <h4>BNXT LEAGUE IS A UNIFIED TEAM</h4>
          <p>
            with all stakeholders involved a company setting new standards in media an events producer involving fans to
            unite
          </p>
        </div>

        <div class="text">
          <h4>BNXT LEAGUE COLLABORATES</h4>
          <p>with its stakeholders to run the competition includes fans through events is ambitious & caring</p>
        </div>
      </div>
    </div>
  </div>

  <OurPartners @partnersLoaded="loaders.partners = true" />

  <VideoModal :video-id="currentVideoId" @close="closeVideoModal" v-if="isVideoModalVisible" />
</template>

<script>
import OurPartners from "@/components/shared/our-partners";
import AboutParallax from "@/views/about/about-parallax";
import VideoModal from "@/components/shared/modals/video-modal";
import misc from "@/core/helpers/misc";
export default {
  name: `About`,
  components: { VideoModal, AboutParallax, OurPartners },
  data() {
    return {
      isVideoModalVisible: false,
      currentVideoId: String,
      misc: misc,
      loaders: {
        partners: false,
      },
    };
  },

  methods: {
    showVideoModal(videoId) {
      this.currentVideoId = videoId;
      this.isVideoModalVisible = true;
    },

    closeVideoModal() {
      this.currentVideoId = null;
      this.isVideoModalVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.hero {
  background: url("../../assets/img/hero/bg.jpg") 50% 50%;
  background-size: cover;
  padding: 8% 8% 0;
  position: relative;
  height: 585px;

  video {
    width: 100%;
    position: absolute;
    left: 0;
    top: -240px;
  }

  .background-overlay {
    background-color: #000;
    opacity: 0.5;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }

  &-text-wrapper {
    margin-top: 100px;
    margin-bottom: 160px;
    display: flex;
    position: relative;
    z-index: 1;
    align-items: center;

    h1 {
      width: 60%;
      color: #fff;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-size: 12rem;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 0.9em;
      text-shadow: 0 10px 30px rgb(0 0 0 / 35%);
      padding: 10px;
    }

    .play-video {
      width: 40%;
      text-align: center;
      padding: 8px 8px 0 0;

      img {
        width: 120px;
        cursor: pointer;
      }
    }
  }
}

.videos {
  padding: 6% 8%;
  position: relative;
  background: #fff;

  h3 {
    color: #000;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 60px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    text-align: center;
    margin: 0 10% 60px;
  }

  .flex-container {
    display: flex;

    .video-box {
      width: 33.33%;
      background-image: url("../../assets/img/BNXT-bg-min.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 17vw;
      min-height: 240px;
      position: relative;
      margin: 10px;

      .background-overlay {
        background-color: transparent;
        background-image: linear-gradient(180deg, #6441ff00 60%, #6441ff78 100%);
        opacity: 1;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
      }

      .title {
        color: #6441ff;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 42px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 0.9em;
        letter-spacing: 0;
        position: relative;
        top: 25px;
        left: 25px;
        width: calc(100% - 50px);
      }

      .watch-now {
        position: absolute;
        bottom: 15px;
        left: 25px;
        text-decoration: none;
        display: flex;
        align-items: center;

        img {
          width: 50px;
        }

        span {
          font-family: DrukWideBoldItalic, Sans-serif;
          font-size: 30px;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 1em;
          color: #fff;
          position: relative;
          margin-left: 10px;

          &:hover {
            &:after {
              width: 0;
            }
          }

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: currentColor;
            transition: background-color 0.25s ease-in-out, width 0.36s cubic-bezier(0.51, 0.5, 0.07, 0.99);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
          }
        }
      }
    }
  }
}

.explanation {
  background: #000;
  padding: 4% 8%;

  .flex-container {
    display: flex;

    .img-wrapper {
      width: 30%;

      img {
        width: 500px;
        padding-right: 20%;
        position: static;

        &.sticky-top {
          top: 100px;
        }
      }
    }

    .texts-wrapper {
      width: 70%;
      margin-top: 10px;
      padding-right: 13%;

      .text {
        h4 {
          color: #e3ff00;
          font-family: DrukWideItalic, Sans-serif;
          font-size: 40px;
          font-weight: 400;
          text-transform: uppercase;
          line-height: 1em;
          margin-bottom: 20px;
        }

        p {
          color: #fff;
          font-size: 24px;
          font-weight: 500;
          text-transform: none;
          font-style: normal;
          line-height: 1.3em;
          letter-spacing: 0;
          margin-bottom: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .hero {
    height: 500px;

    video {
      top: -170px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .hero {
    padding: 8% 4% 0;
    height: 500px;

    video {
      display: none;
    }

    &-text-wrapper {
      h1 {
        font-size: 140px;
      }
    }
  }

  .videos {
    padding: 3% 4%;

    h3 {
      font-size: 42px;
      margin: 0 0 30px;
    }

    .flex-container {
      .video-box {
        height: 22vw;
      }
    }
  }

  .explanation {
    padding: 4%;

    .flex-container {
      .img-wrapper {
        img {
          position: sticky;
        }
      }

      .texts-wrapper {
        padding: 0;

        .text {
          h4 {
            font-size: 42px;
          }

          p {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .hero {
    height: auto;

    video {
      display: none;
    }
    &-text-wrapper {
      margin: 0;
      padding: 40px 0 20px;

      h1 {
        font-size: 80px;
      }

      .play-video {
        img {
          width: 60px;
        }
      }
    }
  }

  .videos {
    h3 {
      font-size: 30px;
    }

    .flex-container {
      flex-direction: column;

      .video-box {
        width: 100%;
      }
    }
  }

  .explanation {
    .flex-container {
      .img-wrapper {
        img {
          padding-right: 20px;
        }
      }

      .texts-wrapper {
        .text {
          h4 {
            font-size: 30px;
            margin-bottom: 5px;
          }
          p {
            font-size: 16px;
            margin-bottom: 35px;
          }
        }
      }
    }
  }
}
</style>
