<template>
  <AppLoader v-if="!misc.isDataLoaded(loaders)" />

  <div class="team-filter-wrapper">
    <h3 v-if="!selectedLeaderboard">{{ $t("statistics.total_teams") }}</h3>
    <TeamFilter @filters="filtersChanged($event)" :is-overall="true" :show-month-selector="false" />
  </div>

  <slot v-if="!selectedLeaderboard">
    <div class="grid-container" v-if="blocks.assist?.length">
      <div class="stat-box-wrapper" v-for="(block, index) in blocks">
        <TeamStatisticsBox :block="block" :title="index" @viewAll="selectedLeaderboard = index" />
      </div>
    </div>

    <div class="mt-5">
      <div v-if="!blocks.efficiency?.length">{{ $t("statistics.no-data") }}</div>
    </div>
  </slot>

  <div class="efficiency" v-if="selectedLeaderboard">
    <TotalTeamsLeaderboard
      :type="selectedLeaderboard"
      :filters="leaderboardFilters"
      @viewLeaderboard="selectedLeaderboard = null"
    />
  </div>
</template>

<script>
import TeamFilter from "@/components/simple/filters/team-filter/TeamFilter";
import statisticsService from "@/services/StatisticsService";
import TeamStatisticsBox from "@/views/statistics/shared/team-statistics-box";
import TotalTeamsLeaderboard from "@/views/statistics/shared/total-teams-leaderboard";
import misc from "@/core/helpers/misc";
import AppLoader from "@/components/shared/loader/AppLoader";

export default {
  name: "total-teams-statistics",
  components: { TotalTeamsLeaderboard, TeamFilter, TeamStatisticsBox, AppLoader },
  data() {
    return {
      blocks: [],
      selectedLeaderboard: null,
      leaderboardFilters: {
        competitionId: null,
        phase: null,
      },
      misc: misc,
      loaders: {
        teamStatistics: false,
      },
    };
  },
  methods: {
    async getTeamStatistics(competitionId, phaseId, season) {
      this.blocks = await statisticsService.getTeamStatistics(competitionId, { phase_id: phaseId, season, limit: 5 });
      this.loaders.teamStatistics = true;
    },

    filtersChanged(filters) {
      this.loaders.teamStatistics = false;
      this.leaderboardFilters = {
        phase: filters.phase,
        competitionId: filters.competitionId,
      };

      if (filters.season) {
        this.getTeamStatistics(filters.competitionId, filters.phase, filters.season);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.team-filter-wrapper {
  display: flex;

  :deep(.flex-container) {
    max-width: 1030px;
  }

  h3 {
    color: #000;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 50px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
    display: inline-block;
    width: 55%;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  margin-top: 60px;
  gap: 20px;
}

@media only screen and (max-width: 980px) {
  .team-filter-wrapper {
    flex-direction: column;

    h3 {
      font-size: 40px;
    }
  }

  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
  }
}

@media only screen and (max-width: 640px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
</style>
