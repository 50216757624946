<template>
  <transition name="snackbar-fade">
    <div class="snackbar">
      <div class="snackbar-body">
        <slot />
        <span class="close-btn" @click="close">{{ $t("snackbar.close") }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "snackbar",

  mounted() {
    setTimeout(() => {
      this.close();
    }, 5000);
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.snackbar-fade-enter-from,
.snackbar-fade-leave-to {
  opacity: 0;
}

.snackbar-fade-enter-active,
.snackbar-fade-leave-active {
  transition: opacity 0.3s ease;
}

.snackbar {
  position: fixed;
  bottom: 20px;
  z-index: 20;
  width: 100%;

  &-body {
    display: flex;
    max-width: 400px;
    margin: 0 auto;
    background: #f75f00;
    color: #fff;
    padding: 10px 20px;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    .close-btn {
      cursor: pointer;
    }
  }
}
</style>
