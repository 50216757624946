<template>
  <AwardsHero :award="award" />
  <AwardsCarousel :images="award?.gallery.images" :award="award" />
  <AwardsCategories :award="award" />
  <AwardsMvp :award-category="award?.award_categories[0]" />
  <AwardsPlayerOfTheYear
    :award-category-belgium="award?.award_categories[1]"
    :award-category-netherlands="award?.award_categories[2]"
  />
  <AwardsRisingStarOfTheYear
    :award-category-belgium="award?.award_categories[3]"
    :award-category-netherlands="award?.award_categories[4]"
  />
  <AwardsRefereeOfTheYear
    :award-category-belgium="award?.award_categories[5]"
    :award-category-netherlands="award?.award_categories[6]"
  />
  <AwardsCoachOfTheYear
    :award-category-belgium="award?.award_categories[7]"
    :award-category-netherlands="award?.award_categories[8]"
  />
  <AwardsDefensivePlayerOfTheYear :award-category="award?.award_categories[9]" />
  <AwardsSixthManOfTheYear :award-category="award?.award_categories[10]" />
  <AwardsBnxtDreamTeam :award-category="award?.award_categories[11]" />
  <AwardsLifetimeAchievement :award-category="award?.award_categories[12]" />

  <OurPartners @partnersLoaded="loaders.partners = true" />
</template>

<script>
import OurPartners from "@/components/shared/our-partners";
import AwardsHero from "@/views/awards/awards-hero";
import AwardsCarousel from "@/views/awards/awards-carousel";
import AwardsCategories from "@/views/awards/awards-categories";
import AwardsMvp from "@/views/awards/awards-mvp";
import playersService from "@/services/PlayersService";
import AwardsPlayerOfTheYear from "@/views/awards/awards-player-of-the-year";
import AwardsRisingStarOfTheYear from "@/views/awards/awards-rising-star-of-the-year";
import AwardsRefereeOfTheYear from "@/views/awards/awards-referee-of-the-year";
import AwardsCoachOfTheYear from "@/views/awards/awards-coach-of-the-year";
import AwardsSixthManOfTheYear from "@/views/awards/awards-sixth-man-of-the-year";
import AwardsBnxtDreamTeam from "@/views/awards/awards-bnxt-dream-team";
import AwardsLifetimeAchievement from "@/views/awards/awards-lifetime-achievement";
import AwardsDefensivePlayerOfTheYear from "@/views/awards/awards-defensive-player-of-the-year";
import awardsService from "@/services/AwardsService";
import misc from "@/core/helpers/misc";
export default {
  name: "awards",
  components: {
    AwardsDefensivePlayerOfTheYear,
    AwardsLifetimeAchievement,
    AwardsBnxtDreamTeam,
    AwardsSixthManOfTheYear,
    AwardsCoachOfTheYear,
    AwardsRefereeOfTheYear,
    AwardsRisingStarOfTheYear,
    AwardsPlayerOfTheYear,
    AwardsMvp,
    AwardsCategories,
    AwardsCarousel,
    AwardsHero,
    OurPartners,
  },
  data() {
    return {
      mvpPlayers: [],
      dreamTeamPlayers: [],
      lifeTimePlayers: [],
      award: null,
      misc: misc,
      loaders: {
        partners: false,
        award: false,
      },
    };
  },

  mounted() {
    this.getAwardById(1);
    this.getPlayersByTeamId(1);
  },

  methods: {
    async getPlayersByTeamId(teamId) {
      const players = await playersService.getPlayersByTeamId(teamId);
      players[0].player.profile_image = {
        public_url: "https://bnxtleague.com/wp-content/uploads/2022/04/800x950_AsbjornMitgaard_LEI.png",
      };
      this.mvpPlayers = players.slice(0, 3);
      this.dreamTeamPlayers = players.slice(0, 5);
      this.lifeTimePlayers = players.slice(0, 2);
    },

    async getAwardById(awardId) {
      this.award = await awardsService.getAwardById(awardId);
      this.loaders.award = true;
    },
  },
};
</script>

<style scoped lang="scss"></style>
