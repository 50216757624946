export default {
  matchEvents: [
    {
      code: 1000,
      sub_code: 0,
      //description: "Made throw",
    },
    {
      code: 1000,
      sub_code: 1,
      //description: "Made shot in the paint",
    },
    {
      code: 1000,
      sub_code: 2,
      //description: "Made 2 pointer outside the paint",
    },
    {
      code: 1000,
      sub_code: 3,
      //description: "Made 3 pointer",
    },
    {
      code: 1000,
      sub_code: 4,
      //description: "Made free throw",
    },
    {
      code: 1000,
      sub_code: 5,
      //description: "Made dunk",
    },
    {
      code: 1001,
      sub_code: 0,
      //description: "Missed throw",
    },
    {
      code: 1001,
      sub_code: 1,
      //description: "Missed shot in the paint",
    },
    {
      code: 1001,
      sub_code: 2,
      //description: "Missed 2 pointer outside the paint",
    },
    {
      code: 1001,
      sub_code: 3,
      //description: "Missed 3 pointer",
    },
    {
      code: 1001,
      sub_code: 4,
      //description: "Missed free throw",
    },
    {
      code: 1001,
      sub_code: 5,
      //description: "Missed dunk",
    },
    {
      code: 1002,
      sub_code: 0,
      //description: "Defensive rebound",
    },
    {
      code: 1003,
      sub_code: 0,
      //description: "Offensive rebound",
    },
    {
      code: 1004,
      sub_code: 0,
      //description: "Steal",
    },
    {
      code: 1005,
      sub_code: 0,
      //description: "Turnover",
    },
    {
      code: 1005,
      sub_code: 10,
      //description: "Offensive Goaltending",
    },
    {
      code: 1005,
      sub_code: 11,
      //description: "Lane Violation",
    },
    {
      code: 1005,
      sub_code: 12,
      //description: "Ball Handling",
    },
    {
      code: 1005,
      sub_code: 13,
      //description: "Dribbling",
    },
    {
      code: 1005,
      sub_code: 14,
      //description: "Bad pass",
    },
    {
      code: 1005,
      sub_code: 15,
      //description: "Lost ball",
    },
    {
      code: 1005,
      sub_code: 16,
      //description: "Over and back",
    },
    {
      code: 1005,
      sub_code: 17,
      //description: "Backcourt",
    },
    {
      code: 1005,
      sub_code: 18,
      //description: "Double dribble",
    },
    {
      code: 1005,
      sub_code: 19,
      //description: "Out of bounds",
    },
    {
      code: 1005,
      sub_code: 20,
      //description: "Travel",
    },
    {
      code: 1005,
      sub_code: 21,
      //description: "Shot clock",
    },
    {
      code: 1005,
      sub_code: 22,
      //description: "Offensive",
    },
    {
      code: 1005,
      sub_code: 23,
      //description: "3 sec",
    },
    {
      code: 1005,
      sub_code: 24,
      //description: "5 sec",
    },
    {
      code: 1005,
      sub_code: 25,
      //description: "8 sec",
    },
    {
      code: 1005,
      sub_code: 26,
      //description: "10 sec",
    },
    {
      code: 1005,
      sub_code: 27,
      //description: "24 sec",
    },
    {
      code: 1005,
      sub_code: 28,
      //description: "Lifting",
    },
    {
      code: 1005,
      sub_code: 29,
      //description: "Other",
    },
    {
      code: 1006,
      sub_code: 0,
      //description: "Foul against",
    },
    {
      code: 1007,
      sub_code: 0,
      //description: "Foul",
    },
    {
      code: 1007,
      sub_code: 1,
      //description: "1 free throw",
    },
    {
      code: 1007,
      sub_code: 2,
      //description: "2 free throw",
    },
    {
      code: 1007,
      sub_code: 3,
      //description: "3 free throw",
    },
    {
      code: 1007,
      sub_code: 4,
      //description: "One and one",
    },
    {
      code: 1007,
      sub_code: 5,
      //description: "Block",
    },
    {
      code: 1007,
      sub_code: 6,
      //description: "Charge",
    },
    {
      code: 1007,
      sub_code: 7,
      //description: "Double",
    },
    {
      code: 1007,
      sub_code: 8,
      //description: "Flagrant 1",
    },
    {
      code: 1007,
      sub_code: 9,
      //description: "Flagrant 2",
    },
    {
      code: 1007,
      sub_code: 10,
      //description: "Classa",
    },
    {
      code: 1007,
      sub_code: 11,
      //description: "Contact dead ball",
    },
    {
      code: 1007,
      sub_code: 12,
      //description: "Lifting",
    },
    {
      code: 1007,
      sub_code: 30,
      //description: "Offensive",
    },
    {
      code: 1007,
      sub_code: 31,
      //description: "Personal",
    },
    {
      code: 1007,
      sub_code: 32,
      //description: "Technical",
    },
    {
      code: 1007,
      sub_code: 33,
      //description: "Unsportsmanlike",
    },
    {
      code: 1007,
      sub_code: 34,
      //description: "Disqualifying",
    },
    {
      code: 1007,
      sub_code: 35,
      //description: "Intentional",
    },
    {
      code: 1008,
      sub_code: 0,
      //description: "Assist",
    },
    {
      code: 1009,
      sub_code: 0,
      //description: "Block",
    },
    {
      code: 1010,
      sub_code: 0,
      //description: "Block against",
    },
    {
      code: 1011,
      sub_code: 0,
      //description: "Substitution",
    },
    {
      code: 1020,
      sub_code: 0,
      //description: "Technical foul",
    },
    {
      code: 1100,
      sub_code: 0,
      //description: "+1 min",
    },
    {
      code: 1101,
      sub_code: 0,
      //description: "End of Period",
    },
    {
      code: 2002,
      sub_code: 0,
      //description: "Team def.",
    },
    {
      code: 2003,
      sub_code: 0,
      //description: "Team off. rebound",
    },
    {
      code: 2004,
      sub_code: 0,
      //description: "Team steal",
    },
    {
      code: 2005,
      sub_code: 0,
      //description: "Team turnover",
    },
    {
      code: 2006,
      sub_code: 0,
      //description: "Team force foul",
    },
    {
      code: 2007,
      sub_code: 0,
      //description: "Team foul",
    },
    {
      code: 2011,
      sub_code: 0,
      //description: "Team timeout",
    },
    {
      code: 2020,
      sub_code: 0,
      //description: "Team technical foul",
    },
  ],
};
