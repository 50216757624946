<template>
  <div class="welcome-modal">
    <div class="close" @click="close">
      <font-awesome-icon icon="fa-solid fa-xmark" />
    </div>

    <div class="modal-content">
      <a :href="popup?.url" target="_blank">
        <img :src="popup?.banner_image?.public_url" :alt="popup?.alt" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "welcome-modal",
  props: {
    popup: null,
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.welcome-modal {
  position: fixed;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;

  .close {
    position: absolute;
    right: 20px;
    top: 15px;
    color: hsla(0, 0%, 93.3%, 0.9);
    font-size: 24px;
    cursor: pointer;
    line-height: 1;
    z-index: 1;
  }

  .modal-content {
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      height: 95vh;
    }
  }
}
</style>
