import axios from "axios";

export default {
  getSchedules(season, phaseId, monthCount, teamId, month) {
    return axios
      .get(process.env.VUE_APP_API_URL + "schedule/club/" + season, {
        params: {
          "clubs[0]": 1,
          "clubs[1]": 2,
          phase_id: phaseId,
          monthCount: monthCount,
          competition_team_id: teamId,
          month: month,
        },
      })
      .then((response) => {
        return response.data.data;
      });
  },

  getScheduleById(scheduleId) {
    return axios.get(process.env.VUE_APP_API_URL + "schedule/find/" + scheduleId).then((response) => {
      return response.data.data;
    });
  },

  getBoxscore(competitionId, gameId) {
    return axios.get(process.env.VUE_APP_API_URL + "boxscore/game/" + competitionId + "/" + gameId).then((response) => {
      return response.data.data;
    });
  },

  getGameReports(competitionId, gameId) {
    return axios.get(process.env.VUE_APP_API_URL + "film/game/" + competitionId + "/" + gameId).then((response) => {
      return response.data.data;
    });
  },

  getGameReportsOverview(gameId) {
    return axios.get(process.env.VUE_APP_API_URL + "schedule/overview/" + gameId).then((response) => {
      return response.data.data;
    });
  },

  getLatestResults(competitionId) {
    return axios.get(process.env.VUE_APP_API_URL + "schedule/game-slider/" + competitionId).then((response) => {
      return response.data.data;
    });
  },
};
