<template>
  <div class="flex-container">
    <h3>{{ $t("statistics.individual." + type) }}</h3>
    <a href="javascript:void(0)" class="go-back-btn" @click="$emit('viewLeaderboard')">{{ $t("view-leaderboard") }}</a>
  </div>
  <div class="table-wrapper">
    <table class="table table-bordered bnxt-table">
      <thead>
        <tr>
          <th>#</th>
          <th>Photo</th>
          <th>Team name</th>
          <th>{{ $t("statistics.individual." + type) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr height="16" v-for="(team, index) in leaderboard[type]">
          <td>{{ index + 1 }}</td>
          <td>
            <router-link
              :to="{
                name: misc.getRouteNameByLocale($router.options.routes, 'Team'),
                query: { team_id: team.team.id },
              }"
            >
              <div class="player-img">
                <img v-if="team.team.team_logo" :src="team.team.team_logo.public_url" />
              </div>
            </router-link>
          </td>
          <td>
            <router-link
              :to="{
                name: misc.getRouteNameByLocale($router.options.routes, 'Team'),
                query: { team_id: team.team.id },
              }"
            >
              {{ team.team.name }}
            </router-link>
          </td>
          <td>{{ misc.formatNumber(team.value) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";
import statisticsService from "@/services/StatisticsService";

export default {
  name: "total-teams-leaderboard",
  props: {
    type: String,
    filters: {
      competitionId: null,
      phase: null,
    },
  },

  data() {
    return {
      leaderboard: [],
      misc: misc,
      loaders: {
        leaderboard: false,
      },
    };
  },

  mounted() {
    this.getTeamStatistics(this.filters.competitionId, { type: this.$props.type, phase_id: this.filters.phase });
  },

  methods: {
    async getTeamStatistics(competitionId, params) {
      this.leaderboard = await statisticsService.getTeamStatistics(competitionId, params);
      this.loaders.leaderboard = true;
    },
  },

  watch: {
    filters(filters) {
      this.loaders.leaderboard = false;
      this.getTeamStatistics(filters.competitionId, { type: this.$props.type, phase_id: filters.phase });
    },
  },
};
</script>

<style scoped lang="scss">
h3 {
  color: #000;
  font-family: DrukWideBoldItalic, Sans-serif;
  font-size: 56px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.3em;
  display: inline-block;
  width: 55%;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .go-back-btn {
    padding-left: 35px;
    background-image: url("../../../assets/img/icons/back-arrow.png");
    background-repeat: no-repeat;
    background-position: top 10px left;
    background-size: 25px;
    color: #000;
    text-transform: uppercase;
    font-size: 26px;
    font-family: DrukWideBoldItalic, Sans-serif;
    text-decoration: none;
  }
}

table {
  thead {
    tr {
      th {
        border: 0 !important;
        text-align: center;
      }
    }
  }

  tbody {
    td {
      padding: 0 !important;

      .player-img {
        text-align: center;
        padding: 10px 0 0 !important;

        img {
          width: 45px;
        }
      }
    }
  }
}
</style>
